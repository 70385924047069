.fly-planner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  &__filter {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 600px;
    height: 100%;
    background-color: $n-10;
    box-shadow: 2px 0 9px 0 rgba($n-200, 0.3);
    z-index: 1;
    overflow-x: hidden;
    padding-bottom: 60px;

    &__content {
      width: 100%;
      height: 100%;
      padding: 20px;
      flex: 1 1 auto;
      overflow-y: auto;
    }

    &__action-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      background-color: $n-0;
      box-shadow: 0px 0 15px 0 rgba($n-200, 0.15);
      z-index: 100;

      button {
        & + button {
          margin-left: 10px;
        }
      }
    }

    .item-select {
      position: relative;
      width: 100%;
      max-height: 400px;
      border-radius: 6px;
      background-color: $n-0;
      border: solid 1px $very-light-pink;
      padding: 8px 16px;
      margin-top: 8px;
      overflow: scroll;

      &.opened {
        display: block;
      }

      input {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        border: 2px solid transparent;
        font-size: 14px;
        font-weight: 200;
        margin: 8px 0;
        color: $n-200;
        border-radius: 6px;
        background-color: #f5f5f5;
        @include transition-general;

        &:focus {
          background-color: $white;
          outline: none;
          border-color: $b-200;
        }
      }

      .arrow-down {
        margin-top: 4px;
        margin-left: 8px;
        width: 8px;
        transform: rotate(180deg);
        position: absolute;
        top: 22px;
        right: 30px;
        cursor: pointer;

        &.opened {
          transform: rotate(0deg);
        }
      }

      .item-selected {
        position: relative;
        width: 100%;
        height: 36px;
        border-radius: 6px;
        background-color: $n-0;
        border: solid 1px $very-light-pink;
        padding: 8px;
        margin: 8px 0;

        .trash-icon {
          position: absolute;
          top: 6px;
          right: 8px;
          cursor: pointer;
        }

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding-right: 25px;
        }
      }

      &.geofence {
        padding: 0;
      }

      .item-select-geofence-header {
        display: flex;
        height: 32px;

        .item-select-geofence-tab {
          width: 100%;
          border-bottom: solid 1px $very-light-pink;
          cursor: pointer;

          p {
            height: 14px;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.35px;
            text-align: center;
            color: #4f4f4f;
            margin-top: 8px;
          }

          &.selected {
            border-bottom: solid 3px #3675ff;

            p {
              color: #3675ff;
            }
          }
        }
      }
    }
  }

  &__map {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    width: 100%;
    height: calc(100% + 30px);

    & > div:first-child:not(.map-controllers) {
      width: 100% !important;
      height: 100% !important;

      & > div:first-child:not(.search) {
        width: 100% !important;
        height: 100% !important;

        .mapboxgl-map {
          width: 100% !important;
          height: 100% !important;

          .mapboxgl-canvas {
            width: 100% !important;
            height: 100% !important;
          }
        }

        .overlays {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    // TODO this should be refactoring
    &__result {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      width: auto;
      height: auto;
      background-color: $n-0;
      border-radius: 6px;
      box-shadow: 2px 0 9px 0 rgba($n-200, 0.3);
      z-index: 1;
      overflow-x: hidden;

      &__content {
        width: auto;
        height: auto;
        padding: 20px;
        flex: 1 1 auto;
      }
    }

    &__zipcode_result {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      width: auto;
      height: auto;
      background-color: $n-0;
      border-radius: 6px;
      box-shadow: 2px 0 9px 0 rgba($n-200, 0.3);
      z-index: 1;
      overflow-x: hidden;
    }

    .fly-map-city-select {
      top: 57px;
    }
  }

  .DayPicker-Month {
    width: 48% !important;
  }
}
