.fly-tab {
  position: relative;
  display: flex;
  width: auto;
  height: 38px;
  z-index: 10;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px 6px 0 0;
    cursor: pointer;

    i.icon {
      display: inline-block;
      margin-top: 2px;
      margin-right: 3px;
      font-size: 26px;
      color: rgba($n-200, 0.7);
      @include noselect;
    }

    b {
      display: inline-block;
      font-size: 10px;
      color: rgba($n-200, 0.7);
      font-weight: 500;
      @include noselect;
    }

    &:hover {
      background-color: $n-10;
    }

    &.is-active {
      background-color: $n-0;

      b,
      i {
        color: $n-200;
      }
    }
  }
}
