.fly-sub-menu {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 340px;
  height: 100%;
  background-color: $white;
  box-shadow: 5px 0 20px 0 rgba(164, 164, 164, 0.22);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 100%;
    height: 114px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid $gray-4;

    .fly-combo-box {
      width: 100%;
      margin-bottom: 15px;

      .Select {
        .Select-control {
          width: 100%;
        }
      }
    }

    .date-selector {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        background-color: $blue-gray-2;
        cursor: pointer;
        @include transition-general;

        &:hover {
          background-color: darken($blue-gray-2, 5%);
        }

        i.icon {
          margin-top: 3px;
          color: $dark-ocean;
        }
      }

      b {
        color: $dark-ocean;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  &__content {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 65px;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
      border-bottom: 1px solid #e4e4e4;
      @include transition-general;

      &:hover {
        background-color: $gray-3;
      }

      &.is-active {
        background-color: rgba($blue-gray-2, 0.6);
      }

      &.has-error {
        background-color: rgba($pumpkin, 0.1);
      }

      &__title {
        width: 100%;
        height: auto;
        margin-bottom: 7px;

        b {
          font-size: 14px;
          font-weight: 600;
          color: $dark-ocean;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &__tag-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 100%;
    height: 65px;
    border-top: 1px solid $gray-4;

    button + button {
      margin-left: 10px;
    }
  }
}
