.events-container {
  position: absolute;
  left: 615px;
  top: 56px;
  z-index: 10;
  padding: 10px 0;
  background: white;
  width: 915px;
  height: 650px;
  border-radius: 6px;
  box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
  font-size: 14px;

  letter-spacing: 0.35px;

  b {
    font-weight: bold;
  }

  .events-inner {
    position: relative;

    .items {
      height: 504px;
      overflow-y: scroll;
    }

    .action-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      width: 100%;
      height: 56px;
      padding: 0 10px;
      background-color: $n-0;
      box-shadow: 0 0 15px 0 rgba($n-200, 0.15);
      z-index: 100;
      border-radius: 0 0 6px 6px;
      b {
        font-weight: 500;
      }

      button {
        & + button {
          margin-left: 10px;
        }
      }

      .fly-button {
        &.secondary {
          opacity: 0.5;
        }
      }
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.35px;
    color: #2e2e2e;
    padding: 0 10px;

    .hide-button {
      width: auto !important;
      margin: -15px;
      cursor: pointer;
    }
  }

  .dma-dropdown {
    margin-top: 12px;
  }

  .filters {
    display: flex;
    margin-top: 12px;
    margin-bottom: 6px;
    padding: 0 10px;

    .date-picker-component,
    .category-dropdown,
    .type-dropdown {
      width: 165px;
      margin-right: 4px;
      margin-left: 0;

      .select__control {
        background-color: white;
        border: solid 1px #d8d8d8;
        font-size: 12px;
        letter-spacing: 0.48px;
        color: #2e2e2e;
      }
    }

    .impression-dropdown {
      margin-left: 0;
      .select__control {
        background-color: white;
        border: solid 1px #d8d8d8;
        font-size: 12px;
        letter-spacing: 0.48px;
        color: #2e2e2e;
      }
    }

    .category-dropdown.selected,
    .impression-dropdown.selected,
    .type-dropdown.selected {
      border: 1px solid $lightish-blue;
    }

    .fly-combo-box {
    }

    .fly-combo-box .select__control {
      padding-left: 0;
    }

    .fly-search {
      background-color: transparent;

      &:hover {
        background-color: $n-10;
      }

      &.events {
        width: 220px;
        background-color: white;
        border: solid 1px #d8d8d8;
        margin-right: 4px;
      }

      input {
        font-size: 13px;
        letter-spacing: 0.48px;
        color: #2e2e2e;
        padding-left: 32px;
      }
    }
  }

  .no-items {
    text-align: left;
    font-size: 16px;
    margin-top: 25px;
    padding: 0 10px;
  }

  .fly-svg-icon-16 {
    width: 16px !important;
    height: 16px !important;

    circle {
      display: none;
    }

    path {
      fill: #2e2e2e;
    }
  }

  .fly-svg-icon-16-div {
    width: 16px !important;
    height: 16px !important;
    margin-top: 2px;
  }
}

.event-item {
  display: grid;
  grid-template-columns: 30px 220px 1fr auto;
  flex-direction: row;
  line-height: 19px;
  text-align: left;
  padding: 10px 10px 5px 10px;
  font-size: 14px;
  letter-spacing: 0.35px;
  color: #4f4f4f;
  position: relative;
  margin: 4px 0;

  &:last-child {
    border-bottom: none;
  }

  &.filter-result {
    padding: 0;
    margin: 0;
    width: 100%;
    grid-template-columns: 250px 1fr auto;

    .event-item__info {
      width: 250px;
      margin-left: 30px;
    }
    .event-item__image {
      width: 100%;
    }
    &:hover {
      background-color: white;
    }
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &.selected {
    background-color: #f5f5f5;
  }

  &__selection {
    width: 20px;
    align-self: center;
    flex-grow: 1;

    .selection-svg {
      width: 20px;
      height: 20px;
    }

    .svg {
      width: 20px !important;
      height: 20px !important;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    width: 218px;
    height: 106px;
    border-radius: 4px;
    background-color: #e8e8e8;

    .event-item__category {
      min-height: 22px;
      width: 90%;
      margin-bottom: -12px;
      padding: 4px;
      border-radius: 4px;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.2);
      background-color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
      color: #2e2e2e;
      text-align: center;
    }

    .event-item__logo {
      opacity: 0.22;
      display: flex;
      height: 90px;
      align-items: center;
    }
  }

  &__info {
    margin-left: 16px;

    div {
      margin-bottom: 5px;
    }

    .fly-svg-icon-16-div {
      width: 16px !important;
      height: 16px !important;
      margin-top: 2px;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.21;
    letter-spacing: 0.35px;
    color: #2e2e2e;
  }

  &__date {
    display: flex;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #e23000;
  }

  &__date_flight {
    display: flex;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #3675ff;

    b {
      color: #2e2e2e;
      font-weight: bold;
    }
  }

  &__flight-info {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2571ff;

    b {
      color: #2e2e2e;
      font-weight: 500;
      &.has-left-margin {
        margin-left: 6px;
      }
    }

    &.has-top-margin {
      margin-top: 16px;
    }

    span {
      margin-left: 6px;
    }
  }

  &__address {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #2e2e2e;
    display: flex;
    span {
      margin-left: 6px;
      opacity: 0.6;
    }
  }

  &__impression {
    opacity: 0.6;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #2e2e2e;
    display: flex;

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      width: 500px;
      word-wrap: break-word;
      margin-left: 10px;
      letter-spacing: 0.3px;
    }

    .fly-svg-icon {
      width: 26px;
      height: 26px;

      circle {
        display: none;
      }

      path {
        fill: #2e2e2e;
      }
    }
  }

  &__select {
    cursor: pointer;
    align-self: center;
    flex-grow: 1;

    .fly-svg-icon {
      width: 30px;
      height: 20px;
      margin: 0 0 0 auto;

      path {
        fill: black;
      }
    }
  }
}

.events-btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 601px;
  z-index: 10;
  background: #d1410c;
  padding: 12px;
  cursor: pointer;
  border-radius: 0 0 6px 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.34px;
  text-align: center;
  color: #ffffff;

  &.display-none {
    display: none;
  }

  &.name {
    margin-left: 150px;
    border-radius: 0 0 6px 6px;
    background: #d1410c;
  }

  .fly-svg-icon {
    width: 12px;
    height: 12px;
    margin-left: 8px;

    polygon {
      fill: white;
    }
  }

  .fly-svg-icon-beta {
    width: 34px;
    height: 14px;
    margin-left: 8px;
    margin-top: 4px;

    polygon {
      fill: white;
    }
  }
}

.point-info-for-event {
  .event-item {
    display: block;
    margin-top: 10px;
    margin-bottom: 0;

    &__image {
      width: 100%;
    }

    &__info {
      margin-top: 32px;
      margin-left: 10px;
    }

    &__select {
      display: none;
    }

    &__selection {
      display: none;
    }

    &:hover {
      background-color: white;
    }

    &.selected {
      background-color: white;
    }
  }
}

.selected-event-tooltip {
  margin: 15px;
  font-size: 14px;

  b {
    font-weight: bold;
  }
}
