.fly-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  z-index: 999999;

  .rodal-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(#070e22, 0.85) !important;
  }

  .rodal-dialog {
    position: relative;
    width: 100% !important;
    max-width: 546px;
    height: auto !important;
    z-index: 1000000;

    background: $n-0;
    border-radius: 6px;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);

    &:focus {
      outline: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $n-30;
    background-color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);

    b.header {
      display: block;
      flex: 1 1 auto;
      width: 100%;
      color: $n-0;
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
    }

    button.close-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-left: 20px;
      border-radius: 4px;

      &:hover {
        background-color: $gray-4;
      }

      i.icon {
        color: rgba($dark-ocean, 0.8);
        font-size: 29px;
        margin-top: 6px;
        display: block;
      }
    }
  }

  &__content {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $white !important;
    padding: 20px;
    border-radius: 6px 6px 0 0;

    .modal-creative-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
      height: auto;

      .fly-creative-list__item {
        cursor: pointer;
      }
    }

    &__empty-space {
      height: 20px;
    }

    &__forget-password {
      height: 16px;
      width: 159px;
      opacity: 0.7;
      color: #2e2e2e;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.29px;
      line-height: 14px;
      margin-top: 12px;
    }

    &__divider {
      box-sizing: border-box;
      width: 100%;
      height: 1px;
      border: 1px solid $n-20;
      margin: 20px 0;
    }

    input {
      max-width: 319px;
    }
  }

  &__action-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid $n-30;
    border-radius: 0 0 6px 6px;
    background-color: white;
    // box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.08);

    button {
      flex: 0 0 auto;
      & + button {
        margin-left: 10px;
      }
    }

    .space {
      display: block;
      width: 100%;
      flex: 1 1 auto;
    }
  }

  .rodal-close {
    position: absolute;
    cursor: pointer;
    top: 23px;
    right: 20px;
    width: 16px;
    height: 16px;

    &:hover {
      &::before,
      &::after {
        background: #333;
      }
    }

    &::before,
    &::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #999;
      border-radius: 1px;
      -webkit-transition: background 0.2s;
      transition: background 0.2s;
    }

    &::before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &::after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

.fly-metro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 0 auto 20px auto;

  .rc-steps {
    &-item {
      &-finish {
        .rc-steps-item-icon {
          background-color: $pumpkin;
          border-color: $pumpkin;
        }

        .rc-steps-item-tail {
          &::after {
            background-color: $pumpkin;
          }
        }
      }

      &-icon {
        width: 14px;
        height: 14px;
        margin-top: 6px;
        border-color: $gray-4;
        background-color: $gray-4;
        margin-left: 40px;

        .rc-steps-icon {
          display: none;
        }
      }

      &-content {
        margin-top: 13px;
      }

      &-process {
        .rc-steps-item-icon {
          background-color: $pumpkin;
          border-color: $pumpkin;
          margin-top: -1px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 26px;
          height: 26px;
          line-height: 26px;
          margin-left: 36px;

          .rc-steps-icon {
            display: block;
            margin-top: 2px;
          }
        }

        .rc-steps-item-content {
          margin-top: 8px;
        }
      }
    }
  }
}

.fly-metro-content {
  width: 100%;
  height: auto;

  span {
    &.error {
      color: #d0021b;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.24px;
      line-height: 12px;
    }

    &.success {
      color: $b-300;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.24px;
      line-height: 12px;
    }
  }
}

// These are just animation choices

/* -- fade -- */
@-webkit-keyframes rodal-fade-enter {
  from {
    opacity: 0;
  }
}

@keyframes rodal-fade-enter {
  from {
    opacity: 0;
  }
}

.rodal-fade-enter {
  -webkit-animation: rodal-fade-enter both ease-in;
  animation: rodal-fade-enter both ease-in;
}

@-webkit-keyframes rodal-fade-leave {
  to {
    opacity: 0;
  }
}

@keyframes rodal-fade-leave {
  to {
    opacity: 0;
  }
}

.rodal-fade-leave {
  -webkit-animation: rodal-fade-leave both ease-out;
  animation: rodal-fade-leave both ease-out;
}

/* -- zoom -- */
@-webkit-keyframes rodal-zoom-enter {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rodal-zoom-enter {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.rodal-zoom-enter {
  -webkit-animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-zoom-leave {
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rodal-zoom-leave {
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.rodal-zoom-leave {
  -webkit-animation: rodal-zoom-leave both;
  animation: rodal-zoom-leave both;
}

/* -- slideDown -- */
@-webkit-keyframes rodal-slideDown-enter {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes rodal-slideDown-enter {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

.rodal-slideDown-enter {
  -webkit-animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideDown-leave {
  to {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes rodal-slideDown-leave {
  to {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

.rodal-slideDown-leave {
  -webkit-animation: rodal-slideDown-leave both;
  animation: rodal-slideDown-leave both;
}

/* -- slideLeft -- */
@-webkit-keyframes rodal-slideLeft-enter {
  from {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

@keyframes rodal-slideLeft-enter {
  from {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

.rodal-slideLeft-enter {
  -webkit-animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideLeft-leave {
  to {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

@keyframes rodal-slideLeft-leave {
  to {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

.rodal-slideLeft-leave {
  -webkit-animation: rodal-slideLeft-leave both;
  animation: rodal-slideLeft-leave both;
}

/* -- slideRight -- */
@-webkit-keyframes rodal-slideRight-enter {
  from {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

@keyframes rodal-slideRight-enter {
  from {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

.rodal-slideRight-enter {
  -webkit-animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideRight-leave {
  to {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

@keyframes rodal-slideRight-leave {
  to {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

.rodal-slideRight-leave {
  -webkit-animation: rodal-slideRight-leave both;
  animation: rodal-slideRight-leave both;
}

/* -- slideUp -- */
@-webkit-keyframes rodal-slideUp-enter {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

@keyframes rodal-slideUp-enter {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

.rodal-slideUp-enter {
  -webkit-animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideUp-leave {
  to {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

@keyframes rodal-slideUp-leave {
  to {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

.rodal-slideUp-leave {
  -webkit-animation: rodal-slideUp-leave both;
  animation: rodal-slideUp-leave both;
}

/* -- flip -- */
@-webkit-keyframes rodal-flip-enter {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes rodal-flip-enter {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.rodal-flip-enter {
  -webkit-animation: rodal-flip-enter both ease-in;
  animation: rodal-flip-enter both ease-in;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes rodal-flip-leave {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
  }
}

@keyframes rodal-flip-leave {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
  }
}

.rodal-flip-leave {
  -webkit-animation: rodal-flip-leave both;
  animation: rodal-flip-leave both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

/* -- rotate -- */
@-webkit-keyframes rodal-rotate-enter {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rodal-rotate-enter {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
  }
}

.rodal-rotate-enter {
  -webkit-animation: rodal-rotate-enter both;
  animation: rodal-rotate-enter both;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes rodal-rotate-leave {
  to {
    -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rodal-rotate-leave {
  to {
    -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
  }
}

.rodal-rotate-leave {
  -webkit-animation: rodal-rotate-leave both;
  animation: rodal-rotate-leave both;
  -webkit-transform-origin: center;
  transform-origin: center;
}

/* -- door -- */
@-webkit-keyframes rodal-door-enter {
  from {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
  }
}

@keyframes rodal-door-enter {
  from {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
  }
}

.rodal-door-enter {
  -webkit-animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-door-leave {
  60% {
    -webkit-transform: scale3d(0.01, 1, 1);
    transform: scale3d(0.01, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0, 1, 0.1);
    transform: scale3d(0, 1, 0.1);
  }
}

@keyframes rodal-door-leave {
  60% {
    -webkit-transform: scale3d(0.01, 1, 1);
    transform: scale3d(0.01, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0, 1, 0.1);
    transform: scale3d(0, 1, 0.1);
  }
}

.rodal-door-leave {
  -webkit-animation: rodal-door-leave both;
  animation: rodal-door-leave both;
}
