.error-page-container {
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 5rem 0;
  background: white;

  h5 {
    font-size: 20px;
  }

  .f-col-6:first-child {
    text-align: center;
  }

  .row {
    width: 100%;

    .error-page_stage-logo {
      img {
        display: inline-flex;
        min-width: 140px;
      }
    }
  }

  .error-text {
    .error-msg {
      display: flex;
      align-items: center;
      padding-left: 5rem;
      @media screen and (max-width: 768px) {
        padding-left: 1rem;
        margin-bottom: 4rem;
      }
    }

    h5 {
      color: gray;
      font-weight: bold;
      font-size: 0.875em;
      letter-spacing: 0.43px;
    }

    h1 {
      color: #000;
      font-weight: bold;
      font-size: 2.25em;
      line-height: 1em;
      letter-spacing: 0.9px;
      margin: 8px 0 12px 0;
    }

    p {
      color: gray;
      font-weight: bold;
      font-size: 1.125em;
      letter-spacing: 0.56px;
      margin: 0 0 40px 0;
    }

    .assetHolder {
      padding-right: 5rem;
    }
  }
}

.not-found {
  .assetHolder {
    padding-right: 0 !important;
    position: fixed;
    right: 0;
  }

  .error-msg {
    padding-left: 0.8rem !important;
  }
}

.error-body {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .error-page {
    padding: 2rem;

    .co {
      display: block;
    }

    .error-text {
      h1 {
        font-size: 2em;
      }

      p {
        margin: 0 0 5px 0;
      }
    }
  }
  .not-found {
    .assetHolder {
      position: fixed;
      bottom: 0;
      right: 0;
      padding: 0 !important;
    }
  }
}
