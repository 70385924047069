.force-select {
  -webkit-user-select: text; /* Chrome 49+ */
  -moz-user-select: text; /* Firefox 43+ */
  -ms-user-select: text; /* No support yet */
  user-select: text; /* Likely future */
}

.theme-dark {
  display: inline-block;
  height: 30px;
  width: 56px;
  background-image: url(../image/night.png);
}

.theme-light {
  display: inline-block;
  height: 30px;
  width: 56px;
  background-image: url(../image/day.png);
}

.select-item .gradient-t1 {
  display: block;
  width: 34px;
  height: 12px;
  margin-right: 6px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#209cff),
    to(rgba(32, 156, 255, 0))
  );
  background: -webkit-linear-gradient(
    left,
    #209cff 0%,
    rgba(32, 156, 255, 0) 100%
  );
  background: -o-linear-gradient(left, #209cff 0%, rgba(32, 156, 255, 0) 100%);
  background: linear-gradient(to right, #209cff 0%, rgba(32, 156, 255, 0) 100%);
}

.select-item .gradient-t2 {
  display: block;
  width: 34px;
  height: 12px;
  margin-right: 6px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#50e3c2),
    to(rgba(80, 227, 194, 0))
  );
  background: -webkit-linear-gradient(
    left,
    #50e3c2 0%,
    rgba(80, 227, 194, 0) 100%
  );
  background: -o-linear-gradient(left, #50e3c2 0%, rgba(80, 227, 194, 0) 100%);
  background: linear-gradient(to right, #50e3c2 0%, rgba(80, 227, 194, 0) 100%);
}

.select-item .gradient-t3 {
  display: block;
  width: 34px;
  height: 12px;
  margin-right: 6px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5053e3),
    to(rgba(80, 83, 227, 0))
  );
  background: -webkit-linear-gradient(
    left,
    #5053e3 0%,
    rgba(80, 83, 227, 0) 100%
  );
  background: -o-linear-gradient(left, #5053e3 0%, rgba(80, 83, 227, 0) 100%);
  background: linear-gradient(to right, #5053e3 0%, rgba(80, 83, 227, 0) 100%);
}

.select-item .gradient-t4 {
  display: block;
  width: 34px;
  height: 12px;
  margin-right: 6px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d150e3),
    to(rgba(209, 80, 227, 0))
  );
  background: -webkit-linear-gradient(
    left,
    #d150e3 0%,
    rgba(209, 80, 227, 0) 100%
  );
  background: -o-linear-gradient(left, #d150e3 0%, rgba(209, 80, 227, 0) 100%);
  background: linear-gradient(to right, #d150e3 0%, rgba(209, 80, 227, 0) 100%);
}

.select-item .gradient-t5 {
  display: block;
  width: 34px;
  height: 12px;
  margin-right: 6px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e35050),
    to(rgba(227, 80, 80, 0))
  );
  background: -webkit-linear-gradient(
    left,
    #e35050 0%,
    rgba(227, 80, 80, 0) 100%
  );
  background: -o-linear-gradient(left, #e35050 0%, rgba(227, 80, 80, 0) 100%);
  background: linear-gradient(to right, #e35050 0%, rgba(227, 80, 80, 0) 100%);
}

.select-item .gradient-t6 {
  display: block;
  width: 34px;
  height: 12px;
  margin-right: 6px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e3c250),
    to(rgba(227, 194, 80, 0))
  );
  background: -webkit-linear-gradient(
    left,
    #e3c250 0%,
    rgba(227, 194, 80, 0) 100%
  );
  background: -o-linear-gradient(left, #e3c250 0%, rgba(227, 194, 80, 0) 100%);
  background: linear-gradient(to right, #e3c250 0%, rgba(227, 194, 80, 0) 100%);
}

.select-item .gradient-t7 {
  display: block;
  width: 34px;
  height: 12px;
  margin-right: 6px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#8ae350),
    to(rgba(138, 227, 80, 0))
  );
  background: -webkit-linear-gradient(
    left,
    #8ae350 0%,
    rgba(138, 227, 80, 0) 100%
  );
  background: -o-linear-gradient(left, #8ae350 0%, rgba(138, 227, 80, 0) 100%);
  background: linear-gradient(to right, #8ae350 0%, rgba(138, 227, 80, 0) 100%);
}

.select-item .gradient-t8 {
  display: block;
  width: 34px;
  height: 12px;
  margin-right: 6px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#50d1e3),
    to(rgba(80, 209, 227, 0))
  );
  background: -webkit-linear-gradient(
    left,
    #50d1e3 0%,
    rgba(80, 209, 227, 0) 100%
  );
  background: -o-linear-gradient(left, #50d1e3 0%, rgba(80, 209, 227, 0) 100%);
  background: linear-gradient(to right, #50d1e3 0%, rgba(80, 209, 227, 0) 100%);
}

.mapbox-u-label {
  color: #444855;
  font-size: 14px;
}

.mapbox-ui-multi-button {
  color: #444855;
  font-size: 14px;
  border: 1px solid #8b9898;
  background-color: white;
  padding: 4px 4px;
  cursor: pointer;
}

.mapbox-ui-multi-button.is-active {
  color: white;
  background-color: #f86d7e;
}
