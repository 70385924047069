.heat-map-controllers {
  .filter {
    position: absolute;
    width: 350px;
    height: calc(100% - 48px);
    left: 24px;
    top: 24px;
    background-color: $n-0;
    border-radius: 6px;
    box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
    z-index: 1;

    &.client-margin-top {
      top: 175px;
      height: calc(100% - 198px);
    }

    .filter-button-div {
      height: 50px;
      width: 350px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      background-color: $n-0;
      box-shadow: 0 0 5px 0 rgba($n-200, 0.15);
      border-radius: 0 0px 6px 6px;
      z-index: 100;

      .reset-button {
        margin-right: 12px;
      }

      .filter-button {
        width: 60px;
        height: 32px;
        border-radius: 6px;
        background-color: $lightish-blue;
        margin-right: 8px;

        b {
          width: 38px;
          height: 14px;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.34px;
          text-align: center;
          color: #ffffff;
        }

        & .disabled {
          opacity: 0.8;
        }
      }
    }

    &.hidden {
      display: none;
    }

    .fly-map-filter {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 50px);
      z-index: 110;

      &__header {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        z-index: 1;

        b {
          font-size: 18px;
          font-weight: 500;
          color: $n-100;
        }
      }

      &__wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
        padding: 0 15px 15px 15px;
        overflow-x: hidden;
        overflow-y: auto;

        .item {
          display: block;
          margin: 10px 0;

          p {
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 200;
          }

          .item-header {
            width: 100%;
            height: 36px;
            border-radius: 6px;
            background-color: #f5f5f5;
            border: solid 1px #f5f5f5;
            padding: 8px;
            display: flex;
            position: relative;
            cursor: pointer;

            &.opened {
              background-color: $n-0;
              border: solid 1px $very-light-pink;
            }

            p {
              font-size: 14px;
              font-weight: 200;
            }

            .item-date {
              position: relative;
              margin-left: 10px;
              font-weight: 400;
            }

            .item-open-button {
              position: absolute;
              right: 8px;
              transform: rotate(180deg);

              &.opened {
                transform: rotate(0deg);
              }

              .arrow-down {
                cursor: pointer;
                width: 8px;
              }
            }
          }

          .item-select {
            position: relative;
            width: 100%;
            max-height: 400px;
            border-radius: 6px;
            background-color: $n-0;
            border: solid 1px $very-light-pink;
            padding: 8px 16px;
            margin-top: 8px;
            display: none;
            overflow: scroll;

            &.opened {
              display: block;
            }

            input {
              width: 100%;
              height: 36px;
              display: flex;
              align-items: center;
              padding: 0 8px;
              border: 2px solid transparent;
              font-size: 14px;
              font-weight: 200;
              margin: 8px 0;
              color: $n-200;
              border-radius: 6px;
              background-color: #f5f5f5;
              @include transition-general;

              &:focus {
                background-color: $white;
                outline: none;
                border-color: $b-200;
              }
            }

            .arrow-down {
              margin-top: 4px;
              margin-left: 8px;
              width: 8px;
              transform: rotate(180deg);
              position: absolute;
              top: 22px;
              right: 30px;
              cursor: pointer;

              &.opened {
                transform: rotate(0deg);
              }
            }

            .item-selected {
              position: relative;
              width: 100%;
              height: 36px;
              border-radius: 6px;
              background-color: $n-0;
              border: solid 1px $very-light-pink;
              padding: 8px;
              margin: 8px 0;

              .trash-icon {
                position: absolute;
                top: 6px;
                right: 8px;
                cursor: pointer;
              }

              p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-right: 25px;
              }
            }

            &.geofence {
              padding: 0;
            }

            .item-select-geofence-header {
              display: flex;
              height: 32px;

              .item-select-geofence-tab {
                width: 100%;
                border-bottom: solid 1px $very-light-pink;
                cursor: pointer;

                p {
                  height: 14px;
                  font-size: 12px;
                  font-weight: 500;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: 0.35px;
                  text-align: center;
                  color: #4f4f4f;
                  margin-top: 8px;
                }

                &.selected {
                  border-bottom: solid 3px #3675ff;

                  p {
                    color: #3675ff;
                  }
                }
              }
            }
          }

          .item-flex {
            display: flex;
            justify-content: space-between;

            &.map-style {
              justify-content: start;
            }

            .item-block {
              display: block;

              &.map-style {
                margin-right: 21px;
              }

              p {
                font-size: 12px;
                font-weight: 200;
                text-align: center;
                margin-top: 4px;
              }

              .shape {
                position: relative;
                width: 64px;
                height: 64px;
                background-color: $n-0;
                border-radius: 8px;
                border: solid 1px #bdc1c2;

                &:hover {
                  border: solid 1px $lightish-blue;
                }

                .shape-icon {
                  margin: auto;
                  padding: 17px 0;
                }

                &.selected {
                  border: solid 1px $lightish-blue;
                  background-color: #edf2ff;
                  .shape-icon-tick {
                    display: block;
                  }
                }

                .shape-icon-tick {
                  display: none;
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 16px;
                }
              }

              img {
                width: 62px;
                height: 62px;
                border-radius: 8px;
              }
            }
          }

          .fly-calendar {
            position: absolute;
            left: 374px;
            width: 400px;
            max-width: 400px;
            top: 70px;
          }

          .campaign-list {
            position: absolute;
            width: auto;
            height: 475px;
            border-radius: 6px;
            box-shadow: 0 6px 10px 0 rgba(22, 33, 57, 0.1);
            background-color: $n-0;
            left: 360px;
            display: flex;

            .select-city-combobox {
              margin-bottom: 16px;
              width: 100%;

              .multi-select {
                width: 100%;

                .svg {
                  margin-top: 5px;
                }

                .badge-count {
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  text-align: center;
                  line-height: 20px;
                  border-radius: 50%;
                  background-color: $lightish-blue;
                  color: white;
                  margin-left: 10px;
                }
              }

              .select-city-placeholder {
                margin-left: 30px;
              }
            }

            .no-campaign-text {
              text-align: center;
            }

            .campaign-list-items {
              width: 280px;
              height: auto;
              margin: 8px;
              overflow: scroll;

              .campaign-item {
                display: flex;
                align-items: center;
                width: 100%;
                height: 32px;
                padding-left: 10px;
                padding-right: 10px;
                cursor: pointer;
                position: relative;

                &.selected {
                  background-color: #f5f5f5;

                  p {
                    font-weight: 500;
                  }
                  .right-icon-tick {
                    display: block;
                  }
                }

                .right-icon-tick {
                  display: none;
                  position: absolute;
                  top: 10px;
                  right: 8px;
                  width: 12px;
                }

                p {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  padding-right: 20px;
                }
              }
            }

            .campaign-medias {
              width: 280px;
              height: auto;
              margin: 8px 8px 8px 0;
              border-radius: 6px;
              background-color: #f5f5f5;
              display: flex;
              flex-direction: column;

              .select-all {
                margin-bottom: 4px;
                padding: 12px;

                p {
                  cursor: pointer;
                  float: right;
                  margin-bottom: 0;
                }
              }

              .select-media-items {
                height: 475px;
                overflow: scroll;
                padding: 12px;

                .media-item {
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-content: center;
                  position: relative;
                  background: white;
                  border-radius: 6px;
                  margin-bottom: 12px;

                  p {
                    margin: 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    padding-right: 25px;
                  }

                  .icon-tick {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                  }

                  &.selected .fly-creative-list__item {
                    border: solid 1px $lightish-blue;
                  }

                  .fly-creative-list__item {
                    .media-title-bar {
                      text-align: left;
                      padding: 4px 0;
                      padding-left: 8px;
                      font-size: 12px;
                      color: $main-dark;
                    }
                  }
                  .fly-creative-list__item,
                  .fly-creative-list__item:hover {
                    transition: none;
                    transform: none;
                    cursor: pointer;
                    box-shadow: none;

                    .image {
                      box-shadow: none;
                      min-height: 83.3px;
                    }
                  }
                }
              }

              .select-buttons {
                background-color: #e9e9e9;
                padding: 12px;
                border-radius: 0 0 6px 6px;

                .reset {
                  float: right;
                  width: 94px;
                  height: 32px;
                  border-radius: 6px;
                  background-color: transparent;
                  b {
                    width: 38px;
                    height: 14px;
                    opacity: 0.5;
                    font-size: 12px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.34px;
                    color: $n-200;
                  }

                  &:hover {
                    border: solid 1px $very-light-pink;
                  }
                }

                .apply {
                  float: right;
                  width: 94px;
                  height: 32px;
                  border-radius: 6px;
                  background-color: $lightish-blue;
                  margin-left: 8px;

                  b {
                    width: 38px;
                    height: 14px;
                    font-size: 12px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.34px;
                    text-align: center;
                    color: #ffffff;
                  }

                  & .disabled {
                    opacity: 0.8;
                  }
                }
              }
            }
          }

          .select-city-dropdown {
            width: 100%;
          }

          .color-set {
            width: 100%;
            height: 36px;
            border-radius: 6px;
            background-color: #f5f5f5;
            border: solid 1px #f5f5f5;
            padding: 12px 16px;
            display: flex;
            position: relative;

            .color-bar {
              width: 100%;
              height: 12px;
            }

            &.opened {
              background-color: transparent;
            }
          }

          .color-sets {
            width: 100%;
            height: auto;
            max-height: 400px;
            border-radius: 6px;
            background-color: transparent;
            border: solid 1px #f5f5f5;
            position: relative;
            overflow: scroll;
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            padding: 8px 0;

            .color-sets-bar {
              width: 100%;
              display: flex;
              padding: 8px 16px;

              &:hover {
                background-color: #f5f5f5;
              }

              .color-bar {
                width: 100%;
                height: 12px;
              }
            }
          }

          .opacity-set {
            width: 100%;
            height: 36px;
            background-color: transparent;
            padding: 4px 0px;
            display: flex;
            position: relative;

            .opacity-slider {
              width: 100%;

              .opacity-slider-bar {
                width: 95%;
                margin-top: 8px;

                .rc-slider-rail {
                  height: 4px;
                  border-radius: 2px;
                  background-color: #f5f5f5;
                }

                .rc-slider-track {
                  height: 4px;
                  border-radius: 2px;
                  background-color: $lightish-blue;
                }

                .rc-slider-handle {
                  width: 20px;
                  height: 20px;
                  border-radius: 2px;
                  box-shadow: 0 0 10px 0 rgba(151, 147, 147, 0.27);
                  border: solid 0 rgba(151, 147, 147, 0.27);
                  background-color: $white;
                  margin-top: -8px;
                }

                .rc-slider-handle::after {
                  content: "";
                  position: absolute;
                  top: 3;
                  left: 5px;
                  display: inline-block;
                  width: 3px;
                  height: 10px;
                  margin: 5px 0 8px 0;
                  background-color: #bec1c2;
                }

                .rc-slider-handle::before {
                  content: "";
                  position: absolute;
                  top: 3;
                  left: 12px;
                  display: inline-block;
                  width: 3px;
                  height: 10px;
                  margin: 5px 0 8px 0;
                  background-color: #bec1c2;
                }
              }
            }

            .opacity-result-box {
              width: 60px;
              height: 28px;
              border-radius: 6px;
              border: solid 1px #bec1c2;
              background-color: $n-0;

              p {
                width: 31px;
                height: 16px;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.35px;
                text-align: center;
                color: $n-200;
                margin-left: auto;
                margin-right: auto;
                margin-top: 4px;
              }
            }
          }

          .mapfences {
            .shape__item__info {
              width: 195px;
            }
          }

          .zipcodes {
            padding: 40px 8px 4px 8px;
            display: block;

            .zipcodes-textarea {
              height: auto;
              min-height: 60px;
              padding: 10px;
              width: 100%;
              border-radius: 6px;
              border: solid 1px $very-light-pink;
              resize: vertical;
              font-size: 14px;
              background-color: #f5f5f5;
            }

            .zipcode__action-box {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              height: auto;
              margin-top: 10px;
            }

            .zipcodes-selected {
              padding: 4px;
              display: flex;
              flex-wrap: wrap;

              .zipcode {
                margin: 4px;
                padding: 4px;
                width: 65px;
                height: 24px;
                border-radius: 4px;
                background-color: #4f4f4f;
                display: flex;

                &.none {
                  background-color: #db5555;
                }

                p {
                  color: $white;
                  text-align: center;
                  width: 100%;
                  font-size: 14px;
                }

                .div-close-button {
                  display: none;
                  cursor: pointer;
                  .close-btn {
                    width: 14px;
                  }
                }

                &:hover {
                  .div-close-button {
                    display: flex;
                  }

                  p {
                    width: 90%;
                  }
                }
              }
            }

            .zipcodes__switch {
              position: absolute;
              top: 45px;
              right: 8px;
              display: flex;

              p {
                font-size: 12px;
                padding-right: 8px;
                font-weight: 400;
              }
            }

            .zipcodes_reset {
              display: flex;
              float: right;

              .reset-button {
                width: 60px;
                height: 32px;
                border-radius: 6px;
                background-color: $lightish-blue;
                margin: 12px 0;

                b {
                  width: 38px;
                  height: 14px;
                  font-size: 12px;
                  font-weight: 600;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: 0.34px;
                  text-align: center;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }

  .filter-hide-button {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 386px;
    top: 24px;
    background-color: $n-0;
    border-radius: 4px;
    box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
    z-index: 1;

    .arrow-down {
      margin-top: 4px;
      margin-left: 8px;
      width: 9px;
      cursor: pointer;
    }
  }

  .fly-map-city-select {
    left: 386px;
    top: 60px;
  }

  .hidden ~ .fly-map-city-select,
  .hidden ~ .filter-hide-button {
    left: 24px;
  }

  .hidden ~ .filter-hide-button {
    transform: rotate(180deg);
  }

  .result {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 65px;
    left: calc(50% - 250px);
    bottom: 0;
    background-color: $n-0;
    border-radius: 6px;
    box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
    z-index: 1;
    padding: 12px 15px;

    .item {
      display: flex;
      width: calc(100% / 3);
      margin: 0 15px;
      z-index: 1;

      .item_label_info {
        display: block;
        margin-left: 10px;

        p {
          font-size: 14px;
          margin-bottom: 5px;
          font-weight: 200;
        }

        b {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }

  .h3_info {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: auto;
    height: auto;
    background-color: $n-0;
    border-radius: 6px;
    box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
    z-index: 2;
    overflow-x: hidden;

    .content {
      width: auto;
      height: auto;
      padding: 4px 12px;
      flex: 1 1 auto;
      flex-direction: column;

      .h3_info_item {
        display: block;
        margin: 8px;

        p {
          font-size: 18px;
          font-weight: 500;
        }

        .p-small {
          font-size: 14px;
          margin-bottom: 2px;
          font-weight: 200;
        }
      }
    }
  }
}

.right-controls {
  position: absolute;
  display: flex;
  flex-direction: column;
  line-height: 36px;
  top: 96px;
  right: 40px;
  background-color: transparent;
  z-index: 1;

  &.client-margin-top {
    top: 222px;
  }

  &.display-none {
    display: none;
  }

  .control {
    width: 36px;
    height: 36px;
    margin: 6px;
    background-color: $n-0;
    border-radius: 6px;
    box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
    z-index: 1;
    position: relative;

    .control-icon {
      margin: 6px;
      width: 24px;
      cursor: pointer;
    }

    .control-icon-firefly {
      margin-left: 10px;
      margin-top: 6px;
      width: 14px;
    }

    &.center {
      text-align: center;
    }

    b {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .workspace-control {
    z-index: 3;
  }

  .zoom-control.tooltip-container {
    .tooltip {
      left: -7px;
      line-height: 17px;
      right: 6px;

      &::after {
        left: calc(50% - 0px);
      }
    }
  }
}
