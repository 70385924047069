.fly-list-item {
  width: 100%;
  height: auto;
  border-radius: 6px;

  &.slim {
    .fly-list-item__wrapper {
      height: auto;
      min-height: 38px;
    }
  }

  &:hover {
    .fly-list-item__wrapper {
      background-color: darken($n-10, 2%);
      border: 1px solid darken($n-10, 2%);
    }

    .fly-list-item__tag-box {
      background-color: darken($n-10, 2%);
      border: 1px solid darken($n-10, 2%);
      right: 170px;

      .fly-tag {
        &.city {
          &.slim {
            .tag {
              background-color: rgba($n-200, 0.1);
            }
          }
        }
      }
    }

    .fly-list-item__chart-box {
      background-color: darken($blue-gray-2, 2%);
      border: 1px solid darken($blue-gray-2, 2%);
    }

    .fly-list-item__action-box {
      display: flex;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 10px;
    border-radius: 6px;
    background-color: $n-10;
    border: 1px solid $n-10;
    cursor: pointer;
    overflow: hidden;
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    margin-right: 20px;

    b,
    p {
      position: relative;
      display: inline-block;
      color: $main-dark;
      font-size: 13px;
      font-weight: 400;
    }

    b {
      font-weight: 600;

      & + p {
        margin-left: 30px;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -15px;
          display: inline-block;
          height: 20px;
          width: 1px;
          background-color: darken($blue-gray-2, 10%);
          color: $main-dark;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  &__status {
    margin-right: 30px;

    .fly-tag {
      margin-top: 1px;
    }
  }

  &__tag-box {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    padding-right: 8px;
    padding-left: 10px;
    border-radius: 0 5px 5px 0;
    border: 1px solid $blue-gray-1;
    background-color: $blue-gray-1;
  }

  &__action-box {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    padding-right: 8px;
    padding-left: 10px;
    border-left: 1px solid darken($blue-gray-2, 5%);
    background-color: $blue-gray-2;
    border-radius: 0 5px 5px 0;
    box-shadow: -2px 0 8px 0 rgba($black, 0.03);

    .fly-list-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 25px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 15px;
      background-color: darken($blue-gray-2, 6%);
      color: $main-dark;
      font-size: 11px;

      &:hover {
        background-color: darken($blue-gray-2, 10%);
      }
    }

    .expendable-icon {
      position: relative;
      height: 100%;
      width: 16px;
      margin-left: 5px;

      span {
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 6px);
        display: block;
        width: 12px;
        height: 2px;
        background-color: $pumpkin;

        @include transition-general;

        &.line-2 {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__chart-box {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 41px;
    display: flex;
    align-items: center;
    width: 500px;
    padding-right: 10px;
    background-color: $blue-gray-1;
    border: 1px solid $blue-gray-1;

    .fly-tag {
      flex: 0 0 auto;
    }

    .fly-line-chart {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding-top: 0;

      .base {
        margin-bottom: 0;

        .line {
          position: relative;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            border-radius: 7px;
            background: linear-gradient(to right, #b0b1b7 0%, #8b8c93 100%);
          }
        }
      }

      .percent {
        padding-left: 0 !important;
        flex: 0 0 auto;
        width: auto;

        b {
          font-size: 12px;
          font-weight: 400;
          color: $main-dark;
          width: auto;
          line-height: 20px;
          margin-left: 10px;
          margin-right: 10px;
        }

        &.cfr {
          b {
            color: $pumpkin;
            font-weight: 600;
          }
        }

        &.mfr {
          b {
            width: 100px;
          }
        }

        &.trf {
          height: 24px;
          border: 1px solid rgba($main-dark, 0.8);
          border-radius: 4px;
          align-items: center;

          b {
            color: rgba($main-dark, 0.8);
            font-weight: 600;
          }

          &.overflow {
            border: 1px solid $bright-red;

            b {
              color: $bright-red;
            }
          }
        }
      }

      .divider {
        flex: 0 0 auto;
        display: block;
        width: 1px;
        height: 20px;
        background-color: rgba($main-dark, 0.2);
        margin: 0 0px 0 15px;
      }
    }
  }

  &__expand-wrapper {
    position: relative;
    display: none;
    width: 100%;
    border-radius: 0 0 6px 6px;
    background-color: $blue-gray-2;

    &.has-max-height {
      max-height: 400px;
      overflow-x: auto;
    }

    .summary-section {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 45px;
      padding-left: 10px;
      padding-right: 10px;
      border-bottom: 1px solid darken($blue-gray-1, 10%);

      .summary-item {
        width: auto;
        height: auto;

        & + .summary-item {
          margin-left: 30px;
        }

        p,
        b {
          display: inline-block;
          font-size: 14px;
          color: rgba($main-dark, 0.7);
          font-weight: 400;
        }

        b {
          color: $main-dark;
          font-weight: 600;
          margin-left: 5px;
        }
      }
    }

    .creative-list {
      width: 100%;
      height: auto;
      padding: 15px 10px 10px 10px;

      .column {
        display: flex;
        align-items: center;
        width: 250px;
      }

      .creative {
        width: 475px;
      }

      .id {
        width: 150px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        margin-bottom: 5px;
        padding-left: 30px;

        .column {
          height: auto;
          color: rgba($main-dark, 0.7);
          font-size: 10px;
          font-weight: 600;
        }

        .id {
          width: 150px;
        }
      }

      &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 34px;
        padding-left: 30px;
        border-radius: 17px;
        cursor: pointer;

        @include transition-general;

        &:hover {
          background-color: rgba($white, 0.7);
          box-shadow: 0 2px 8px 0 rgba($shadow-color, 0.08);

          i.icon {
            opacity: 1;
            right: 7px;
          }
        }

        .status {
          position: absolute;
          top: calc(50% - 4px);
          left: 14px;
          width: 8px;
          height: 8px;
          border-radius: 4px;

          &.active {
            background: linear-gradient(135deg, $sea-green 0, $green 100%);
          }

          &.passive {
            background: rgba($carbon, 0.5);
          }
        }

        i.icon {
          position: absolute;
          display: block;
          font-size: 26px;
          top: 7px;
          right: 16px;
          opacity: 0;
          color: $pumpkin;

          @include transition-general;
        }

        .column {
          height: 100%;
          color: $main-dark;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .zipcode-list {
      width: 100%;
      height: auto;
      padding: 15px 10px 10px 10px;

      .column {
        display: flex;
        align-items: center;
        width: 180px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        margin-bottom: 5px;

        .column {
          height: auto;
          color: rgba($main-dark, 0.7);
          font-size: 10px;
          font-weight: 600;
        }
      }

      &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;

        @include transition-general;

        .zipcode-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 34px;
          border-radius: 17px;

          .column {
            height: 100%;
            color: $main-dark;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .zipcode-mass-list {
      width: 100%;
      height: auto;
      padding: 15px 10px 10px 10px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        margin-bottom: 5px;

        b {
          font-size: 10px;
          font-weight: 600;
          color: rgba($dark-ocean, 0.8);
        }
      }

      &__content {
        width: 100%;
        height: auto;

        .list {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: auto;
          max-height: 100%;
          overflow: hidden;

          &.is-active {
            max-height: auto;
          }

          span.zipcode {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            width: 57px;
            height: 24px;
            margin-bottom: 8px;
            margin-right: 8px;
            border-radius: 4px;
            background-color: rgba($white, 0.8);

            p {
              font-size: 14px;
              color: $dark-ocean;
            }
          }
        }

        button.show-more {
          width: auto;
          height: auto;

          b {
            font-size: 10px;
            color: $dark-sky;
          }

          &:hover {
            b {
              color: darken($dark-sky, 4%);
              text-decoration: underline;
            }
          }
        }
      }
    }

    .creative-mass-list {
      width: 100%;
      height: auto;
      padding: 15px 10px 10px 10px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        margin-bottom: 5px;

        b {
          font-size: 10px;
          font-weight: 600;
          color: rgba($dark-ocean, 0.8);
        }
      }

      &__content {
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .fly-creative-list__item {
          width: 150px !important;
          height: 50px !important;
          margin-bottom: 10px;
          margin-right: 10px;

          &:hover {
            transform: translate(0, 0);
            -webkit-transform: translate(0, 0);

            .image {
              box-shadow: 0 3px 14px rgba(25, 27, 30, 0.15);
            }
          }

          .image {
            width: 100%;
            height: 100%;

            img {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }
    }

    .requirement-list {
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: auto;
      padding: 15px 10px 10px 10px;

      &__part {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        height: auto;

        b.label {
          font-size: 10px;
          font-weight: 600;
          color: rgba($dark-ocean, 0.8);
          margin-bottom: 10px;
        }

        .item {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;

          & + .item {
            margin-top: 10px;
          }

          p {
            display: block;
            flex: 1 1 auto;
            width: 50%;
            font-size: 12px;
            color: $dark-ocean;
          }
        }

        & + .requirement-list__part {
          margin-left: 40px;
        }
      }
    }
  }

  &.has-absolute-box {
    .fly-list-item__action-box {
      background-color: darken($blue-gray-2, 2%);
      border: 1px solid darken($blue-gray-2, 2%);
      box-shadow: none;
    }
  }

  &.is-active {
    box-shadow: 0 4px 20px rgba($shadow-color, 0.08);

    .fly-list-item__wrapper {
      border-radius: 5px 5px 0 0;
      border: 1px solid darken($blue-gray-2, 5%);
      border-bottom: 1px solid darken($blue-gray-1, 10%);
      background-color: darken($blue-gray-2, 2%);
    }

    .fly-list-item__action-box {
      display: flex;

      .expendable-icon {
        .line-2 {
          transform: rotate(0deg);
        }
      }
    }

    .fly-list-item__chart-box {
      background-color: darken($blue-gray-2, 2%);
      border: 1px solid darken($blue-gray-2, 2%);
    }

    .fly-list-item__expand-wrapper {
      display: block;
      border: 1px solid darken($blue-gray-2, 5%);
      border-top: none;
    }

    .fly-list-item__tag-box {
      background-color: darken($blue-gray-2, 2%);
      border: 1px solid darken($blue-gray-2, 2%);
      right: 170px;
    }
  }

  & + .fly-list-item {
    margin-top: 8px;
  }

  .impression-result {
    &:hover {
      .tooltip {
        visibility: visible;
      }
    }

    .tooltip {
      position: absolute;
      max-width: 120px;
      height: auto;
      padding: 4px 6px;
      border-radius: 4px;
      text-align: left;
      color: $n-0;
      font-size: 10px;
      z-index: 1;
      background-color: $g-200;
      visibility: hidden;

      span {
        font-size: 10px;
      }

      div {
        opacity: 0.8;
        font-size: 8px;
      }

      &.black {
        background-color: $n-200;
      }
    }
  }
}
