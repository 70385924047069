.fly-hour-filter {
  width: 100%;
  height: auto;

  &__edit {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 40px 0 5px;
    border-radius: 6px;
    background-color: $n-0;
    box-shadow: 0 6px 20px 0 rgba($shadow-color, 0.06);

    &__box {
      width: auto;
      height: auto;
      flex: 0 0 auto;

      & + .fly-hour-filter__edit__box {
        margin-left: 20px;
      }
    }

    &__divider {
      flex: 0 0 auto;
      width: 10px;
      height: 2px;
      background-color: $n-40;
      margin: 0 10px;
    }

    &__action-box {
      position: absolute;
      top: 0;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: auto;
      height: 100%;

      button {
        & + button {
          margin-left: 10px;
        }
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    padding: 0 10px 0 15px;
    border-radius: 6px;
    background-color: $n-0;
    box-shadow: 0 6px 20px 0 rgba($shadow-color, 0.06);

    &__text {
      width: 100%;
      height: auto;
      flex: 1 1 auto;
      color: $n-200;
      font-size: 14px;
    }

    &__delete {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      cursor: pointer;

      i.icon {
        font-size: 23px;
        margin-top: 5px;
      }

      &:hover {
        background-color: $n-20;
      }
    }
  }
}
