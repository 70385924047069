.workspace-container {
  position: absolute;
  right: 0;
  top: 0;
  background: white;
  width: 332px;
  height: 527px;
  overflow-y: scroll;
  border-radius: 6px;
  box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);

  .workspace-inner {
    position: relative;
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .workspace-filter {
      margin-bottom: 16px;
    }

    .tab-controls-container {
      margin-bottom: 14px;
      .tab-control {
        border-radius: 16px;
        border: solid 1px #d8d8d8;
        margin-right: 12px;
        padding: 7px 13.5px;
        font-size: 12px;
        letter-spacing: 0.48px;
        color: #2e2e2e;
        opacity: 0.7;
        font-weight: 500;
        float: left;
        line-height: 12px;

        &.active {
          border-color: #3675ff;
          color: #3675ff;
          opacity: 1;
          background-color: #edf2ff;
        }
      }
    }

    .share-dialog {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      z-index: 10;

      .share-dialog-inner {
        position: relative;
        background: white;
        width: 100%;
        height: 342px;
        overflow: scroll;
        margin-top: auto;
        padding: 16px 0 0 0;
        display: flex;
        flex-direction: column;

        a {
          display: flex;
          height: 40px;
          line-height: 40px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.4px;
          cursor: pointer;
          margin: 5px;
          justify-content: center;
          align-items: center;

          &.disabled {
            cursor: not-allowed !important;
            pointer-events: none !important;

            .fly-svg-icon {
              path,
              rect:not(.cls-1) {
                fill: #858585 !important;
              }
            }
          }
        }

        .share-dialog-header {
          display: flex;
          justify-content: space-between;
          padding: 0 12px;
          margin-bottom: 10px;
          span {
            color: #2e2e2e;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
          }

          .close-icon {
            margin: auto 0;
          }

          .close-icon,
          .close-icon svg {
            height: 16px;
            width: 16px;
          }
        }

        .share-dialog-content {
          display: flex;
          flex-direction: column;
          padding: 6px 12px 60px 12px;
          overflow-y: auto;

          .recipients {
            .recipient {
              margin-bottom: 12px;
              width: 100%;
              display: flex;
              span {
                text-align: start !important;
                padding: 12px;
                color: #2e2e2e;
                border-radius: 6px;
                float: left;
                background-color: #f5f5f5;
                font-size: 14px;
                line-height: 14px;
                width: 271px;
              }

              .delete {
                visibility: hidden;
                margin: auto 0;
                margin-left: 12px;
                .fly-svg-icon {
                  width: 20px;
                  height: 20px;

                  path,
                  rect:not(.cls-1) {
                    fill: #2e2e2e;
                    opacity: 0.5;
                  }
                }
              }

              &:hover {
                .delete {
                  visibility: visible;
                }
              }
            }
          }

          .email-input {
            position: relative;
            input {
              width: 271px;
              height: 40px;
              background: #f5f5f5;
              color: #2e2e2e;
              font-size: 14px;
              float: left;
              border: none;
              border-radius: 6px;
              padding-left: 13px;
              margin-bottom: 18px;

              &:focus {
                outline: 0;
                box-shadow: 0 0 0 2px #3675ff;
              }
            }

            .validation-message {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: 0.3px;
              color: #ff3d48;
              text-align: start;
            }
          }

          .email-input.invalid {
            input {
              box-shadow: 0 0 0 2px #ff3d48 !important;
            }
          }

          .email-input::placeholder {
            opacity: 0.6;
          }
          .add-recipient-btn {
            margin: 8px 0 0 0;
            border: solid 1px #d8d8d8;
            font-size: 10px;
            color: #3675ff;
            border: none;
            float: left;
            height: auto;

            .title {
              margin-left: 8px;
              line-height: 0;
            }

            .fly-svg-icon {
              height: 16px;
              width: 16px;
              path {
                fill: #3675ff;
              }
            }
          }
        }

        .share-dialog-footer {
          margin-top: auto;
          box-shadow: 0 -5px 10px 0 rgba(225, 225, 225, 0.5);

          .sh_are-btn {
            border: solid 1px #d8d8d8;
            color: white;
            background: #3675ff;
            margin: 8px;
            .title {
              margin-left: 8px;
            }

            .fly-svg-icon {
              path {
                fill: white;
                opacity: 1;
              }
            }

            &.disabled {
              background: #eaeaea !important;
              color: #2e2e2e !important;
              opacity: 0.5;
              border: none;
              .title {
                opacity: 0.5;
              }
              .fly-svg-icon {
                path,
                rect:not(.cls-1) {
                  fill: #2e2e2e !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 14px;

    .title {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.35px;
      color: #2e2e2e;
    }

    .hide-button {
      width: auto !important;
      margin: 0 !important;
      cursor: pointer;
    }
  }

  .items {
    overflow-y: auto;
  }

  .workspace-item {
    display: flex;
    align-items: flex-start;
    line-height: 20px;
    text-align: left;
    margin-bottom: 5px;
    padding: 2px;

    &__radio-btn {
      width: 20px;
      height: 20px;
      border: solid 1px #bdc1c2;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .dot {
        display: none;
        width: 12px;
        height: 12px;
        background-color: #3675ff;
        border-radius: 50%;
      }

      &.selected {
        border-color: #3675ff;

        .dot {
          display: block;
        }
      }
    }

    &__name {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.35px;
      color: #4f4f4f;
      margin-left: 10px;
      width: 185px;

      > span {
        display: block;
      }

      input {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.35px;
        width: 100%;
      }
    }

    &__date {
      opacity: 0.7;
      font-size: 12px;
      letter-spacing: 0.34px;
      color: #2e2e2e;
    }

    &__type-container {
      display: flex;
      width: 55px;
      justify-content: flex-end;
      margin-right: 10px;
    }

    &__type {
      border-radius: 2px;
      background-color: #979797;
      padding: 5px;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.19px;
      color: #ffffff;
      line-height: 14px;
    }

    &__delete-item {
      visibility: hidden;

      .fly-svg-icon {
        width: 20px;
        height: 20px;
        margin-top: 2px;

        path,
        rect:not(.cls-1) {
          fill: #2e2e2e;
          opacity: 0.5;
        }
      }
    }

    &:hover {
      background-color: #f5f5f5;

      .workspace-item__delete-item {
        visibility: visible;
      }
    }
  }

  .workspace-footer {
    position: relative;
    display: flex;
    bottom: -12px;
    margin-left: -12px;
    margin-right: -12px;
    background: white;
    margin-top: auto;
    padding: 8px;
    box-shadow: 0 -5px 10px 0 rgba(225, 225, 225, 0.5);
    div,
    a {
      display: flex;
      height: 40px;
      line-height: 40px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.4px;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      .title {
      }

      &.disabled {
        // background: #d8d8d8;
        // color: lightgray;
        cursor: not-allowed;
        pointer-events: none;

        .fly-svg-icon {
          path,
          rect:not(.cls-1) {
            fill: #d8d8d8;
          }
        }
      }
    }

    .export-btn {
      border: solid 1px #d8d8d8;
      color: #2e2e2e;
      width: 40px;
      .title {
        margin-left: 8px;
      }

      .fly-svg-icon {
        path,
        rect:not(.cls-1) {
          fill: #2e2e2e;
        }
      }
    }

    .sh_are-btn {
      border: solid 1px #d8d8d8;
      color: #2e2e2e;
      width: 40px;

      .title {
        margin-left: 8px;
      }

      .fly-svg-icon {
        path {
          fill: #2e2e2e;
        }
      }
    }

    .show-map-btn {
      background-color: #3675ff;
      color: #ffffff;
      flex-grow: 1;

      .fly-svg-icon {
        path {
          fill: white;
        }
      }

      &.disabled {
        background: #eaeaea;
        color: #2e2e2e;
        opacity: 0.5;

        .title {
          opacity: 0.5;
        }

        .fly-svg-icon {
          path,
          rect:not(.cls-1) {
            fill: #2e2e2e;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
