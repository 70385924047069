.fly-bottom-menu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  overflow-x: auto;

  .menu-item {
    position: relative;
    min-width: 125px;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $n-0;
    opacity: 0.8;

    i.icon {
      font-size: 18px;
      margin-top: 3px;
    }

    .tool-tip {
      display: block;

      b {
        display: block;
        font-size: 10px;
        line-height: 1;
      }

      &.has-margin {
        margin-top: 4px;
      }
    }

    &.is-active {
      opacity: 1;
      background: linear-gradient(to bottom, rgba($b-200, 0.2) 0%, $n-100 80%);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: calc(50% - 14px);
        display: block;
        width: 28px;
        height: 7px;
        border-radius: 0 0 3px 3px;
        background-color: $b-200;
        z-index: 90;
      }
    }

    &:hover {
      background-color: rgba($n-200, 0.6);

      .tool-tip {
        display: block;
        b {
          opacity: 1;
        }
      }
    }
  }
}
