.fly-campaign-list {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100%;
  height: auto;
  padding: 10px 0 30px 0;

  @media #{$screen-750} {
    grid-gap: 15px;
    grid-template-columns: 100%;
    padding: 0 0 30px 0;
  }

  &:empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      display: block;
      width: 250px;
      height: 107px;
      margin-bottom: 10px;
      margin-top: 30px;
      background-image: url(../../image/empty-img@2x.png);
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
    }

    &::after {
      content: "There is no item";
      display: block;
      font-size: 14px;
      text-align: center;
      color: rgba($dark-ocean, 0.5);
      font-weight: 500;
    }
  }
}
