.fly-line-chart {
  display: block;
  width: 100%;
  height: auto;
  padding-top: 10px;

  .base {
    width: 100%;
    height: 12px;
    margin-bottom: 5px;
    border-radius: 6px;
    background-color: $blue-gray-3;
    box-shadow: inset 0 1px 4px 0 rgba($main-dark, 0.1);

    .line {
      display: block;
      height: 100%;
      border-radius: 6px;
      background: linear-gradient(
        to right,
        lighten($pumpkin, 10%) 0%,
        $pumpkin
      );
      box-shadow: 2px 0 4px 0 rgba($main-dark, 0.1);
    }
  }

  .percent {
    width: 100%;
    height: auto;

    b {
      display: inline-block;
      width: 50px;
      color: rgba($main-dark, 0.8);
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
  }

  &.percent-0 {
    .base {
      .line {
        width: 0%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-1 {
    .base {
      .line {
        width: 1%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-2 {
    .base {
      .line {
        width: 2%;
      }
    }

    .percent {
      ptext-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-3 {
    .base {
      .line {
        width: 3%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-4 {
    .base {
      .line {
        width: 4%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-5 {
    .base {
      .line {
        width: 5%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-6 {
    .base {
      .line {
        width: 6%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-7 {
    .base {
      .line {
        width: 7%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-8 {
    .base {
      .line {
        width: 8%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-9 {
    .base {
      .line {
        width: 9%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-10 {
    .base {
      .line {
        width: 10%;
      }
    }

    .percent {
      text-align: left;

      b {
        text-align: left;
      }
    }
  }

  &.percent-11 {
    .base {
      .line {
        width: 11%;
      }
    }

    .percent {
      padding-left: calc(11% - 25px);
    }
  }

  &.percent-12 {
    .base {
      .line {
        width: 12%;
      }
    }

    .percent {
      padding-left: calc(12% - 25px);
    }
  }

  &.percent-13 {
    .base {
      .line {
        width: 13%;
      }
    }

    .percent {
      padding-left: calc(13% - 25px);
    }
  }

  &.percent-14 {
    .base {
      .line {
        width: 14%;
      }
    }

    .percent {
      padding-left: calc(14% - 25px);
    }
  }

  &.percent-15 {
    .base {
      .line {
        width: 15%;
      }
    }

    .percent {
      padding-left: calc(15% - 25px);
    }
  }

  &.percent-16 {
    .base {
      .line {
        width: 16%;
      }
    }

    .percent {
      padding-left: calc(16% - 25px);
    }
  }

  &.percent-17 {
    .base {
      .line {
        width: 17%;
      }
    }

    .percent {
      padding-left: calc(17% - 25px);
    }
  }

  &.percent-18 {
    .base {
      .line {
        width: 18%;
      }
    }

    .percent {
      padding-left: calc(18% - 25px);
    }
  }

  &.percent-19 {
    .base {
      .line {
        width: 19%;
      }
    }

    .percent {
      padding-left: calc(19% - 25px);
    }
  }

  &.percent-20 {
    .base {
      .line {
        width: 20%;
      }
    }

    .percent {
      padding-left: calc(20% - 25px);
    }
  }

  &.percent-21 {
    .base {
      .line {
        width: 21%;
      }
    }

    .percent {
      padding-left: calc(21% - 25px);
    }
  }

  &.percent-22 {
    .base {
      .line {
        width: 22%;
      }
    }

    .percent {
      padding-left: calc(22% - 25px);
    }
  }

  &.percent-23 {
    .base {
      .line {
        width: 23%;
      }
    }

    .percent {
      padding-left: calc(23% - 25px);
    }
  }

  &.percent-24 {
    .base {
      .line {
        width: 24%;
      }
    }

    .percent {
      padding-left: calc(24% - 25px);
    }
  }

  &.percent-25 {
    .base {
      .line {
        width: 25%;
      }
    }

    .percent {
      padding-left: calc(25% - 25px);
    }
  }

  &.percent-26 {
    .base {
      .line {
        width: 26%;
      }
    }

    .percent {
      padding-left: calc(26% - 25px);
    }
  }

  &.percent-27 {
    .base {
      .line {
        width: 27%;
      }
    }

    .percent {
      padding-left: calc(27% - 25px);
    }
  }

  &.percent-28 {
    .base {
      .line {
        width: 28%;
      }
    }

    .percent {
      padding-left: calc(28% - 25px);
    }
  }

  &.percent-29 {
    .base {
      .line {
        width: 29%;
      }
    }

    .percent {
      padding-left: calc(29% - 25px);
    }
  }

  &.percent-30 {
    .base {
      .line {
        width: 30%;
      }
    }

    .percent {
      padding-left: calc(30% - 25px);
    }
  }

  &.percent-31 {
    .base {
      .line {
        width: 31%;
      }
    }

    .percent {
      padding-left: calc(31% - 25px);
    }
  }

  &.percent-32 {
    .base {
      .line {
        width: 32%;
      }
    }

    .percent {
      padding-left: calc(32% - 25px);
    }
  }

  &.percent-33 {
    .base {
      .line {
        width: 33%;
      }
    }

    .percent {
      padding-left: calc(33% - 25px);
    }
  }

  &.percent-34 {
    .base {
      .line {
        width: 34%;
      }
    }

    .percent {
      padding-left: calc(34% - 25px);
    }
  }

  &.percent-35 {
    .base {
      .line {
        width: 35%;
      }
    }

    .percent {
      padding-left: calc(35% - 25px);
    }
  }

  &.percent-36 {
    .base {
      .line {
        width: 36%;
      }
    }

    .percent {
      padding-left: calc(36% - 25px);
    }
  }

  &.percent-37 {
    .base {
      .line {
        width: 37%;
      }
    }

    .percent {
      padding-left: calc(37% - 25px);
    }
  }

  &.percent-38 {
    .base {
      .line {
        width: 38%;
      }
    }

    .percent {
      padding-left: calc(38% - 25px);
    }
  }

  &.percent-39 {
    .base {
      .line {
        width: 39%;
      }
    }

    .percent {
      padding-left: calc(39% - 25px);
    }
  }

  &.percent-40 {
    .base {
      .line {
        width: 40%;
      }
    }

    .percent {
      padding-left: calc(40% - 25px);
    }
  }

  &.percent-41 {
    .base {
      .line {
        width: 41%;
      }
    }

    .percent {
      padding-left: calc(1% - 25px);
    }
  }

  &.percent-42 {
    .base {
      .line {
        width: 42%;
      }
    }

    .percent {
      padding-left: calc(42% - 25px);
    }
  }

  &.percent-43 {
    .base {
      .line {
        width: 43%;
      }
    }

    .percent {
      padding-left: calc(43% - 25px);
    }
  }

  &.percent-44 {
    .base {
      .line {
        width: 44%;
      }
    }

    .percent {
      padding-left: calc(44% - 25px);
    }
  }

  &.percent-45 {
    .base {
      .line {
        width: 45%;
      }
    }

    .percent {
      padding-left: calc(45% - 25px);
    }
  }

  &.percent-46 {
    .base {
      .line {
        width: 46%;
      }
    }

    .percent {
      padding-left: calc(46% - 25px);
    }
  }

  &.percent-47 {
    .base {
      .line {
        width: 47%;
      }
    }

    .percent {
      padding-left: calc(47% - 25px);
    }
  }

  &.percent-48 {
    .base {
      .line {
        width: 48%;
      }
    }

    .percent {
      padding-left: calc(48% - 25px);
    }
  }

  &.percent-49 {
    .base {
      .line {
        width: 49%;
      }
    }

    .percent {
      padding-left: calc(49% - 25px);
    }
  }

  &.percent-50 {
    .base {
      .line {
        width: 50%;
      }
    }

    .percent {
      padding-left: calc(50% - 25px);
    }
  }

  &.percent-51 {
    .base {
      .line {
        width: 51%;
      }
    }

    .percent {
      padding-left: calc(51% - 25px);
    }
  }

  &.percent-52 {
    .base {
      .line {
        width: 52%;
      }
    }

    .percent {
      padding-left: calc(52% - 25px);
    }
  }

  &.percent-53 {
    .base {
      .line {
        width: 53%;
      }
    }

    .percent {
      padding-left: calc(53% - 25px);
    }
  }

  &.percent-54 {
    .base {
      .line {
        width: 54%;
      }
    }

    .percent {
      padding-left: calc(54% - 25px);
    }
  }

  &.percent-55 {
    .base {
      .line {
        width: 55%;
      }
    }

    .percent {
      padding-left: calc(55% - 25px);
    }
  }

  &.percent-56 {
    .base {
      .line {
        width: 56%;
      }
    }

    .percent {
      padding-left: calc(56% - 25px);
    }
  }

  &.percent-57 {
    .base {
      .line {
        width: 57%;
      }
    }

    .percent {
      padding-left: calc(57% - 25px);
    }
  }

  &.percent-58 {
    .base {
      .line {
        width: 58%;
      }
    }

    .percent {
      padding-left: calc(58% - 25px);
    }
  }

  &.percent-59 {
    .base {
      .line {
        width: 59%;
      }
    }

    .percent {
      padding-left: calc(59% - 25px);
    }
  }

  &.percent-60 {
    .base {
      .line {
        width: 60%;
      }
    }

    .percent {
      padding-left: calc(60% - 25px);
    }
  }

  &.percent-61 {
    .base {
      .line {
        width: 61%;
      }
    }

    .percent {
      padding-left: calc(61% - 25px);
    }
  }

  &.percent-62 {
    .base {
      .line {
        width: 62%;
      }
    }

    .percent {
      padding-left: calc(62% - 25px);
    }
  }

  &.percent-63 {
    .base {
      .line {
        width: 63%;
      }
    }

    .percent {
      padding-left: calc(63% - 25px);
    }
  }

  &.percent-64 {
    .base {
      .line {
        width: 64%;
      }
    }

    .percent {
      padding-left: calc(64% - 25px);
    }
  }

  &.percent-65 {
    .base {
      .line {
        width: 65%;
      }
    }

    .percent {
      padding-left: calc(65% - 25px);
    }
  }

  &.percent-66 {
    .base {
      .line {
        width: 66%;
      }
    }

    .percent {
      padding-left: calc(66% - 25px);
    }
  }

  &.percent-67 {
    .base {
      .line {
        width: 67%;
      }
    }

    .percent {
      padding-left: calc(67% - 25px);
    }
  }

  &.percent-68 {
    .base {
      .line {
        width: 68%;
      }
    }

    .percent {
      padding-left: calc(68% - 25px);
    }
  }

  &.percent-69 {
    .base {
      .line {
        width: 69%;
      }
    }

    .percent {
      padding-left: calc(69% - 25px);
    }
  }

  &.percent-70 {
    .base {
      .line {
        width: 70%;
      }
    }

    .percent {
      padding-left: calc(70% - 25px);
    }
  }

  &.percent-71 {
    .base {
      .line {
        width: 71%;
      }
    }

    .percent {
      padding-left: calc(71% - 25px);
    }
  }

  &.percent-72 {
    .base {
      .line {
        width: 72%;
      }
    }

    .percent {
      padding-left: calc(72% - 25px);
    }
  }

  &.percent-73 {
    .base {
      .line {
        width: 73%;
      }
    }

    .percent {
      padding-left: calc(73% - 25px);
    }
  }

  &.percent-74 {
    .base {
      .line {
        width: 74%;
      }
    }

    .percent {
      padding-left: calc(74% - 25px);
    }
  }

  &.percent-75 {
    .base {
      .line {
        width: 75%;
      }
    }

    .percent {
      padding-left: calc(75% - 25px);
    }
  }

  &.percent-76 {
    .base {
      .line {
        width: 76%;
      }
    }

    .percent {
      padding-left: calc(76% - 25px);
    }
  }

  &.percent-77 {
    .base {
      .line {
        width: 77%;
      }
    }

    .percent {
      padding-left: calc(77% - 25px);
    }
  }

  &.percent-78 {
    .base {
      .line {
        width: 78%;
      }
    }

    .percent {
      padding-left: calc(78% - 25px);
    }
  }

  &.percent-79 {
    .base {
      .line {
        width: 79%;
      }
    }

    .percent {
      padding-left: calc(79% - 25px);
    }
  }

  &.percent-80 {
    .base {
      .line {
        width: 80%;
      }
    }

    .percent {
      padding-left: calc(80% - 25px);
    }
  }

  &.percent-81 {
    .base {
      .line {
        width: 81%;
      }
    }

    .percent {
      padding-left: calc(81% - 25px);
    }
  }

  &.percent-82 {
    .base {
      .line {
        width: 82%;
      }
    }

    .percent {
      padding-left: calc(82% - 25px);
    }
  }

  &.percent-83 {
    .base {
      .line {
        width: 83%;
      }
    }

    .percent {
      padding-left: calc(83% - 25px);
    }
  }

  &.percent-84 {
    .base {
      .line {
        width: 84%;
      }
    }

    .percent {
      padding-left: calc(84% - 25px);
    }
  }

  &.percent-85 {
    .base {
      .line {
        width: 85%;
      }
    }

    .percent {
      padding-left: calc(85% - 25px);
    }
  }

  &.percent-86 {
    .base {
      .line {
        width: 86%;
      }
    }

    .percent {
      padding-left: calc(86% - 25px);
    }
  }

  &.percent-87 {
    .base {
      .line {
        width: 87%;
      }
    }

    .percent {
      padding-left: calc(87% - 25px);
    }
  }

  &.percent-88 {
    .base {
      .line {
        width: 88%;
      }
    }

    .percent {
      padding-left: calc(88% - 25px);
    }
  }

  &.percent-89 {
    .base {
      .line {
        width: 89%;
      }
    }

    .percent {
      padding-left: calc(89% - 25px);
    }
  }

  &.percent-90 {
    .base {
      .line {
        width: 90%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-91 {
    .base {
      .line {
        width: 91%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-92 {
    .base {
      .line {
        width: 92%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-93 {
    .base {
      .line {
        width: 93%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-94 {
    .base {
      .line {
        width: 94%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-95 {
    .base {
      .line {
        width: 95%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-96 {
    .base {
      .line {
        width: 96%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-97 {
    .base {
      .line {
        width: 97%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-98 {
    .base {
      .line {
        width: 98%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-99 {
    .base {
      .line {
        width: 99%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.percent-100 {
    .base {
      .line {
        width: 100%;
      }
    }

    .percent {
      text-align: right;

      b {
        text-align: right;
      }
    }
  }

  &.overflow {
    display: flex;
    align-items: center;
    padding-top: 0;

    .base {
      height: 7px;
      border-radius: 4px;
      order: 2;
      margin-bottom: 0;
      background-color: #9d9ea5;

      .line {
        position: relative;
        margin-left: calc(100% - 4px);

        &::before {
          content: "";
          display: block;
          position: absolute;
          left: -3px;
          top: 0;
          width: 8px;
          height: 7px;
          border-radius: 4px;
          background-color: #9d9ea5;
          z-index: 10;
        }
      }
    }

    .percent {
      width: auto;
      padding-left: 0;
      order: 1;
      margin-right: 10px;

      b {
        width: auto;
        color: #4f4f4f;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
      }
    }

    &.percent-1 {
      .base {
        .line {
          width: 0;
        }
      }
    }

    &.percent-1 {
      .base {
        .line {
          width: calc(1% + 6px);
        }
      }
    }

    &.percent-2 {
      .base {
        .line {
          width: calc(2% + 6px);
        }
      }
    }

    &.percent-3 {
      .base {
        .line {
          width: calc(3% + 6px);
        }
      }
    }

    &.percent-4 {
      .base {
        .line {
          width: calc(4% + 6px);
        }
      }
    }

    &.percent-5 {
      .base {
        .line {
          width: calc(5% + 6px);
        }
      }
    }

    &.percent-6 {
      .base {
        .line {
          width: calc(6% + 6px);
        }
      }
    }

    &.percent-7 {
      .base {
        .line {
          width: calc(7% + 6px);
        }
      }
    }

    &.percent-8 {
      .base {
        .line {
          width: calc(8% + 6px);
        }
      }
    }

    &.percent-9 {
      .base {
        .line {
          width: calc(9% + 6px);
        }
      }
    }

    &.percent-10 {
      .base {
        .line {
          width: calc(10% + 6px);
        }
      }
    }

    &.percent-11 {
      .base {
        .line {
          width: calc(11% + 6px);
        }
      }
    }

    &.percent-12 {
      .base {
        .line {
          width: calc(12% + 6px);
        }
      }
    }

    &.percent-13 {
      .base {
        .line {
          width: calc(13% + 6px);
        }
      }
    }

    &.percent-14 {
      .base {
        .line {
          width: calc(14% + 6px);
        }
      }
    }

    &.percent-15 {
      .base {
        .line {
          width: calc(15% + 6px);
        }
      }
    }

    &.percent-16 {
      .base {
        .line {
          width: calc(16% + 6px);
        }
      }
    }

    &.percent-17 {
      .base {
        .line {
          width: calc(17% + 6px);
        }
      }
    }

    &.percent-18 {
      .base {
        .line {
          width: calc(18% + 6px);
        }
      }
    }

    &.percent-19 {
      .base {
        .line {
          width: calc(19% + 6px);
        }
      }
    }

    &.percent-20 {
      .base {
        .line {
          width: calc(20% + 6px);
        }
      }
    }

    &.percent-21 {
      .base {
        .line {
          width: calc(21% + 6px);
        }
      }
    }

    &.percent-22 {
      .base {
        .line {
          width: calc(22% + 6px);
        }
      }
    }

    &.percent-23 {
      .base {
        .line {
          width: calc(23% + 6px);
        }
      }
    }

    &.percent-24 {
      .base {
        .line {
          width: calc(24% + 6px);
        }
      }
    }

    &.percent-25 {
      .base {
        .line {
          width: calc(25% + 6px);
        }
      }
    }

    &.percent-26 {
      .base {
        .line {
          width: calc(26% + 6px);
        }
      }
    }

    &.percent-27 {
      .base {
        .line {
          width: calc(27% + 6px);
        }
      }
    }

    &.percent-28 {
      .base {
        .line {
          width: calc(28% + 6px);
        }
      }
    }

    &.percent-29 {
      .base {
        .line {
          width: calc(29% + 6px);
        }
      }
    }

    &.percent-30 {
      .base {
        .line {
          width: calc(30% + 6px);
        }
      }
    }

    &.percent-31 {
      .base {
        .line {
          width: calc(31% + 6px);
        }
      }
    }

    &.percent-32 {
      .base {
        .line {
          width: calc(32% + 6px);
        }
      }
    }

    &.percent-33 {
      .base {
        .line {
          width: calc(33% + 6px);
        }
      }
    }

    &.percent-34 {
      .base {
        .line {
          width: calc(34% + 6px);
        }
      }
    }

    &.percent-35 {
      .base {
        .line {
          width: calc(35% + 6px);
        }
      }
    }

    &.percent-36 {
      .base {
        .line {
          width: calc(36% + 6px);
        }
      }
    }

    &.percent-37 {
      .base {
        .line {
          width: calc(37% + 6px);
        }
      }
    }

    &.percent-38 {
      .base {
        .line {
          width: calc(38% + 6px);
        }
      }
    }

    &.percent-39 {
      .base {
        .line {
          width: calc(39% + 6px);
        }
      }
    }

    &.percent-40 {
      .base {
        .line {
          width: calc(40% + 6px);
        }
      }
    }

    &.percent-41 {
      .base {
        .line {
          width: calc(41% + 6px);
        }
      }
    }

    &.percent-42 {
      .base {
        .line {
          width: calc(42% + 6px);
        }
      }
    }

    &.percent-43 {
      .base {
        .line {
          width: calc(43% + 6px);
        }
      }
    }

    &.percent-44 {
      .base {
        .line {
          width: calc(44% + 6px);
        }
      }
    }

    &.percent-45 {
      .base {
        .line {
          width: calc(45% + 6px);
        }
      }
    }

    &.percent-46 {
      .base {
        .line {
          width: calc(46% + 6px);
        }
      }
    }

    &.percent-47 {
      .base {
        .line {
          width: calc(47% + 6px);
        }
      }
    }

    &.percent-48 {
      .base {
        .line {
          width: calc(48% + 6px);
        }
      }
    }

    &.percent-49 {
      .base {
        .line {
          width: calc(49% + 6px);
        }
      }
    }

    &.percent-50 {
      .base {
        .line {
          width: calc(50% + 6px);
        }
      }
    }

    &.percent-51 {
      .base {
        .line {
          width: calc(51% + 6px);
        }
      }
    }

    &.percent-52 {
      .base {
        .line {
          width: calc(52% + 6px);
        }
      }
    }

    &.percent-53 {
      .base {
        .line {
          width: calc(53% + 6px);
        }
      }
    }

    &.percent-54 {
      .base {
        .line {
          width: calc(54% + 6px);
        }
      }
    }

    &.percent-55 {
      .base {
        .line {
          width: calc(55% + 6px);
        }
      }
    }

    &.percent-56 {
      .base {
        .line {
          width: calc(56% + 6px);
        }
      }
    }

    &.percent-57 {
      .base {
        .line {
          width: calc(57% + 6px);
        }
      }
    }

    &.percent-58 {
      .base {
        .line {
          width: calc(58% + 6px);
        }
      }
    }

    &.percent-59 {
      .base {
        .line {
          width: calc(59% + 6px);
        }
      }
    }

    &.percent-60 {
      .base {
        .line {
          width: calc(60% + 6px);
        }
      }
    }

    &.percent-61 {
      .base {
        .line {
          width: calc(61% + 6px);
        }
      }
    }

    &.percent-62 {
      .base {
        .line {
          width: calc(62% + 6px);
        }
      }
    }

    &.percent-63 {
      .base {
        .line {
          width: calc(63% + 6px);
        }
      }
    }

    &.percent-64 {
      .base {
        .line {
          width: calc(64% + 6px);
        }
      }
    }

    &.percent-65 {
      .base {
        .line {
          width: calc(65% + 6px);
        }
      }
    }

    &.percent-66 {
      .base {
        .line {
          width: calc(66% + 6px);
        }
      }
    }

    &.percent-67 {
      .base {
        .line {
          width: calc(67% + 6px);
        }
      }
    }

    &.percent-68 {
      .base {
        .line {
          width: calc(68% + 6px);
        }
      }
    }

    &.percent-69 {
      .base {
        .line {
          width: calc(69% + 6px);
        }
      }
    }

    &.percent-70 {
      .base {
        .line {
          width: calc(70% + 6px);
        }
      }
    }

    &.percent-71 {
      .base {
        .line {
          width: calc(71% + 6px);
        }
      }
    }

    &.percent-72 {
      .base {
        .line {
          width: calc(72% + 6px);
        }
      }
    }

    &.percent-73 {
      .base {
        .line {
          width: calc(73% + 6px);
        }
      }
    }

    &.percent-74 {
      .base {
        .line {
          width: calc(74% + 6px);
        }
      }
    }

    &.percent-75 {
      .base {
        .line {
          width: calc(75% + 6px);
        }
      }
    }

    &.percent-76 {
      .base {
        .line {
          width: calc(76% + 6px);
        }
      }
    }

    &.percent-77 {
      .base {
        .line {
          width: calc(77% + 6px);
        }
      }
    }

    &.percent-78 {
      .base {
        .line {
          width: calc(78% + 6px);
        }
      }
    }

    &.percent-79 {
      .base {
        .line {
          width: calc(79% + 6px);
        }
      }
    }

    &.percent-80 {
      .base {
        .line {
          width: calc(80% + 6px);
        }
      }
    }

    &.percent-81 {
      .base {
        .line {
          width: calc(81% + 6px);
        }
      }
    }

    &.percent-82 {
      .base {
        .line {
          width: calc(82% + 6px);
        }
      }
    }

    &.percent-83 {
      .base {
        .line {
          width: calc(83% + 6px);
        }
      }
    }

    &.percent-84 {
      .base {
        .line {
          width: calc(84% + 6px);
        }
      }
    }

    &.percent-85 {
      .base {
        .line {
          width: calc(85% + 6px);
        }
      }
    }

    &.percent-86 {
      .base {
        .line {
          width: calc(86% + 6px);
        }
      }
    }

    &.percent-87 {
      .base {
        .line {
          width: calc(87% + 6px);
        }
      }
    }

    &.percent-88 {
      .base {
        .line {
          width: calc(88% + 6px);
        }
      }
    }

    &.percent-89 {
      .base {
        .line {
          width: calc(89% + 6px);
        }
      }
    }

    &.percent-90 {
      .base {
        .line {
          width: calc(90% + 6px);
        }
      }
    }

    &.percent-91 {
      .base {
        .line {
          width: calc(91% + 6px);
        }
      }
    }

    &.percent-92 {
      .base {
        .line {
          width: calc(92% + 6px);
        }
      }
    }

    &.percent-93 {
      .base {
        .line {
          width: calc(93% + 6px);
        }
      }
    }

    &.percent-94 {
      .base {
        .line {
          width: calc(94% + 6px);
        }
      }
    }

    &.percent-95 {
      .base {
        .line {
          width: calc(95% + 6px);
        }
      }
    }

    &.percent-96 {
      .base {
        .line {
          width: calc(96% + 6px);
        }
      }
    }

    &.percent-97 {
      .base {
        .line {
          width: calc(97% + 6px);
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-98 {
      .base {
        .line {
          width: calc(98% + 6px);
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-99 {
      .base {
        .line {
          width: calc(99% + 6px);
        }
      }
    }

    &.percent-100 {
      .base {
        .line {
          width: calc(100% + 6px);
        }
      }
    }
  }

  &.list {
    .percent {
      b {
        font-weight: 500;
      }

      &.difference {
        b {
          color: darken($pumpkin, 5%);
        }
      }
    }

    &.difference-1 {
      .base {
        .line {
          &::before {
            width: calc(100% - 0);
          }
        }
      }
    }

    &.difference-1 {
      .base {
        .line {
          &::before {
            width: calc(100% - 1px);
          }
        }
      }
    }

    &.difference-2 {
      .base {
        .line {
          &::before {
            width: calc(100% - 2px);
          }
        }
      }
    }

    &.difference-3 {
      .base {
        .line {
          &::before {
            width: calc(100% - 3px);
          }
        }
      }
    }

    &.difference-4 {
      .base {
        .line {
          &::before {
            width: calc(100% - 4px);
          }
        }
      }
    }

    &.difference-5 {
      .base {
        .line {
          &::before {
            width: calc(100% - 5px);
          }
        }
      }
    }

    &.difference-6 {
      .base {
        .line {
          &::before {
            width: calc(100% - 6px);
          }
        }
      }
    }

    &.difference-7 {
      .base {
        .line {
          &::before {
            width: calc(100% - 7px);
          }
        }
      }
    }

    &.difference-8 {
      .base {
        .line {
          &::before {
            width: calc(100% - 8px);
          }
        }
      }
    }

    &.difference-9 {
      .base {
        .line {
          &::before {
            width: calc(100% - 9px);
          }
        }
      }
    }

    &.difference-10 {
      .base {
        .line {
          &::before {
            width: calc(100% - 10px);
          }
        }
      }
    }

    &.difference-11 {
      .base {
        .line {
          &::before {
            width: calc(100% - 11px);
          }
        }
      }
    }

    &.difference-12 {
      .base {
        .line {
          &::before {
            width: calc(100% - 12px);
          }
        }
      }
    }

    &.difference-13 {
      .base {
        .line {
          &::before {
            width: calc(100% - 13px);
          }
        }
      }
    }

    &.difference-14 {
      .base {
        .line {
          &::before {
            width: calc(100% - 14px);
          }
        }
      }
    }

    &.difference-15 {
      .base {
        .line {
          &::before {
            width: calc(100% - 15px);
          }
        }
      }
    }

    &.difference-16 {
      .base {
        .line {
          &::before {
            width: calc(100% - 16px);
          }
        }
      }
    }

    &.difference-17 {
      .base {
        .line {
          &::before {
            width: calc(100% - 17px);
          }
        }
      }
    }

    &.difference-18 {
      .base {
        .line {
          &::before {
            width: calc(100% - 18px);
          }
        }
      }
    }

    &.difference-19 {
      .base {
        .line {
          &::before {
            width: calc(100% - 19px);
          }
        }
      }
    }

    &.difference-20 {
      .base {
        .line {
          &::before {
            width: calc(100% - 20px);
          }
        }
      }
    }

    &.difference-21 {
      .base {
        .line {
          &::before {
            width: calc(100% - 21px);
          }
        }
      }
    }

    &.difference-22 {
      .base {
        .line {
          &::before {
            width: calc(100% - 22px);
          }
        }
      }
    }

    &.difference-23 {
      .base {
        .line {
          &::before {
            width: calc(100% - 23px);
          }
        }
      }
    }

    &.difference-24 {
      .base {
        .line {
          &::before {
            width: calc(100% - 24px);
          }
        }
      }
    }

    &.difference-25 {
      .base {
        .line {
          &::before {
            width: calc(100% - 25px);
          }
        }
      }
    }

    &.difference-26 {
      .base {
        .line {
          &::before {
            width: calc(100% - 26px);
          }
        }
      }
    }

    &.difference-27 {
      .base {
        .line {
          &::before {
            width: calc(100% - 27px);
          }
        }
      }
    }

    &.difference-28 {
      .base {
        .line {
          &::before {
            width: calc(100% - 28px);
          }
        }
      }
    }

    &.difference-29 {
      .base {
        .line {
          &::before {
            width: calc(100% - 29px);
          }
        }
      }
    }

    &.difference-30 {
      .base {
        .line {
          &::before {
            width: calc(100% - 30px);
          }
        }
      }
    }

    &.difference-31 {
      .base {
        .line {
          &::before {
            width: calc(100% - 31px);
          }
        }
      }
    }

    &.difference-32 {
      .base {
        .line {
          &::before {
            width: calc(100% - 32px);
          }
        }
      }
    }

    &.difference-33 {
      .base {
        .line {
          &::before {
            width: calc(100% - 33px);
          }
        }
      }
    }

    &.difference-34 {
      .base {
        .line {
          &::before {
            width: calc(100% - 34px);
          }
        }
      }
    }

    &.difference-35 {
      .base {
        .line {
          &::before {
            width: calc(100% - 35px);
          }
        }
      }
    }

    &.difference-36 {
      .base {
        .line {
          &::before {
            width: calc(100% - 36px);
          }
        }
      }
    }

    &.difference-37 {
      .base {
        .line {
          &::before {
            width: calc(100% - 37px);
          }
        }
      }
    }

    &.difference-38 {
      .base {
        .line {
          &::before {
            width: calc(100% - 38px);
          }
        }
      }
    }

    &.difference-39 {
      .base {
        .line {
          &::before {
            width: calc(100% - 39px);
          }
        }
      }
    }

    &.difference-40 {
      .base {
        .line {
          &::before {
            width: calc(100% - 40px);
          }
        }
      }
    }

    &.difference-41 {
      .base {
        .line {
          &::before {
            width: calc(100% - 41px);
          }
        }
      }
    }

    &.difference-42 {
      .base {
        .line {
          &::before {
            width: calc(100% - 42px);
          }
        }
      }
    }

    &.difference-43 {
      .base {
        .line {
          &::before {
            width: calc(100% - 43px);
          }
        }
      }
    }

    &.difference-44 {
      .base {
        .line {
          &::before {
            width: calc(100% - 44px);
          }
        }
      }
    }

    &.difference-45 {
      .base {
        .line {
          &::before {
            width: calc(100% - 45px);
          }
        }
      }
    }

    &.difference-46 {
      .base {
        .line {
          &::before {
            width: calc(100% - 46px);
          }
        }
      }
    }

    &.difference-47 {
      .base {
        .line {
          &::before {
            width: calc(100% - 47px);
          }
        }
      }
    }

    &.difference-48 {
      .base {
        .line {
          &::before {
            width: calc(100% - 48px);
          }
        }
      }
    }

    &.difference-49 {
      .base {
        .line {
          &::before {
            width: calc(100% - 49px);
          }
        }
      }
    }

    .difference-50 {
      .base {
        .line {
          &::before {
            width: calc(100% - 50px);
          }
        }
      }
    }

    &.difference-51 {
      .base {
        .line {
          &::before {
            width: calc(100% - 51px);
          }
        }
      }
    }

    &.difference-52 {
      .base {
        .line {
          &::before {
            width: calc(100% - 52px);
          }
        }
      }
    }

    &.difference-53 {
      .base {
        .line {
          &::before {
            width: calc(100% - 53px);
          }
        }
      }
    }

    &.difference-54 {
      .base {
        .line {
          &::before {
            width: calc(100% - 54px);
          }
        }
      }
    }

    &.difference-55 {
      .base {
        .line {
          &::before {
            width: calc(100% - 55px);
          }
        }
      }
    }

    &.difference-56 {
      .base {
        .line {
          &::before {
            width: calc(100% - 56px);
          }
        }
      }
    }

    &.difference-57 {
      .base {
        .line {
          &::before {
            width: calc(100% - 57px);
          }
        }
      }
    }

    &.difference-58 {
      .base {
        .line {
          &::before {
            width: calc(100% - 58px);
          }
        }
      }
    }

    &.difference-59 {
      .base {
        .line {
          &::before {
            width: calc(100% - 59px);
          }
        }
      }
    }

    &.difference-60 {
      .base {
        .line {
          &::before {
            width: calc(100% - 60px);
          }
        }
      }
    }

    &.difference-61 {
      .base {
        .line {
          &::before {
            width: calc(100% - 61px);
          }
        }
      }
    }

    &.difference-62 {
      .base {
        .line {
          &::before {
            width: calc(100% - 62px);
          }
        }
      }
    }

    &.difference-63 {
      .base {
        .line {
          &::before {
            width: calc(100% - 63px);
          }
        }
      }
    }

    &.difference-64 {
      .base {
        .line {
          &::before {
            width: calc(100% - 64px);
          }
        }
      }
    }

    &.difference-65 {
      .base {
        .line {
          &::before {
            width: calc(100% - 65px);
          }
        }
      }
    }

    &.difference-66 {
      .base {
        .line {
          &::before {
            width: calc(100% - 66px);
          }
        }
      }
    }

    &.difference-67 {
      .base {
        .line {
          &::before {
            width: calc(100% - 67px);
          }
        }
      }
    }

    &.difference-68 {
      .base {
        .line {
          &::before {
            width: calc(100% - 68px);
          }
        }
      }
    }

    &.difference-69 {
      .base {
        .line {
          &::before {
            width: calc(100% - 69px);
          }
        }
      }
    }

    &.difference-70 {
      .base {
        .line {
          &::before {
            width: calc(100% - 70px);
          }
        }
      }
    }

    &.difference-71 {
      .base {
        .line {
          &::before {
            width: calc(100% - 71px);
          }
        }
      }
    }

    &.difference-72 {
      .base {
        .line {
          &::before {
            width: calc(100% - 72px);
          }
        }
      }
    }

    &.difference-73 {
      .base {
        .line {
          &::before {
            width: calc(100% - 73px);
          }
        }
      }
    }

    &.difference-74 {
      .base {
        .line {
          &::before {
            width: calc(100% - 74px);
          }
        }
      }
    }

    &.difference-75 {
      .base {
        .line {
          &::before {
            width: calc(100% - 75px);
          }
        }
      }
    }

    &.difference-76 {
      .base {
        .line {
          &::before {
            width: calc(100% - 76px);
          }
        }
      }
    }

    &.difference-77 {
      .base {
        .line {
          &::before {
            width: calc(100% - 77px);
          }
        }
      }
    }

    &.difference-78 {
      .base {
        .line {
          &::before {
            width: calc(100% - 78px);
          }
        }
      }
    }

    &.difference-79 {
      .base {
        .line {
          &::before {
            width: calc(100% - 79px);
          }
        }
      }
    }

    &.difference-80 {
      .base {
        .line {
          &::before {
            width: calc(100% - 80px);
          }
        }
      }
    }

    &.difference-81 {
      .base {
        .line {
          &::before {
            width: calc(100% - 81px);
          }
        }
      }
    }

    &.difference-82 {
      .base {
        .line {
          &::before {
            width: calc(100% - 82px);
          }
        }
      }
    }

    &.difference-83 {
      .base {
        .line {
          &::before {
            width: calc(100% - 83px);
          }
        }
      }
    }

    &.difference-84 {
      .base {
        .line {
          &::before {
            width: calc(100% - 84px);
          }
        }
      }
    }

    &.difference-85 {
      .base {
        .line {
          &::before {
            width: calc(100% - 85px);
          }
        }
      }
    }

    &.difference-86 {
      .base {
        .line {
          &::before {
            width: calc(100% - 86px);
          }
        }
      }
    }

    &.difference-87 {
      .base {
        .line {
          &::before {
            width: calc(100% - 87px);
          }
        }
      }
    }

    &.difference-88 {
      .base {
        .line {
          &::before {
            width: calc(100% - 88px);
          }
        }
      }
    }

    &.difference-89 {
      .base {
        .line {
          &::before {
            width: calc(100% - 89px);
          }
        }
      }
    }

    &.difference-90 {
      .base {
        .line {
          &::before {
            width: calc(100% - 90px);
          }
        }
      }
    }

    &.difference-91 {
      .base {
        .line {
          &::before {
            width: calc(100% - 91px);
          }
        }
      }
    }

    &.difference-92 {
      .base {
        .line {
          &::before {
            width: calc(100% - 92px);
          }
        }
      }
    }

    &.difference-93 {
      .base {
        .line {
          &::before {
            width: calc(100% - 93px);
          }
        }
      }
    }

    &.difference-94 {
      .base {
        .line {
          &::before {
            width: calc(100% - 94px);
          }
        }
      }
    }

    &.difference-95 {
      .base {
        .line {
          &::before {
            width: calc(100% - 95px);
          }
        }
      }
    }

    &.difference-96 {
      .base {
        .line {
          &::before {
            width: calc(100% - 96px);
          }
        }
      }
    }

    &.difference-97 {
      .base {
        .line {
          &::before {
            width: calc(100% - 97px);
          }
        }
      }
    }

    &.difference-98 {
      .base {
        .line {
          &::before {
            width: calc(100% - 98px);
          }
        }
      }
    }

    &.difference-99 {
      .base {
        .line {
          &::before {
            width: calc(100% - 99px);
          }
        }
      }
    }

    &.difference-100 {
      .base {
        .line {
          &::before {
            width: calc(100% - 100px);
          }
        }
      }
    }

    &.percent-2 {
      .base {
        .line {
          width: 2%;
        }
      }

      .percent {
        ptext-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-3 {
      .base {
        .line {
          width: 3%;
        }
      }

      .percent {
        text-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-4 {
      .base {
        .line {
          width: 4%;
        }
      }

      .percent {
        text-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-5 {
      .base {
        .line {
          width: 5%;
        }
      }

      .percent {
        text-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-6 {
      .base {
        .line {
          width: 6%;
        }
      }

      .percent {
        text-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-7 {
      .base {
        .line {
          width: 7%;
        }
      }

      .percent {
        text-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-8 {
      .base {
        .line {
          width: 8%;
        }
      }

      .percent {
        text-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-9 {
      .base {
        .line {
          width: 9%;
        }
      }

      .percent {
        text-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-10 {
      .base {
        .line {
          width: 10%;
        }
      }

      .percent {
        text-align: left;

        b {
          text-align: left;
        }
      }
    }

    &.percent-11 {
      .base {
        .line {
          width: 11%;
        }
      }

      .percent {
        padding-left: calc(11% - 25px);
      }
    }

    &.percent-12 {
      .base {
        .line {
          width: 12%;
        }
      }

      .percent {
        padding-left: calc(12% - 25px);
      }
    }

    &.percent-13 {
      .base {
        .line {
          width: 13%;
        }
      }

      .percent {
        padding-left: calc(13% - 25px);
      }
    }

    &.percent-14 {
      .base {
        .line {
          width: 14%;
        }
      }

      .percent {
        padding-left: calc(14% - 25px);
      }
    }

    &.percent-15 {
      .base {
        .line {
          width: 15%;
        }
      }

      .percent {
        padding-left: calc(15% - 25px);
      }
    }

    &.percent-16 {
      .base {
        .line {
          width: 16%;
        }
      }

      .percent {
        padding-left: calc(16% - 25px);
      }
    }

    &.percent-17 {
      .base {
        .line {
          width: 17%;
        }
      }

      .percent {
        padding-left: calc(17% - 25px);
      }
    }

    &.percent-18 {
      .base {
        .line {
          width: 18%;
        }
      }

      .percent {
        padding-left: calc(18% - 25px);
      }
    }

    &.percent-19 {
      .base {
        .line {
          width: 19%;
        }
      }

      .percent {
        padding-left: calc(19% - 25px);
      }
    }

    &.percent-20 {
      .base {
        .line {
          width: 20%;
        }
      }

      .percent {
        padding-left: calc(20% - 25px);
      }
    }

    &.percent-21 {
      .base {
        .line {
          width: 21%;
        }
      }

      .percent {
        padding-left: calc(21% - 25px);
      }
    }

    &.percent-22 {
      .base {
        .line {
          width: 22%;
        }
      }

      .percent {
        padding-left: calc(22% - 25px);
      }
    }

    &.percent-23 {
      .base {
        .line {
          width: 23%;
        }
      }

      .percent {
        padding-left: calc(23% - 25px);
      }
    }

    &.percent-24 {
      .base {
        .line {
          width: 24%;
        }
      }

      .percent {
        padding-left: calc(24% - 25px);
      }
    }

    &.percent-25 {
      .base {
        .line {
          width: 25%;
        }
      }

      .percent {
        padding-left: calc(25% - 25px);
      }
    }

    &.percent-26 {
      .base {
        .line {
          width: 26%;
        }
      }

      .percent {
        padding-left: calc(26% - 25px);
      }
    }

    &.percent-27 {
      .base {
        .line {
          width: 27%;
        }
      }

      .percent {
        padding-left: calc(27% - 25px);
      }
    }

    &.percent-28 {
      .base {
        .line {
          width: 28%;
        }
      }

      .percent {
        padding-left: calc(28% - 25px);
      }
    }

    &.percent-29 {
      .base {
        .line {
          width: 29%;
        }
      }

      .percent {
        padding-left: calc(29% - 25px);
      }
    }

    &.percent-30 {
      .base {
        .line {
          width: 30%;
        }
      }

      .percent {
        padding-left: calc(30% - 25px);
      }
    }

    &.percent-31 {
      .base {
        .line {
          width: 31%;
        }
      }

      .percent {
        padding-left: calc(31% - 25px);
      }
    }

    &.percent-32 {
      .base {
        .line {
          width: 32%;
        }
      }

      .percent {
        padding-left: calc(32% - 25px);
      }
    }

    &.percent-33 {
      .base {
        .line {
          width: 33%;
        }
      }

      .percent {
        padding-left: calc(33% - 25px);
      }
    }

    &.percent-34 {
      .base {
        .line {
          width: 34%;
        }
      }

      .percent {
        padding-left: calc(34% - 25px);
      }
    }

    &.percent-35 {
      .base {
        .line {
          width: 35%;
        }
      }

      .percent {
        padding-left: calc(35% - 25px);
      }
    }

    &.percent-36 {
      .base {
        .line {
          width: 36%;
        }
      }

      .percent {
        padding-left: calc(36% - 25px);
      }
    }

    &.percent-37 {
      .base {
        .line {
          width: 37%;
        }
      }

      .percent {
        padding-left: calc(37% - 25px);
      }
    }

    &.percent-38 {
      .base {
        .line {
          width: 38%;
        }
      }

      .percent {
        padding-left: calc(38% - 25px);
      }
    }

    &.percent-39 {
      .base {
        .line {
          width: 39%;
        }
      }

      .percent {
        padding-left: calc(39% - 25px);
      }
    }

    &.percent-40 {
      .base {
        .line {
          width: 40%;
        }
      }

      .percent {
        padding-left: calc(40% - 25px);
      }
    }

    &.percent-41 {
      .base {
        .line {
          width: 41%;
        }
      }

      .percent {
        padding-left: calc(1% - 25px);
      }
    }

    &.percent-42 {
      .base {
        .line {
          width: 42%;
        }
      }

      .percent {
        padding-left: calc(42% - 25px);
      }
    }

    &.percent-43 {
      .base {
        .line {
          width: 43%;
        }
      }

      .percent {
        padding-left: calc(43% - 25px);
      }
    }

    &.percent-44 {
      .base {
        .line {
          width: 44%;
        }
      }

      .percent {
        padding-left: calc(44% - 25px);
      }
    }

    &.percent-45 {
      .base {
        .line {
          width: 45%;
        }
      }

      .percent {
        padding-left: calc(45% - 25px);
      }
    }

    &.percent-46 {
      .base {
        .line {
          width: 46%;
        }
      }

      .percent {
        padding-left: calc(46% - 25px);
      }
    }

    &.percent-47 {
      .base {
        .line {
          width: 47%;
        }
      }

      .percent {
        padding-left: calc(47% - 25px);
      }
    }

    &.percent-48 {
      .base {
        .line {
          width: 48%;
        }
      }

      .percent {
        padding-left: calc(48% - 25px);
      }
    }

    &.percent-49 {
      .base {
        .line {
          width: 49%;
        }
      }

      .percent {
        padding-left: calc(49% - 25px);
      }
    }

    &.percent-50 {
      .base {
        .line {
          width: 50%;
        }
      }

      .percent {
        padding-left: calc(50% - 25px);
      }
    }

    &.percent-51 {
      .base {
        .line {
          width: 51%;
        }
      }

      .percent {
        padding-left: calc(51% - 25px);
      }
    }

    &.percent-52 {
      .base {
        .line {
          width: 52%;
        }
      }

      .percent {
        padding-left: calc(52% - 25px);
      }
    }

    &.percent-53 {
      .base {
        .line {
          width: 53%;
        }
      }

      .percent {
        padding-left: calc(53% - 25px);
      }
    }

    &.percent-54 {
      .base {
        .line {
          width: 54%;
        }
      }

      .percent {
        padding-left: calc(54% - 25px);
      }
    }

    &.percent-55 {
      .base {
        .line {
          width: 55%;
        }
      }

      .percent {
        padding-left: calc(55% - 25px);
      }
    }

    &.percent-56 {
      .base {
        .line {
          width: 56%;
        }
      }

      .percent {
        padding-left: calc(56% - 25px);
      }
    }

    &.percent-57 {
      .base {
        .line {
          width: 57%;
        }
      }

      .percent {
        padding-left: calc(57% - 25px);
      }
    }

    &.percent-58 {
      .base {
        .line {
          width: 58%;
        }
      }

      .percent {
        padding-left: calc(58% - 25px);
      }
    }

    &.percent-59 {
      .base {
        .line {
          width: 59%;
        }
      }

      .percent {
        padding-left: calc(59% - 25px);
      }
    }

    &.percent-60 {
      .base {
        .line {
          width: 60%;
        }
      }

      .percent {
        padding-left: calc(60% - 25px);
      }
    }

    &.percent-61 {
      .base {
        .line {
          width: 61%;
        }
      }

      .percent {
        padding-left: calc(61% - 25px);
      }
    }

    &.percent-62 {
      .base {
        .line {
          width: 62%;
        }
      }

      .percent {
        padding-left: calc(62% - 25px);
      }
    }

    &.percent-63 {
      .base {
        .line {
          width: 63%;
        }
      }

      .percent {
        padding-left: calc(63% - 25px);
      }
    }

    &.percent-64 {
      .base {
        .line {
          width: 64%;
        }
      }

      .percent {
        padding-left: calc(64% - 25px);
      }
    }

    &.percent-65 {
      .base {
        .line {
          width: 65%;
        }
      }

      .percent {
        padding-left: calc(65% - 25px);
      }
    }

    &.percent-66 {
      .base {
        .line {
          width: 66%;
        }
      }

      .percent {
        padding-left: calc(66% - 25px);
      }
    }

    &.percent-67 {
      .base {
        .line {
          width: 67%;
        }
      }

      .percent {
        padding-left: calc(67% - 25px);
      }
    }

    &.percent-68 {
      .base {
        .line {
          width: 68%;
        }
      }

      .percent {
        padding-left: calc(68% - 25px);
      }
    }

    &.percent-69 {
      .base {
        .line {
          width: 69%;
        }
      }

      .percent {
        padding-left: calc(69% - 25px);
      }
    }

    &.percent-70 {
      .base {
        .line {
          width: 70%;
        }
      }

      .percent {
        padding-left: calc(70% - 25px);
      }
    }

    &.percent-71 {
      .base {
        .line {
          width: 71%;
        }
      }

      .percent {
        padding-left: calc(71% - 25px);
      }
    }

    &.percent-72 {
      .base {
        .line {
          width: 72%;
        }
      }

      .percent {
        padding-left: calc(72% - 25px);
      }
    }

    &.percent-73 {
      .base {
        .line {
          width: 73%;
        }
      }

      .percent {
        padding-left: calc(73% - 25px);
      }
    }

    &.percent-74 {
      .base {
        .line {
          width: 74%;
        }
      }

      .percent {
        padding-left: calc(74% - 25px);
      }
    }

    &.percent-75 {
      .base {
        .line {
          width: 75%;
        }
      }

      .percent {
        padding-left: calc(75% - 25px);
      }
    }

    &.percent-76 {
      .base {
        .line {
          width: 76%;
        }
      }

      .percent {
        padding-left: calc(76% - 25px);
      }
    }

    &.percent-77 {
      .base {
        .line {
          width: 77%;
        }
      }

      .percent {
        padding-left: calc(77% - 25px);
      }
    }

    &.percent-78 {
      .base {
        .line {
          width: 78%;
        }
      }

      .percent {
        padding-left: calc(78% - 25px);
      }
    }

    &.percent-79 {
      .base {
        .line {
          width: 79%;
        }
      }

      .percent {
        padding-left: calc(79% - 25px);
      }
    }

    &.percent-80 {
      .base {
        .line {
          width: 80%;
        }
      }

      .percent {
        padding-left: calc(80% - 25px);
      }
    }

    &.percent-81 {
      .base {
        .line {
          width: 81%;
        }
      }

      .percent {
        padding-left: calc(81% - 25px);
      }
    }

    &.percent-82 {
      .base {
        .line {
          width: 82%;
        }
      }

      .percent {
        padding-left: calc(82% - 25px);
      }
    }

    &.percent-83 {
      .base {
        .line {
          width: 83%;
        }
      }

      .percent {
        padding-left: calc(83% - 25px);
      }
    }

    &.percent-84 {
      .base {
        .line {
          width: 84%;
        }
      }

      .percent {
        padding-left: calc(84% - 25px);
      }
    }

    &.percent-85 {
      .base {
        .line {
          width: 85%;
        }
      }

      .percent {
        padding-left: calc(85% - 25px);
      }
    }

    &.percent-86 {
      .base {
        .line {
          width: 86%;
        }
      }

      .percent {
        padding-left: calc(86% - 25px);
      }
    }

    &.percent-87 {
      .base {
        .line {
          width: 87%;
        }
      }

      .percent {
        padding-left: calc(87% - 25px);
      }
    }

    &.percent-88 {
      .base {
        .line {
          width: 88%;
        }
      }

      .percent {
        padding-left: calc(88% - 25px);
      }
    }

    &.percent-89 {
      .base {
        .line {
          width: 89%;
        }
      }

      .percent {
        padding-left: calc(89% - 25px);
      }
    }

    &.percent-90 {
      .base {
        .line {
          width: 90%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-91 {
      .base {
        .line {
          width: 91%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-92 {
      .base {
        .line {
          width: 92%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-93 {
      .base {
        .line {
          width: 93%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-94 {
      .base {
        .line {
          width: 94%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-95 {
      .base {
        .line {
          width: 95%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-96 {
      .base {
        .line {
          width: 96%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-97 {
      .base {
        .line {
          width: 97%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-98 {
      .base {
        .line {
          width: 98%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-99 {
      .base {
        .line {
          width: 99%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }

    &.percent-100 {
      .base {
        .line {
          width: 100%;
        }
      }

      .percent {
        text-align: right;

        b {
          text-align: right;
        }
      }
    }
  }
}
