.fly-time-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
  color: $n-100;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;

  @media #{$screen-750} {
    font-size: 10px;
  }

  &__divider {
    display: block;
    width: 1px;
    height: 20px;
    background-color: rgba($n-100, 0.3);
    margin: 0 15px;
  }

  &__dates {
    display: block;

    & + .fly-time-left__dates {
      margin-left: 15px;
    }

    b {
      display: block;
      color: $n-100;
      font-size: 12px;
      letter-spacing: 0.33px;
      line-height: 14px;
      white-space: nowrap;

      @media #{$screen-750} {
        font-size: 10px;
      }
    }
  }
}
