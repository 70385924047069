.fly-tag {
  display: inline-block;
  width: auto;
  height: auto;
  flex: 0 0 auto;

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 30px;
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;

    &.slim {
      height: 24px;
    }
  }

  &.progress {
    .tag {
      border: 2px solid black;

      b {
        font-size: 15px;
        font-weight: 500;
        margin-top: -1px;
      }

      &.on-track {
        border-color: $green;
        color: $green;
      }

      &.over-delivering {
        border-color: $blue;
        color: $blue;
      }

      &.under-delivering {
        border-color: $red;
        color: $red;
      }

      &.not-started {
        border-color: $gray-2;
        color: $gray-2;
      }

      &.missing-creative {
        border-color: #ff9533;
        color: #ff9533;
      }
    }

    &.slim {
      .tag {
        height: 19px;

        b {
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }

    &.solid {
      .tag {
        &.on-track {
          border-color: transparent;
          background-color: rgba($green, 0.2);
          color: darken($green, 10%);
        }

        &.over-delivering {
          border-color: transparent;
          background-color: rgba($blue, 0.2);
          color: darken($blue, 10%);
        }

        &.under-delivering {
          border-color: transparent;
          background-color: rgba($red, 0.2);
          color: darken($red, 10%);
        }

        &.not-started {
          border-color: transparent;
          background-color: rgba($gray-2, 0.2);
          color: darken($gray-2, 10%);
        }
      }
    }
  }

  &.number {
    .tag {
      min-width: 23px;
      height: 15px;
      border-radius: 8px;
      background-color: $crimsion-pinkish;

      b {
        font-size: 12px;
        font-weight: 600;
        color: $white;
        text-align: center;
      }
    }
  }

  &.city {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .tag {
      background-color: rgba($dark-ocean, 0.15);
      padding-right: 8px;
      padding-left: 6px;

      i {
        color: $main-dark;
        margin-top: 4px;
        margin-right: 3px;
      }

      b {
        font-size: 15px;
        color: $main-dark;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.slim {
      .tag {
        background-color: darken($blue-gray-2, 0%);
        height: 18px;
      }

      b {
        font-size: 11px;
      }

      i {
        font-size: 20px;
      }
    }
  }

  &.zipcode {
    .tag {
      background-color: $bright-red;
      border: 1px solid darken($bright-red, 10%);
      padding-left: 4px;
      padding-right: 4px;
      height: 24px;

      i.icon {
        color: $white;
        padding-top: 4px;
      }

      b {
        font-size: 14px;
        color: $white;
        font-weight: 600;
      }
    }

    &.zero {
      .tag {
        background-color: $blue-gray-3;
        border: 1px solid darken($blue-gray-3, 10%);
      }
    }
  }

  & + .fly-tag {
    margin-left: 10px;
  }
}

.fly-tag-with-bg {
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;

  text-transform: uppercase;

  &.progress {
    .tag {
      padding: 6px;
      border-radius: 4px;

      &.on-track {
        background-color: #c7f0cd;
        color: #037a06;
      }

      &.over-delivering {
        background-color: #c7daff;
        color: #0f70e6;
      }

      &.under-delivering {
        background-color: #fadcdc;
        color: #dd1212;
      }

      &.not-started {
        background-color: #e0e0e0;
        color: #2e2e2e;
      }

      &.missing-creative {
        background-color: #fff1d8;
        color: #ca5812;
      }
    }
  }
}
