@mixin transition-general {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@mixin e100 {
  box-shadow: 0 0 1px 0 rgba($n-200, 0.31), 0 1px 1px 0 rgba($n-200, 0.25);
}

@mixin e200 {
  box-shadow: 0 0 1px 0 rgba($n-200, 0.31), 0 4px 8px -2px rgba($n-200, 0.25);
}

@mixin e300 {
  box-shadow: 0 0 1px 0 rgba($n-200, 0.31), 0 8px 16px -4px rgba($n-200, 0.25);
}

@mixin e400 {
  box-shadow: 0 0 1px 0 rgba($n-200, 0.31), 0 12px 24px -6px rgba($n-200, 0.25);
}

@mixin e500 {
  box-shadow: 0 0 1px 0 rgba($n-200, 0.31), 0 20px 32px -8px rgba($n-200, 0.25);
}
