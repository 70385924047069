.fly-campaign-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 212px;
  background-color: $n-0;
  border-radius: 6px;
  box-shadow: 0 6px 20px 0 rgba(#162139, 0.08);
  cursor: pointer;
  transition: all 0.4s ease;

  @media #{$screen-800} {
    height: auto;
    box-shadow: 0 6px 20px 0 rgba(#162139, 0.12);
  }

  &:hover {
    box-shadow: 0 16px 25px -6px rgba(#162139, 0.27);
  }

  &.started {
    background: linear-gradient(
        174deg,
        rgba($g-100, 0.18) 0%,
        rgba($n-0, 0.13) 40%,
        rgba($n-0, 0.13) 100%
      ),
      $n-0;

    .fly-campaign-card__header {
      background-color: rgba($g-100, 0.82);

      .status {
        color: $n-0;
      }
    }
  }

  &.ended {
    background: linear-gradient(
        174deg,
        rgba($n-20, 0.18) 0%,
        rgba(255, 255, 255, 0.13) 40%,
        rgba(255, 255, 255, 0.13) 100%
      ),
      #fff;

    .fly-campaign-card__header {
      background-color: $n-10;
      border-bottom: 1px solid $n-20;

      .status {
        color: rgba($n-100, 0.5);
      }
    }
  }

  &.upcoming {
    background: linear-gradient(
        174deg,
        rgba($b-200, 0.18) 0%,
        rgba(255, 255, 255, 0.13) 40%,
        rgba(255, 255, 255, 0.13) 100%
      ),
      #fff;

    .fly-campaign-card__header {
      background-color: rgba($b-200, 0.62);

      .status {
        color: $n-0;

        span.dot {
          display: block;
          animation: online-dot 1s steps(5, start) infinite;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: 100%;
    height: 26px;
    padding: 0 15px;
    border-radius: 6px 6px 0 0;

    .status {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-weight: 600;

      span.dot {
        display: none;
        width: 6px;
        height: 6px;
        margin-right: 5px;
        border-radius: 3px;
        background-color: white;
      }
    }

    .fly-campaign-card__header__time-tag {
      flex: 0 0 auto;
      display: none;
      align-items: center;
      justify-content: flex-end;
      width: auto;
      height: auto;

      @media #{$screen-800} {
        display: flex;
      }

      b {
        font-size: 10px;
        font-weight: 600;
        color: rgba($n-0, 0.9);
        white-space: nowrap;
      }
    }
  }

  &__content {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    padding: 15px;

    @media #{$screen-750} {
      padding: 10px;
    }

    &__campaign-name {
      width: 100%;
      height: auto;
      margin-bottom: 10px;

      @media #{$screen-750} {
        margin-bottom: 0;
      }

      b {
        font-size: 16px;
        font-weight: 500;
        color: $n-200;
        letter-spacing: 0.5px;
      }
    }

    &__dma-tag {
      display: inline-block;
      width: auto;
      height: 24px;
      border-radius: 4px;
      padding: 0 8px;
      background-color: rgba($n-200, 0.08);

      @media #{$screen-800} {
        display: none;
      }

      b {
        font-size: 14px;
        color: $n-200;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__time-tag {
      display: inline-block;
      width: auto;
      height: 24px;
      border-radius: 4px;
      padding: 0 8px;
      background-color: rgba($n-200, 0.08);
      margin-left: 5px;

      @media #{$screen-800} {
        display: none;
      }

      b {
        font-size: 14px;
        color: $n-200;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__impression {
      width: auto;
      height: auto;

      @media #{$screen-800} {
        display: none;
      }

      b {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: $b-300;
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    padding: 0 15px 15px 15px;

    @media #{$screen-800} {
      display: none;
    }

    &__item {
      width: auto;
      height: auto;

      & + .fly-campaign-card__date__item {
        margin-left: 24px;
      }

      p {
        display: block;
        font-size: 12px;
        font-weight: 600;
        color: rgba($n-200, 0.7);
        margin-bottom: 5px;
      }

      b {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: $n-200;
      }
    }
  }
}
