.AppContainer {
  height: 100%;

  > div {
    height: 100%;
  }
}

.geometrics-layout {
  margin-left: -30px;
  margin-right: -30px;
}
