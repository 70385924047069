.fly-combo-box {
  width: 166px;
  height: 35px !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;

  &.has-margin-left {
    margin-left: 20px;
  }

  &.has-margin-right {
    margin-right: 10px;
  }

  &.width-flex {
    width: 100%;
  }

  &--large {
    width: 220px;
  }

  &--large-events {
    width: 216px;
  }

  &--xlarge {
    width: 330px;
  }

  &--medium {
    width: 160px;
  }

  &--small {
    width: 76px;
  }

  &--has-icon {
    .select {
      &__control {
        padding-left: 22px;
      }
    }
  }

  &.has-select-value-margin-left {
    .select__single-value {
      margin-left: 8px;
    }
    .select__input {
      margin-left: 6px;
    }
  }

  &.planner-city-filter {
    height: auto !important;
    min-height: 48px;

    .select {
      min-height: 48px;

      &__control {
        min-height: 48px;
        background-color: $n-0;
        box-shadow: 0 6px 20px 0 rgba($shadow-color, 0.06);

        &:hover {
          background-color: $n-0;
          box-shadow: 0 8px 20px 0 rgba($shadow-color, 0.15);
        }
      }

      &__value-container {
        i.icon {
          font-size: 30px;
          margin-top: 4px;
        }
      }

      &__placeholder {
        margin-left: 32px;
      }

      &__multi-value {
        height: 28px;

        &__label {
          font-size: 14px;
        }
      }
    }
  }

  & + .fly-combo-box {
    margin-left: 10px;
  }

  & + .fly-search {
    margin-left: 10px;
  }

  .svg {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 10px;
    z-index: 10;
  }

  .svg-events {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    z-index: 10;
  }

  .select {
    width: 100%;
    height: 100%;

    &__placeholder {
      //margin-left: 25px;
    }

    &__control {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      min-height: auto;
      background-color: $n-10;
      border-radius: 6px;
      border: 2px solid transparent;
      cursor: pointer;
      box-shadow: none;

      @include transition-general;

      &:active {
        background-color: $n-20;
        border-color: $b-200;
      }

      &:hover {
        background-color: $n-20;
      }

      &:focus {
        background-color: $n-20;
      }

      &--menu-is-open,
      &--is-focused {
        border-radius: 6px !important;
        border-color: $b-200;
        background-color: $n-0;

        .Select-arrow-zone {
          margin-top: -2px;
        }
      }

      .select-multi-value-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        padding-left: 40px;

        .select-value {
          left: 40px;
          right: 20px;
          padding-left: 0;
          padding-right: 0;
        }

        .select-input {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding-left: 0;

          input {
            display: flex;
            align-items: center;
            width: 100% !important;
            height: 100%;
            padding: 0;
          }
        }

        .select-arrow-zone {
          padding-right: 0;
          width: 20px;
        }
      }
    }

    &__value-container {
      position: inherit;
      width: 100%;
      height: 100%;
      font-size: 14px;
      padding-top: 0;
      padding-bottom: 0;
    }

    &__multi-value {
      display: flex;
      align-items: center;
      width: auto !important;
      background-color: $n-20;
      color: $n-200;
      border-radius: 4px;

      &__label {
        color: $n-200;
        font-weight: 500;
      }

      &__remove {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        padding: 0;
        margin-right: 3px;
        color: $n-200;
        opacity: 0.6;

        &:hover {
          opacity: 1;
          background-color: $n-0;
          color: $n-200;
        }
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 0;
      color: $n-200;
    }

    .select-menu-outer {
      border-radius: 6px;
      margin-top: 10px;
      padding: 10px 0;
      border: none;

      @include e400;

      .select-menu {
        border-radius: 0;

        .select-option {
          border-radius: 0;

          &:hover,
          &.is-focused {
            background-color: $n-10;
          }

          &:active &:last-child {
            border-radius: 0;
          }

          &.is-selected {
            background-color: $n-100;
            color: $n-0;
          }
        }

        .select-noresult {
          border-radius: 0 0 6px 6px !important;
        }
      }
    }

    &.is-open {
      .select {
        &__control {
          border-radius: 6px !important;
          border-color: $b-200;
          background-color: $n-0;

          .Select-arrow-zone {
            margin-top: -2px;
          }
        }
      }
    }

    &.is-focused:not(.is-open) {
      border: none;

      .select__control {
        border-color: $b-200;
        background-color: $n-0;
        box-shadow: none;
      }
    }
  }
}
