.fr-right {
  .fly-map-filter {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 110;

    &__header {
      position: relative;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      width: 100%;
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px solid $gray-6;
      box-shadow: 0 2px 7px 0 rgba($dark-ocean, 0.1);
      z-index: 1;

      b {
        font-size: 16px;
        font-weight: 600;
        color: $dark-ocean;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      padding: 15px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__result {
      flex: 0 0 auto;
      width: 100%;
      height: auto;
      background-color: $gray-3;
      border-top: 1px solid $gray-5;
      padding: 10px 15px 20px 15px;

      b {
        display: block;
        font-size: 14px;
        font-weight: 600;
        color: $dark-ocean;
        margin-bottom: 10px;
      }

      .result-box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: auto;

        &__item {
          flex: 8 8 auto;
          width: 100%;
          height: auto;

          b {
            display: block;
            margin-bottom: 3px;
            font-size: 20px;
            font-weight: 600;
            color: $dark-ocean;
          }

          p {
            display: block;
            font-size: 10px;
            font-weight: 600;
            color: $dark-ocean;
          }

          &.hours {
            flex: 6 6 auto;
          }

          &.impression {
            flex: 7 7 auto;
          }

          & + .result-box__item {
            margin-left: 10px;
          }
        }
      }
    }

    &__filter-con {
      width: 100%;
      height: auto;
      background-color: $white;
      border: 1px solid $gray-6;
      border-radius: 10px;
      box-shadow: 0 2px 7px 0 rgba($dark-ocean, 0.1);

      .filter-header {
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        height: 45px;
        border-radius: 9px;
        cursor: pointer;
        @include transition-general;

        &:hover {
          background-color: rgba($main-dark, 0.05);
        }

        &__text {
          display: block;
          flex: 1 1 auto;
          font-size: 14px;
          font-weight: 600;
          color: $main-dark;
        }

        &__indicator {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: 15px;
          min-width: 22px;
          margin-right: 5px;
          border-radius: 13px;
          background-color: rgba($dark-ocean, 0.72);

          p {
            font-size: 12px;
            color: $white;
            font-weight: 600;
          }
        }

        &__open-close {
          position: relative;
          height: 100%;
          width: 16px;
          margin-left: 5px;

          span {
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 6px);
            display: block;
            width: 12px;
            height: 2px;
            background-color: $b-100;

            @include transition-general;

            &.line-2 {
              transform: rotate(90deg);
            }
          }
        }
      }

      .filter-content {
        display: none;
        width: 100%;
        height: auto;

        &__filter {
          width: 100%;
          height: auto;
          padding: 20px 15px;

          //Date filter style
          .DayPicker {
            width: 100%;

            &:focus {
              outline: none;
            }

            .DayPicker-wrapper {
              padding-bottom: 0;

              &:focus {
                outline: none;
              }
            }

            .DayPicker-NavBar {
              .DayPicker-NavButton {
                top: -3px;
                background-size: 45%;

                &:focus {
                  outline: none;
                }

                &--prev {
                  left: 70px;
                }

                &--next {
                  right: 70px;
                }
              }
            }

            .DayPicker-Month {
              width: 100%;
              margin: 0;

              .DayPicker-Caption {
                text-align: center;
                width: 200px;
                margin: 0 auto;
                font-weight: normal;
                margin-bottom: 20px;
                font-size: 15px;

                div {
                  display: inline-block;
                  color: $dark-ocean;
                  font-size: 15px;
                  font-weight: normal;
                }
              }
            }
          }

          .month-selector {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            color: $main-dark;
            text-align: center;

            i.icon {
              height: 26px;
              width: 26px;
              font-size: 25px;
              margin-left: 20px;
              margin-right: 20px;
            }

            b {
              height: auto;
              margin-top: -5px;
              font-size: 15px;
            }
          }

          .input-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;
            margin-bottom: 20px;

            input {
              width: 130px;
              height: 28px;
              border: 1px solid $main-dark;
              border-radius: 4px;
              font-size: 13px;
              text-align: center;
              color: $main-dark;

              &:focus {
                border: 1px solid $b-100;
                outline: none;
                box-shadow: inset 0 2px 3px 0 rgba($main-dark, 0.2),
                  0 2px 10px 0 rgba(darken($b-100, 40%), 0.2);
                @include transition-general;
              }
            }

            .divider {
              width: 10px;
              height: 1px;
              background-color: $main-dark;
            }
          }

          //Hour filter style
          .hour-filter {
            display: flex;
            align-items: center;

            .hour-box {
              width: auto;
              height: auto;
              flex: 1 1 auto;

              b.label {
                display: block;
                margin-bottom: 3px;
                font-size: 12px;
                color: $dark-ocean;
              }

              & + .hour-box {
                margin-left: 10px;
              }
            }

            button.fly-button {
              flex: 0 0 auto;
              height: 35px;
              border-radius: 20px;
              min-width: 60px;
              align-self: flex-end;
              margin-left: 10px;
            }

            & + .hour-range-item {
              margin-top: 15px;
            }
          }

          .hour-range-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 35px;
            padding-left: 10px;
            padding-right: 5px;
            background-color: $gray-3;
            border: 1px solid $gray-5;
            border-radius: 4px;
            box-shadow: 0 2px 7px 0 rgba($dark-ocean, 0.05);

            b {
              display: block;
              flex: 1 1 auto;
              font-size: 14px;
              font-weight: 600;
              color: $dark-ocean;
            }

            .delete {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              border-radius: 4px;

              i.icon {
                margin-top: 4px;
                color: rgba($dark-ocean, 0.8);
              }

              &:hover {
                background-color: $white;
              }
            }

            & + .hour-range-item {
              margin-top: 10px;
            }
          }

          //Filter section (Campaign & Zip code filters)
          .filter-section {
            &__label {
              width: 100%;
              height: auto;
              margin-bottom: 5px;

              b {
                display: block;
                margin-bottom: 3px;
                font-size: 12px;
                color: $dark-ocean;
              }
            }

            .select-item {
              display: flex;
              align-items: center;
              width: 100%;
              height: 35px;
              padding-left: 10px;
              padding-right: 5px;
              background-color: $gray-3;
              border: 1px solid $gray-5;
              border-radius: 4px;
              box-shadow: 0 2px 7px 0 rgba($dark-ocean, 0.05);

              b {
                display: block;
                flex: 1 1 auto;
                font-size: 14px;
                font-weight: 600;
                color: $dark-ocean;
              }

              .delete {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                border-radius: 4px;

                i.icon {
                  margin-top: 2px;
                  color: rgba($dark-ocean, 0.8);
                  font-size: 24px;
                }

                &:hover {
                  background-color: $white;
                }
              }

              & + .select-item {
                margin-top: 10px;
              }
            }

            .zip-code-filter {
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .fly-textarea {
                margin-bottom: 8px;
              }

              .undetected-zipcode-box {
                display: flex;
                flex-wrap: wrap;
                font-size: 12px;
                font-weight: 400;
                color: $dark-ocean;

                b {
                  display: block;
                  width: 100%;
                  height: auto;
                  font-size: 12px;
                  font-weight: 400;
                  color: $dark-ocean;
                }

                &__item {
                  display: flex;
                  align-items: center;
                  width: auto;
                  height: 20px;
                  margin: 2px;
                  padding: 0 2px 0 4px;
                  border-radius: 3px;
                  background: rgba($b-300, 0.2);

                  b {
                    flex: 1 1 auto;
                    display: block;
                    margin-right: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba($dark-ocean, 0.8);
                  }

                  .delete {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                    border-radius: 2px;

                    &:hover {
                      background-color: rgba($white, 0.5);
                    }

                    i.icon {
                      margin-top: 3px;
                      font-size: 15px;
                      color: rgba($dark-ocean, 0.8);
                    }
                  }
                }
              }
            }
          }

          //Draw filter
          .draw-filter {
            display: none;
            width: 100%;
            height: auto;
            z-index: 100;

            &.is-active {
              display: block;
            }

            .image {
              width: 50px;
              height: 50px;
              margin: 0 auto;
              background-image: url(../image/draw-icon@2x.png);
              background-size: auto 100%;
              background-repeat: no-repeat;
              background-position: center;
            }

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 27px;
              margin: 20px auto;
              font-size: 12px;
              padding: 0px 12px;
              border-radius: 14px;
              background-color: $dark-ocean;
              color: $white;

              i.icon {
                font-size: 22px;
                margin-right: 5px;
                margin-top: 4px;
              }
            }
          }
        }

        &__action-box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          height: 45px;
          padding-left: 15px;
          padding-right: 15px;
          border-top: 1px solid $gray-6;

          button + button {
            margin-left: 5px;
          }
        }

        .settings-item {
          width: 100%;
          height: auto;
          padding-top: 8px;
          padding-bottom: 8px;

          &__label {
            display: block;
            font-size: 12px;
            color: $main-dark;
            margin-bottom: 5px;
          }

          &__content {
            display: flex;
            align-items: center;
            width: 100%;
            height: auto;

            .selector-box {
              display: flex;
              align-items: center;
              width: 100%;
              height: auto;

              button.selector {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                min-width: 40px;
                height: 20px;
                border-radius: 10px;
                padding-left: 8px;
                padding-right: 8px;

                &:hover {
                  background-color: rgba($dark-ocean, 0.1);
                }

                b {
                  font-size: 13px;
                  color: $main-dark;
                  font-weight: 600;
                }

                &.is-active {
                  background-color: $b-100;

                  b {
                    color: $white;
                  }
                }

                & + button {
                  margin-left: 4px;
                }
              }
            }
          }

          & + .settings-item {
            border-top: 1px solid $gray-6;
          }
        }
      }

      & + .fly-map-filter__filter-con {
        margin-top: 10px;
      }

      &.is-active {
        .filter-header {
          border-radius: 10px 10px 0 0;
          border-bottom: 1px solid $gray-6;

          &__open-close {
            span.line-2 {
              transform: rotate(0deg);
            }
          }
        }
        .filter-content {
          display: block;
        }
      }

      &.filter-active {
        border: 1px solid rgba($b-100, 0.5);
      }
    }

    .section-divider {
      flex: 0 0 auto;
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: $gray-5;
    }
  }
}

.has-filter {
  .fr-right {
    .open-filter-btn {
      display: none;
    }
  }
}

.select-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 0 40px 0 10px;
  margin: 10px 0;
  border-radius: 6px;
  color: $n-200;
  background-color: $n-20;

  .gradient,
  .gradient-t1,
  [class^="graident"] {
    display: block;
    width: 34px;
    height: 12px;
    margin-bottom: 0;
    background: linear-gradient(to right, #209cff 0%, rgba(#209cff, 0) 100%);
  }

  .favorite-btn,
  .delete {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-left: 10px;
    text-align: center;

    &:hover {
      background-color: $n-0;
    }
  }

  .favorite-btn {
    right: 30px;
    top: 4px;
    display: flex;
    justify-content: center;
  }

  b {
    font-size: 13px;
  }

  i {
    font-size: 24px;
    margin-top: 2px;
    color: rgba($n-200, 0.8);
  }
}
