.react-photo-gallery--gallery {
  & > div {
    display: grid !important;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 100%;
    height: auto;

    @media #{$screen-800} {
      display: flex !important;
      flex-direction: row;
      flex-wrap: nowrap !important;
      align-items: center;
      justify-content: flex-start;
      width: calc(100% + 70px);
      margin-left: -35px;
      padding: 6px 0 6px 35px;
      overflow-x: scroll;

      &::after {
        flex: 0 0 auto;
        display: inline-block;
        width: 35px;
        height: 35px;
        content: "";
      }

      & > div {
        & + div {
          margin-left: 15px !important;
        }
      }
    }

    & > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100% !important;
      height: auto !important;
      overflow: hidden;
      border-radius: 6px;

      @include transition-general;
      @include e200;

      @media #{$screen-800} {
        width: 250px !important;
        height: 150px !important;
        flex: 0 0 auto;
      }

      &.not-selected:hover {
        outline: none;
        box-shadow: 0 0 0 4px $b-200;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
