.fly-inventory-outlook {
  &-date-filter-container {
    display: flex;
    justify-content: space-between;

    .fly-inventory-outlook-date-filter {
      .filter-btn {
        height: 28px;
        padding: 0 10px;
        border-radius: 16px;
        background-color: #ffffff;
        color: $n-100;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.48px;
        text-align: center;
        margin-right: 12px;

        &:hover {
          background-color: lightgray;
        }

        &.active {
          background-color: $main-dark;
          color: white;
        }
      }
    }
  }
}
