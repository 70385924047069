.fly-button {
  display: flex;
  justify-content: center;
  align-items: center;

  b {
    @include noselect;
    font-size: 14px;
    font-weight: 500;
  }

  &.primary,
  &.alert {
    width: auto;
    height: 30px;
    padding: 0 10px;
    border-radius: 4px;
    background: linear-gradient(
      135deg,
      lighten($b-200, 5%) 0%,
      darken($b-200, 5%) 100%
    );
    color: $white;
    &:hover {
      background: linear-gradient(
        135deg,
        lighten($b-200, 1%) 0%,
        darken($b-200, 9%) 100%
      );
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.slim {
      height: 20px;
    }
  }

  &.alert {
    background: red;
    color: white;

    &:hover {
      background: darkred;
    }
  }

  &.secondary {
    width: auto;
    height: 30px;
    padding: 0 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    color: $n-100;

    &:hover {
      background-color: $n-20;
      color: $n-200;
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.slim {
      height: 20px;
    }

    &.modal {
      border-radius: 6px;
    }
  }

  &.white-holder-btn {
    height: 23px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 15px;
    border-radius: 12px;
    background-color: $blue-gray-5;

    &:hover {
      background-color: darken($blue-gray-5, 3%);
    }

    b {
      font-size: 12px;
      font-weight: 600;
      color: $continental-blue;
      white-space: nowrap;
    }
  }

  &.settings-btn {
    height: 33px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 30px;
    margin-top: 15px;
    border-radius: 12px;
    background-color: $blue-gray-5;

    &:hover {
      background-color: darken($blue-gray-5, 3%);
    }

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
      pointer-events: none;
    }

    b {
      font-size: 16px;
      font-weight: 600;
      color: $continental-blue;
      white-space: nowrap;
    }
  }

  &.dark {
    width: auto;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    background: $dark-ocean;
    color: $white;

    b {
      font-size: 14px;
    }

    &:hover {
      background: darken($dark-ocean, 5%);
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.slim {
      height: 20px;
    }
  }

  &.noborder {
    width: auto;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 3px;
    background: rgba(31, 31, 30, 0.08);
    color: $white;
    margin-top: 10px;
    visibility: hidden;

    b {
      font-size: 13px;
      color: $n-100;
      font-weight: 600;
    }

    &:hover {
      background: rgba(31, 31, 30, 0.16);
    }

    &:active {
      box-shadow: inset 0 1px 3px 0 rgba($n-200, 0.31);
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.slim {
      height: 20px;
    }

    &.visible {
      visibility: visible;
    }
  }

  &.light {
    width: auto;
    height: 30px;
    padding: 0 15px;
    border-radius: 4px;
    background: rgba($n-200, 0.15);
    color: $n-200;

    b {
      font-size: 14px;
    }

    &:hover {
      background: rgba($n-200, 0.35);
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &.inline {
    width: auto;
    height: 30px;
    padding: 0 10px;
    border-radius: 4px;
    color: $n-200;

    i.icon {
      &.ozzy-upload {
        margin-top: 2px;
      }

      &.ozzy-trash {
        margin-top: 6px;
        font-size: 27px;
      }
    }

    &:hover {
      background-color: $n-20;
    }

    &.slim {
      height: 20px;
    }

    &.blue {
      color: $b-200;
      padding-left: 0;
      padding-right: 0;
      height: auto;

      &:hover {
        background-color: transparent;

        b {
          text-decoration: underline;
        }
      }
    }
  }

  &.border-white {
    width: auto;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid $b-100;
    border-radius: 15px;
    font-size: 11px;
    background-color: #f7f7f7;
    color: $main-dark;
    box-shadow: 0 2px 4px rgba(#3b3e4d, 0.2);
    @include transition-general;

    &:hover {
      background-color: darken(#f7f7f7, 2%);
      box-shadow: 0 2px 10px rgba(#3b3e4d, 0.2);
    }

    b {
      font-size: 11px;
      line-height: 1.6;
    }

    i.icon {
      margin-left: 5px;

      &.ozzy-right-arrow {
        font-size: 18px;
        margin-top: 4px;
      }
    }
  }

  &__campaigns {
    font-size: 12px;
    letter-spacing: 0.29px;
    font-weight: 500;
    line-height: 14px;
    color: $n-100;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    &:hover {
      color: $b-200;
    }
    span {
      width: 22px;
      height: 22px;
      margin-left: -21px;
    }
  }

  &.border-gray {
    width: auto;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    border: 1px solid rgba($n-100, 0.38);
    border-radius: 3px;
    font-size: 11px;
    background-color: #f7f7f7;
    color: rgba($n-100, 0.38);
    @include transition-general;

    b {
      font-size: 11px;
      line-height: 1.6;
      width: 159px;
    }

    i.icon {
      margin-left: 5px;

      &.ozzy-left-arrow {
        font-size: 18px;
        margin-top: 4px;
      }
    }
  }

  &.top-header {
    @media #{$screen-800} {
      display: none;
    }
  }

  &.circle {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: $blue-gray-2;

    i.icon {
      font-size: 18px;
      color: $dark-ocean;

      &.ozzy-calculate {
        font-size: 36px;
        opacity: 0.8;
        margin-top: -0.5px;
      }
    }
  }

  &.subtle {
    width: auto;
    height: auto;
    color: $dark-ocean;

    i.icon {
      font-size: 28px;
      margin-top: 5px;
    }

    b {
      font-size: 14px;
      font-weight: 500;
    }

    &:hover {
      b {
        text-decoration: underline;
      }
    }
  }

  &__export {
    border-radius: 6px;
    height: 32px;
    width: 88px;
    padding: 0px 12px 0px 6px;
    margin-right: -11px;

    display: flex;
    justify-content: center;
    align-items: center;

    b {
      height: 16px;
      width: 45px;
      color: #434343;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.33px;
      line-height: 16px;
      text-align: right;
    }
    &:hover {
      background-color: $n-20;
      box-shadow: 0 3px 7px 0 rgba(15, 67, 180, 0.3);
    }

    &:active {
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.09),
        0 3px 7px 0 rgba(15, 67, 180, 0.3);
    }

    &:focus:not(:active) {
      box-shadow: 0 0 0 2px $b-200;
      border: 2px solid $n-10;
      background-color: $white;
    }
  }

  //Icon alignment fix
  .ozzy-download {
    padding-top: 3px;
    margin-left: -6px;
  }

  &.modal {
    border-radius: 6px;
    width: 75px;
    height: 32px;
  }
}
