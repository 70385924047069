.white-holder {
  position: relative;
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 6px;
  background-color: $n-0;
  box-shadow: 0 6px 20px 0 rgba($shadow-color, 0.06);

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;

    .header {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      color: $main-dark;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.2px;

      & + .fly-button {
        margin-left: 10px;
      }
    }

    .fly-calendar {
      position: absolute;
      right: 0;
      top: 10px;
    }

    &.has-header-padding-bottom {
      padding-bottom: 20px;
      margin-bottom: 0px;
      height: auto;
    }

    &.planner_result {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.33px;
      color: #2e2e2e;
    }
  }

  &__content {
    &:empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        display: block;
        width: 250px;
        height: 107px;
        margin-bottom: 10px;
        margin-top: 30px;
        background-image: url(../image/empty-img@2x.png);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
      }

      &::after {
        content: "There is no item";
        display: block;
        font-size: 14px;
        text-align: center;
        color: rgba($dark-ocean, 0.5);
        font-weight: 500;
      }
    }

    p {
      color: $main-dark;
      font-size: 13px;
      line-height: 1.4;
    }

    .word-break {
      word-break: break-word;
    }

    .campaign-goal {
      align-items: flex-start;
      height: 36px;

      .header {
        font-size: 12px;
      }
    }

    .summary-box {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: 100%;
      border-radius: 6px;
      background-color: #f4f5f7;
      padding: 24px;
      justify-content: space-around;

      @media #{$screen-1300} {
        &::before {
          display: none;
        }

        ::after {
          display: none;
        }
      }

      &__item {
        display: flex;
        justify-content: space-between;

        .fly-svg-icon {
          width: 40px;
          height: 40px;
        }

        .info-container {
          margin-left: 12px;
        }

        .label {
          overflow-y: visible;
          width: max-content;
          margin-bottom: 7px;
          opacity: 0.7;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.35px;
          color: #2e2e2e;
        }

        .summary-number {
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.56px;
          color: #2e2e2e;
          margin-bottom: 10px;
        }

        span.goal {
          display: block;
          color: rgba($main-dark, 0.7);
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.25px;
          color: #4f4f4f;
          margin-top: 6px;
        }
      }

      .yesterday-impression {
        position: absolute;
        right: 0px;
        bottom: 0px;
        border-radius: 6px 0 6px 0;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        background: #6b6b6b;
        color: white;
        padding: 6px;
      }
    }

    .summary-box-wrapper {
      display: flex;
      justify-content: flex-start;

      .summary-box-part1 {
        @media #{$screen-800} {
          display: none;
        }
        flex: 1.5;
        display: flex;
        flex-direction: column;

        .summary-text-wrapper {
          display: flex;
          height: 90%;

          .has-scroll {
            height: 96px;
            overflow: hidden;
            overflow-y: scroll;
            font-size: 14px;
            letter-spacing: 0.39px;
            line-height: 20px;
            padding-right: 20px;
            text-align: justify;
          }
          .has-scroll::-webkit-scrollbar {
            display: none; // Safari and Chrome
          }

          .summary-text {
            flex: 3;
          }
          .empty-space {
            flex: 1;
          }
        }

        .scroll-gradient {
          height: 10%;
          position: absolute;
          bottom: 0px;
          left: 0px;
          height: 45px;
          width: 58.5%;
          background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            $white
          );
          border-radius: 6px;
        }
      }

      .divider {
        flex: 0;
        margin-right: 30px;
        min-width: 1px;
        background-color: rgba($main-dark, 0.2);

        @media #{$screen-800} {
          display: none;
        }
      }

      .summary-box-part2 {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media #{$screen-800} {
          flex-direction: column;
          align-items: flex-start;
        }

        &__item {
          flex: 1;
          margin-right: 78px;

          @media #{$screen-1400} {
            margin-right: 30px;
          }
          .label {
            color: $main-dark;
            overflow-y: visible;
            width: max-content;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.31px;
            line-height: 18px;
          }

          .summary-number {
            color: $b-300;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0.86px;
            line-height: 49px;
          }

          span.goal {
            display: block;
            color: rgba($main-dark, 0.7);
            font-size: 10px;
          }
        }
      }
    }

    .campaign-summary {
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: auto;
      padding: 25px;

      &__box {
        display: block;
        flex: 1 1 auto;

        @media #{$screen-800} {
          display: none;
        }
      }

      &__impression {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;

        @media #{$screen-1024} {
          padding: 0 20px;
        }

        @media #{$screen-800} {
          padding: 0;
        }

        @media #{$screen-750} {
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
        }

        &__item {
          width: auto;
          height: auto;

          & + .campaign-summary__impression__item {
            margin-left: 80px;

            @media #{$screen-1024} {
              margin-left: 40px;
            }

            @media #{$screen-750} {
              margin-left: 0;
              margin-top: 20px;
            }
          }

          p {
            display: block;
            font-size: 13px;
            color: $n-200;
            font-weight: 500;

            @media #{$screen-1024} {
              font-size: 10px;
              font-weight: 600;
            }
          }

          b {
            display: block;
            font-size: 36px;
            color: $b-300;
            font-weight: 500;

            @media #{$screen-1024} {
              font-size: 30px;
            }
          }
        }
      }

      &__divider {
        flex: 0 0 auto;
        width: 1px;
        height: 100%;
        background-color: $n-20;
        margin: 0 20px;

        @media #{$screen-800} {
          display: none;
        }
      }

      .yesterday-impression {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0px 6px 0 6px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        background: #6b6b6b;
        color: white;
        padding: 6px;
      }
    }
  }

  &.has-header {
    padding-top: 0;
  }

  &.has-no-header-mobile {
    @media #{$screen-800} {
      padding: 15px;

      .white-holder__header {
        display: none;
      }
    }
  }

  &.has-no-bg-mobile {
    @media #{$screen-800} {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      padding: 0;

      .white-holder__header {
        display: none;
      }
    }
  }

  &.has-action-box {
    .white-holder__header {
      justify-content: space-between;
    }
  }

  &.has-calendar {
    .white-holder__header {
      padding-right: 220px;
    }
  }

  &.has-expendable {
    padding-bottom: 0;

    .white-holder__header {
      padding-right: 30px;

      button.expand-btn {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 16px;
        margin-left: 5px;

        span {
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - 6px);
          display: block;
          width: 12px;
          height: 2px;
          background-color: $b-300;
          @include transition-general;

          &.line-2 {
            transform: rotate(90deg);
          }
        }
      }
    }

    .white-holder__content {
      display: none;
    }

    &.is-active {
      padding-bottom: 20px;

      .white-holder__header {
        button.expand-btn {
          .line-2 {
            transform: rotate(0deg);
          }
        }
      }

      .white-holder__content {
        display: block;

        &:empty {
          display: flex;
        }
      }
    }
  }

  &.has-no-bg {
    background-color: transparent;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
  }

  & + .white-holder {
    margin-top: 20px;
  }
}
