.fr-left {
  .menu-wrapper {
    width: 100%;
    display: block;

    .menu-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        bottom: 10px;
        display: block;
        left: -6px;
        width: 6px;
        border-radius: 0 3px 3px 0;
        background-color: $b-200;

        @include transition-general;
      }

      .tool-tip {
        display: none;
        position: absolute;
        left: 70px;
        height: 30px;
        width: auto;
        border-radius: 2px;
        padding: 8px 12px 0 12px;
        background-color: $n-100;

        &::after {
          content: "";
          position: absolute;
          display: block;
          left: -6px;
          top: 9px;
          border-right: 6px solid $n-100;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
        }

        b {
          display: block;
          white-space: nowrap;
          font-size: 12px;
          color: $n-0;
        }
      }

      i.icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        width: 34px;
        height: 34px;
        margin-top: 5px;
        color: $n-0;
        line-height: 34px;
        font-size: 28px;
        text-align: center;
      }

      &.is-active {
        background: linear-gradient(
            103deg,
            rgba($b-200, 0.16) 0%,
            $n-200 30%,
            $n-200 100%
          ),
          $n-200;
        box-shadow: inset 5px 0 5px 0 rgba($n-900, 0.1);

        i.icon {
          color: $n-0;
        }

        &:before {
          left: 0;
        }
      }

      &:hover {
        background-color: rgba($n-200, 0.6);

        .tool-tip {
          display: block;
          b {
            opacity: 1;
          }
        }
      }

      .fly-svg-icon {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.has-full-menu {
  .logo-con {
    background-color: lighten(#ff735b, 3%);
    z-index: 350;
    opacity: 1;
    margin-left: 0;
  }

  .wide-menu-btn {
    padding-top: 0;
    border-left: 1px solid darken(#ff735b, 7%);

    i.icon {
      transform: rotate(180deg);
      margin-top: -3px;
    }
  }
}
