.fly-playlist {
  width: 100%;
  height: auto;

  &__item {
    display: block;
    width: 100%;
    height: auto;

    & + .fly-info {
      margin-top: 20px;
    }

    & + .fly-playlist__item {
      margin-top: 20px;
    }

    b.label {
      display: block;
      font-size: 10px;
      font-weight: 600;
      color: rgba(68, 72, 85, 0.8);
      margin-bottom: 10px;
    }

    .geofence-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: auto;

      &__item {
        display: flex;
        align-items: center;
        width: auto;
        height: 24px;
        border-radius: 4px;
        background-color: $gray-6;
        padding-left: 8px;
        padding-right: 8px;
        margin: 0 8px 8px 0;

        p {
          width: 100%;
          display: block;
          font-size: 14px;
          color: $dark-ocean;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .action-box {
      display: flex;
      align-items: center;

      button + button {
        margin-left: 16px;
      }
    }
  }

  &__item-geo {
    display: block;
    width: 100%;
    height: auto;
    padding: 15px 10px 10px 10px;

    & + .fly-info {
      margin-top: 20px;
    }

    & + .fly-playlist__item {
      margin-top: 20px;
    }

    b.label {
      display: block;
      font-size: 10px;
      font-weight: 600;
      color: rgba(68, 72, 85, 0.8);
      margin-bottom: 10px;
    }

    .geofence-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: auto;

      &__item {
        display: flex;
        align-items: center;
        width: auto;
        height: 24px;
        border-radius: 4px;
        background-color: $gray-6;
        padding-left: 8px;
        padding-right: 8px;
        margin: 0 8px 8px 0;

        p {
          width: 100%;
          display: block;
          font-size: 14px;
          color: $dark-ocean;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .action-box {
      display: flex;
      align-items: center;

      button + button {
        margin-left: 16px;
      }
    }
  }
}

.creative-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .playlist-creative-list {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    width: 100%;
    height: auto;

    &__creative {
      .creative {
        position: relative;
        flex: 0 0 auto;
        width: 100%;
        height: auto;
        border-radius: 0 4px 4px 0;
        padding-left: 22px;
        margin-top: 8px;

        & + .creative {
          margin-left: 30px;
        }

        .num-box {
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          width: 20px;
          height: calc(100% + 5px);
          border-radius: 4px 4px 0 4px;
          background-color: $main-dark;
          box-shadow: 0 2px 10px 0 rgba($main-dark, 0.2);

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            padding-top: 5px;
            color: $white;
            font-size: 12px;
            font-weight: 600;
          }
        }

        .img {
          width: 112px;
          height: 41px;
          background-color: darkgrey;
          border-radius: 0 4px 4px 0;
          box-shadow: 0 2px 10px 0 rgba($main-dark, 0.2);
        }

        .select-box {
          display: none;
        }

        &.has-select {
          position: relative;
          cursor: pointer;

          .select-box {
            position: absolute;
            width: 114px;
            top: 0;
            bottom: 0;
            left: 20px;
            right: 0;
            display: block;
            border: 2px solid $pumpkin;
            border-radius: 0 4px 4px 0;
            z-index: 10;
            opacity: 0;
            @include transition-general;

            &:before {
              content: "";
              position: absolute;
              top: -13px;
              right: -3px;
              display: block;
              border-left: 20px solid $pumpkin;
              border-top: 20px solid transparent;
              border-bottom: 20px solid transparent;
              transform: rotate(-45deg);
            }

            i.icon {
              position: absolute;
              top: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 15px;
              height: 15px;
              color: $white;
            }
          }

          &:hover {
            .image {
              box-shadow: 0 7px 20px rgba(25, 27, 30, 0.3);
            }
          }

          &.is-selected {
            .select-box {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.creative-list--drag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .creative {
    position: relative;
    flex: 0 0 auto;
    width: 112px;
    height: 41px;
    border-radius: 0 4px 4px 0;
    padding-left: 22px;
    margin-top: 8px;

    & + .creative {
      margin-left: 30px;
    }

    .num-box {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 20px;
      height: calc(100% + 5px);
      border-radius: 4px 4px 0 4px;
      background-color: $pumpkin;
      box-shadow: 0 14px 25px -6px rgba($dark-ocean, 0.8);
      transform: rotate(5deg) translate(0px, -6px);
      @include transition-general;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        padding-top: 5px;
        color: $white;
        font-size: 12px;
        font-weight: 600;
      }
    }

    .img {
      width: 112px;
      height: 41px;
      background-color: darkgrey;
      border-radius: 0 4px 4px 0;
      box-shadow: 0 14px 25px -6px rgba($dark-ocean, 0.8);
      transform: rotate(5deg);
      @include transition-general;
    }

    .select-box {
      display: none;
    }

    &.has-select {
      position: relative;
      cursor: pointer;

      .select-box {
        position: absolute;
        width: 114px;
        top: 0;
        bottom: 0;
        left: 20px;
        right: 0;
        display: block;
        border: 2px solid $pumpkin;
        border-radius: 0 4px 4px 0;
        z-index: 10;
        opacity: 0;
        @include transition-general;
        transform: rotate(5deg);

        &:before {
          content: "";
          position: absolute;
          top: -13px;
          right: -3px;
          display: block;
          border-left: 20px solid $pumpkin;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          transform: rotate(-45deg);
        }

        i.icon {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          color: $white;
        }
      }

      &:hover {
        .image {
          box-shadow: 0 7px 20px rgba(25, 27, 30, 0.3);
        }
      }

      &.is-selected {
        .select-box {
          opacity: 1;
        }
      }
    }
  }
}

.zipcode-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: auto;
    height: 24px;
    padding: 0 5px;
    border-radius: 4px;
    background-color: $gray-6;
    color: rgba($dark-ocean, 0.8);
    font-size: 12px;
    font-weight: 500;
    margin: 0 8px 8px 0;
  }
}

.zipcode-list-geo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: auto;
    height: 24px;
    padding: 0 5px;
    border-radius: 4px;
    background-color: $white;
    color: rgba($dark-ocean, 0.8);
    font-size: 12px;
    margin: 0 8px 8px 0;
  }
}
