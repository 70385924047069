/* Burak Başcı -- 2018 */

/* This is a framework of app and below, there is layout styles */

.firefly-frame {
  width: 100%;
  height: 100%;
  position: relative;

  @include transition-general;

  .fr-frame {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 56px;

    @include transition-general;

    @media #{$screen-750} {
      padding-bottom: 50px;
    }

    &.no-padding-bottom {
      padding-bottom: 0px;
    }

    &.has-detail-padding {
      padding-top: 160px;
    }

    &.has-menu-padding {
      padding-top: 55px;
    }

    &.has-no-menu-padding {
      padding-top: 120px;
    }

    &.fly-admin {
      padding-left: 0px;
      min-width: 1024px;

      .fr-top {
        padding-left: 0;
      }

      @media #{$screen-750} {
        padding-left: 0;
        min-width: auto;
      }
    }
  }

  .fr-left {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    width: 60px;
    height: 100%;
    padding: 18px 0 24px 0;
    background-color: $n-100;
    box-shadow: 0 0 20px 0 rgba($n-200, 0.15);
    z-index: 600;

    @include transition-general;

    @media #{$screen-750} {
      display: none;
    }
  }

  .fr-right {
    position: absolute;
    top: 48px;
    right: -350px;
    width: 350px;
    height: calc(100% - 48px);
    background-color: $white;
    z-index: 450;
    box-shadow: 0 0 10px 0 rgba($n-200, 0.3);
  }

  .fr-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background-color: $n-0;
    z-index: 500;
    @include transition-general;

    &.zIndex-none {
      z-index: 0;
    }
  }

  .fr-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0;
    background-color: $n-100;
    box-shadow: 0 0 20px 0 rgba($n-200, 0.15);
    z-index: 400;

    @include transition-general;

    @media #{$screen-min-750} {
      display: none;
    }
  }

  .fr-main {
    position: relative;
    height: 100%;
    background-color: $background;
    overflow-y: auto;

    &.customer-screen {
      overflow: hidden;

      .main-wrapper {
        overflow-y: auto;

        @media #{$screen-800} {
          overflow-y: scroll;
        }
      }
    }

    @media #{$screen-800} {
      padding-left: 0px;
    }

    .fr-page {
      flex: 1 1 auto;
      width: 100%;
      height: auto;
      padding: 20px;

      @media #{$screen-750} {
        padding: 20px 10px;
      }

      &.padding-top-bottom {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;
      }

      &__header {
        position: relative;
        width: 100%;
        height: auto;
        margin-bottom: 30px;

        .campaign-header {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;

          &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;

            &__left {
              display: flex;
              align-items: center;
            }

            &__right {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }

          &__bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;

            &__left {
              .tag-box {
                display: flex;
                align-items: center;
              }
            }

            &__right {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }

          .header {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 10px;

            a {
              display: inline-block;
              color: $main-dark;
              font-size: 30px;
              font-weight: 500;
            }

            i.icon {
              display: inline-block;
              margin-left: 10px;
              font-size: 35px;
              margin-top: 4px;

              &.live {
                color: $bright-green;
              }

              &.paused {
                color: $bright-yellow;
              }

              &.ended {
                color: $bright-red;
              }
            }

            i.icon-live {
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: green;
              margin-left: 10px;
              margin-top: 4px;
              border-radius: 5px;
            }

            i.icon-paused {
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: yellow;
              margin-left: 10px;
              margin-top: 4px;
              border-radius: 5px;
            }

            i.icon-ended {
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: red;
              margin-left: 10px;
              margin-top: 4px;
              border-radius: 5px;
            }
          }

          .info-box {
            position: relative;
            display: flex;
            align-items: center;

            &__item {
              display: flex;
              align-items: center;

              b,
              p {
                font-size: 13px;
                color: $main-dark;
              }

              p {
                margin-right: 5px;
              }

              b {
                font-weight: 600;
              }

              & + .info-box__item {
                margin-left: 15px;

                &:before {
                  content: "";
                  display: block;
                  width: 1px;
                  height: 15px;
                  margin-right: 15px;
                  background-color: rgba($main-dark, 0.5);
                }
              }
            }
          }

          .tag-box + .info-box {
            margin-left: 20px;
          }
        }

        .page-header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: auto;

          b {
            display: block;
            flex: 0 0 auto;
            font-size: 18px;
            font-weight: 600;
            color: $main-dark;

            & + .fly-search {
              margin-left: 20px;
              padding-left: 10px;

              &:before {
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                width: 1px;
                height: 20px;
                background-color: darken($gray-2, 10%);
                display: block;
              }
            }
          }

          .action-box {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: flex-end;
            width: auto;
            height: auto;

            &__item {
              display: flex;
              align-items: center;

              b {
                color: $main-dark;
                font-size: 14px;
                margin-right: 10px;
                font-weight: 400;
              }

              & + .action-box__item {
                margin-left: 15px;

                &::before {
                  content: "";
                  display: block;
                  width: 1px;
                  height: 20px;
                  background-color: rgba($main-dark, 0.2);
                  margin-right: 15px;
                }
              }
            }
          }
        }

        .playlist-header {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;

          .tag-box {
            flex: 0 0 auto;

            & + .header {
              margin-left: 10px;
            }
          }

          .action-box {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: flex-end;
            width: auto;
            height: auto;

            &__item {
              display: flex;
              align-items: center;

              b {
                color: $main-dark;
                font-size: 14px;
                margin-right: 10px;
                font-weight: 400;
              }

              & + .action-box__item {
                margin-left: 15px;

                &::before {
                  content: "";
                  display: block;
                  width: 1px;
                  height: 20px;
                  background-color: rgba($main-dark, 0.2);
                  margin-right: 15px;
                }
              }
            }
          }

          .header {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 0;

            b {
              display: inline-block;
              color: $main-dark;
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }

      &__content {
        width: 100%;
        height: auto;
      }

      &.has-tab {
        .white-holder {
          display: none;

          &:first-child {
            border-radius: 0 6px 6px 6px;
          }

          &__header {
            border-radius: 0;
            border-bottom: 1px solid rgba($gray-2, 0.3);
          }

          &__content {
            padding-top: 20px;
          }

          &.is-active {
            display: block;
          }

          & + .white-holder {
            margin-top: 0;
          }
        }
      }

      &.has-scroll {
        display: flex;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
      }

      &.has-max-width {
        .max-width {
          flex: 1;
          width: 100%;
          max-width: 1400px;
          margin: 0 auto;
          padding: 0 20px;

          @media #{$screen-800} {
            padding: 0;
          }
        }
      }

      &.has-footer-menu {
        padding-bottom: 80px;
      }

      &.has-top-menu {
        padding-top: 110px;
      }
    }
  }

  &.has-filter {
    .fr-frame {
      padding-right: 350px;

      .fr-right {
        right: 0;
      }
    }
  }

  &.has-full-menu {
    padding-left: 255px;

    .fr-left {
      width: 255px;
    }
  }

  .mobile {
    @media #{$screen-800} {
      display: flex;
    }

    @media #{$screen-min-800} {
      display: none;
    }
  }
}
