body {
  .DayPicker {
    .DayPicker-wrapper {
      .DayPicker-Month {
        .DayPicker-Weekdays {
          display: flex;
          width: 100%;
          margin-top: 0;

          .DayPicker-WeekdaysRow {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .DayPicker-Weekday {
              display: flex;
              align-items: center;
              justify-content: center;
              width: calc(100% / 7 - 10px);
              color: rgba($n-200, 0.7);
              font-size: 12px;
            }
          }
        }

        .DayPicker-Body {
          display: flex;
          flex-direction: column;
          width: 100%;

          .DayPicker-Week {
            display: flex;
            justify-content: space-between;
            width: 100%;

            & + .DayPicker-Week {
              margin-top: 10px;
            }

            .DayPicker-Day {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: calc(100% / 7 - 10px);
              height: 32px;
              padding: 4px;
              border-radius: 4px !important;
              color: $n-200;
              background-color: $n-10;
              font-size: 14px;
              cursor: pointer;

              @include transition-general;

              &:hover {
                background-color: rgba($b-200, 0.15);
              }

              &:focus {
                outline: none;
              }

              .indicator {
                position: absolute;
                top: 3px;
                left: 3px;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: $b-200;
              }

              &--today {
                background-color: rgba($b-200, 0.2);
              }

              &--selected {
                background: $b-200;
                color: $white;
                box-shadow: 0 2px 10px 0 rgba(darken($b-200, 70%), 0.2);

                .indicator {
                  background-color: $white;
                }
              }

              &--start {
                background: darken($b-200, 10%) !important;
                color: $white;
              }

              &--end {
                background: darken($b-200, 10%) !important;
                color: $white;
              }

              &--outside {
                background-color: transparent !important;
                box-shadow: none;
                cursor: inherit;

                &:hover {
                  background-color: transparent;
                }
              }

              &--disabled {
                opacity: 0.25;

                color: #1f1f1e;
                background-color: #f5f5f5;
              }
            }
          }
        }
      }
    }
  }
}

.date-picker-component {
  .calendar-dropdown {
    left: 0;
    width: 320px;
  }

  .date-dropdown.selected {
    border: 1px solid $lightish-blue;
  }
}
