.fly-creative {
  width: 100%;
  height: auto;
  @include transition-general;

  .image {
    width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 3px 14px rgba(25, 27, 30, 0.15);
    overflow: hidden;
    @include transition-general;

    img {
      display: block;
      width: 100%;
      height: auto;
      min-height: 70px;
      background-color: grey;
    }
  }

  .select-box {
    display: none;
  }

  &.has-select {
    position: relative;
    cursor: pointer;

    .select-box {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      border: 3px solid $pumpkin;
      border-radius: 4px;
      z-index: 10;
      opacity: 0;
      @include transition-general;

      &:before {
        content: "";
        position: absolute;
        top: -13px;
        right: -3px;
        display: block;
        border-left: 20px solid $pumpkin;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        transform: rotate(-45deg);
      }

      i.icon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        color: $white;
      }
    }

    &:hover {
      .image {
        box-shadow: 0 7px 20px rgba(25, 27, 30, 0.3);
      }
    }

    &.is-selected {
      .select-box {
        opacity: 1;
      }
    }
  }
}
