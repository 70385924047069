.fly-status-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  &__base {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: auto;
    height: 30px;
    padding: 3px;
    border-radius: 15px;
    background-color: $gray-4;
    box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);

    b {
      position: relative;
      display: block;
      width: auto;
      height: 100%;
      border-radius: 13px;
      z-index: 10;
      font-size: 14px;
      font-weight: 500;
      padding: 0 10px;
      color: $dark-ocean;
      line-height: 24px;
      @include transition-general;
    }
  }

  &.draft-active {
    .fly-status-switch__base {
      b.draft {
        background-color: $dark-sky;
        color: $white;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.queue-active {
    .fly-status-switch__base {
      b.queue {
        background-color: $bright-green;
        color: $white;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.online-active {
    .fly-status-switch__base {
      b.online {
        background-color: $bright-red;
        color: $white;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
