.fly-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  & + .fly-section {
    margin-top: 30px;
  }

  &--no-padding {
    .fly-section {
      &__header {
        padding: 0;
      }

      &__content {
        padding: 0;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    padding: 0 20px;
    margin-bottom: 10px;

    b {
      display: block;
      flex: 1 1 auto;
      color: $n-200;
      font-size: 18px;
    }

    &__right {
      display: flex;
      width: auto;
      height: auto;
      flex: 0 0 auto;

      p {
        color: $n-200;
        font-size: 14px;
        margin-right: 5px;
        margin-bottom: 0;
        font-weight: 400;
      }
    }
  }

  &__content {
    width: 100%;
    height: auto;
    padding: 0 20px;
  }
}
