.h-100 {
  display: block;
  font-size: 11px;
  font-weight: 500;
  color: rgba($n-200, 0.6);
  line-height: 16px;
}

.h-200 {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: rgba($n-200, 0.6);
  line-height: 16px;

  b {
    font-weight: 900;
  }

  &.margin-bottom {
    margin-bottom: 5px;
  }

  &.planner-summary {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    color: #2e2e2e;
    b {
      font-weight: 900;
    }
  }
}

.h-300 {
  display: block;
  font-size: 11px;
  font-weight: 600;
  color: rgba($n-200, 0.6);
  line-height: 16px;
  text-transform: uppercase;
}

.h-400 {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: $n-200;
  line-height: 18px;
}

.h-500 {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: $n-200;
  line-height: 20px;
}

.h-600 {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: $n-200;
  line-height: 24px;
}

.h-700 {
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: $n-200;
  line-height: 28px;
}

.h-800 {
  display: block;
  font-size: 29px;
  font-weight: 600;
  color: $n-200;
  line-height: 32px;
}

.h-900 {
  display: block;
  font-size: 35px;
  font-weight: 600;
  color: $n-200;
  line-height: 40px;
}

.h-1000 {
  display: block;
  font-size: 34px;
  font-weight: 600;
  color: $n-200;
  line-height: 50px;
}

.paragraph {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: $n-200;
  line-height: 18px;

  .bold {
    font-weight: 500;
  }

  &.title-bold {
    font-weight: 500;
  }
}

.small {
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: rgba($n-200, 0.6);
  line-height: 14px;

  .bold {
    font-weight: 500;
  }

  b {
    font-weight: 900;
  }
}

[class^="h-"],
[class^="paragraph"] {
  &.blue {
    color: $b-200;
  }

  &.pink {
    color: $p-200;
  }

  &.orange {
    color: #dca521;
  }

  &.green {
    color: $g-200;
  }

  &.teal {
    color: $t-200;
  }
}
