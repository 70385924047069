.geofence-container {
  .file-drop {
    .title {
      font-size: 14px !important;
      font-weight: 500 !important;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.35px;
      color: #2e2e2e;
    }
    .desc {
      font-size: 12px !important;
      font-weight: normal !important;
      font-style: italic;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #4f4f4f;
    }
  }

  .mapfences {
    padding: 4px 8px;
    display: block;

    &.no-fences {
      padding: 8px 8px 0 8px;

      p {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        color: $black;
        padding-left: 6px;
        margin-top: 8px;
        margin-bottom: 6px;

        &.small {
          font-size: 12px;
          font-weight: 200;
          margin-top: 0;
          margin-bottom: 12px;
        }
      }

      .tool-info {
        display: flex;
        flex-direction: column;
        margin-left: 6px;

        .drawing-text-container {
          display: flex;
        }

        p {
          padding-left: 12px;
          padding-top: 12px;
        }
      }

      .geofence-button {
        width: 180px;
        height: 32px;
        border-radius: 6px;
        background-color: $lightish-blue;
        margin: 12px 0;

        b {
          width: 38px;
          height: 14px;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.34px;
          text-align: center;
          color: #ffffff;
        }

        & .disabled {
          opacity: 0.8;
        }
      }
    }

    .drawing-button-container {
      display: flex;
      justify-content: flex-end;
      margin-right: 5px;

      .fly-button.primary {
        width: 149px;
        border-radius: 6px;
        color: white;

        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        text-align: center;
      }
    }

    .shape__item {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;

      svg {
        path,
        g {
          fill: #3675ff;
        }
      }

      .shape__item__info {
        width: 100%;
        display: block;

        p {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.35px;
          color: $black;
          padding-left: 8px;
          margin-bottom: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.small {
            font-size: 10px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

      .item-favorite-button {
        margin-right: 5px;
      }

      .item-trash-button,
      .item-favorite-button {
        cursor: pointer;
      }
    }

    .actions {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }

  .exists {
    path {
      fill: orange !important;
    }
  }
}

.geofence-type-contextmenu {
  position: absolute;
  width: 180px;
  border-radius: 6px;
  box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
  background-color: $white;
  z-index: 1;
  display: block;

  .menu-item {
    display: flex;
    cursor: pointer;
    margin: 12px 0;
    background-color: #edf2ff;
    position: relative;

    p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.35px;
      color: $lightish-blue;
      padding-left: 12px;
      margin-top: 6px;
      margin-bottom: 2px;
    }

    .item-trash-button {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 3px;

      .trash-icon {
        cursor: pointer;
      }
    }
  }
}

.geofence-ptc-miles {
  position: absolute;
  width: 340px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
  background-color: $white;
  z-index: 1;
  display: block;

  .mile__item {
    width: 90px;
    height: 36px;
    border-radius: 6px;
    background-color: #f5f5f5;
    margin: 6px;
    text-align: center;
    cursor: pointer;

    &.double {
      width: 192px;
    }

    &:hover {
      background-color: $lightish-blue;
      p {
        background-color: $lightish-blue;
        color: $white;
        opacity: 1;
      }
    }

    &.opened {
      background-color: $lightish-blue;
      p {
        background-color: $lightish-blue;
        color: $white;
        opacity: 1;
      }
    }

    p {
      opacity: 0.7;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  .geofence-ptc-miles-group {
    display: flex;

    .delete__item {
      display: flex;
      cursor: pointer;
      align-items: center;

      .item-trash-button {
        .trash-icon {
          cursor: pointer;
        }
      }
    }
  }

  .input__item {
    display: none;

    &.opened {
      display: block;
    }

    input {
      display: inline-block;
      margin: 6px;
      width: 64%;
      height: 36px;
      border-radius: 6px;
      border: solid 1px $very-light-pink;
      text-align: right;
      padding: 6px;
      font-size: 14px;
    }

    .btn-apply {
      display: inline-block;
    }
  }
}

.geofence-type-select {
  position: absolute;
  width: 170px;
  height: 200px;
  border-radius: 6px;
  box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
  background-color: $white;
  display: block;

  .geofence-type-select__item {
    display: flex;
    width: 100%;
    height: 50px;
    cursor: pointer;

    &:hover {
      background-color: #edf2ff;
      p {
        color: $lightish-blue;
      }
    }

    p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.35px;
      color: $black;
      margin-top: 16px;
    }

    .icon {
      margin: 4px 12px;
    }

    .item-open-button {
      position: absolute;
      right: 12px;
      margin-top: 18px;
      transform: rotate(180deg);

      .arrow-down {
        cursor: pointer;
      }
    }
  }
}
