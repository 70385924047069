.fr-main {
  .main-wrapper {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
  }

  .main-wrapper-flex {
    width: 100%;
    height: 100%;
    display: flex;

    &.campaign-list {
      flex-direction: column;
    }
  }

  .draw-tool-box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: calc(50% - 222px);
    bottom: 80px;
    height: 48px;
    padding-left: 14px;
    padding-right: 7px;
    background-color: $cloud;
    box-shadow: 0 5px 18px -2px rgba(#1d2631, 0.23);
    border-radius: 24px;

    .tool-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: darken($semi-dark-cloud, 3%);
      margin-left: 3px;
      margin-right: 3px;
      padding-top: 1px;
      border-radius: 6px;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: darken($semi-dark-cloud, 9%);
        .tooltip {
          display: block;
        }
      }

      svg {
        g,
        path,
        rect,
        circle {
          fill: #2e2e2e !important;
        }
      }

      i.icon {
        font-size: 34px;
        line-height: 1;
        color: rgba($dark-ocean, 0.8);
      }
    }

    .fly-button {
      margin-left: 10px;
    }

    .close-draw-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 30px;
      background: linear-gradient(to right, $b-100 0%, $b-100 100%);
      margin-left: 3px;
      margin-right: 3px;
      border-radius: 3px;

      &:hover {
        background: linear-gradient(
          to right,
          darken($b-100, 3%) 0%,
          darken($b-100, 3%) 100%
        );
      }

      p {
        font-size: 14px;
        color: $white;
      }
    }
  }

  .draw-tool-box-planner {
    display: flex;
    align-items: center;
    left: calc(50% - 222px);
    top: 56px;
    height: 56px;
    background-color: transparent;
    flex-direction: column;
    margin-top: 24px;

    .draw-tool-helper {
      pointer-events: initial;
      margin-top: 24px;
      border-radius: 6px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.11);
      background-color: #3d3d3d;
      color: #ffffff;
      padding: 16px;
      z-index: 99999;
      width: 487px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .draw-tool-helper-icon {
        height: 20px;
        width: 20px;
        margin-bottom: auto;
      }

      .draw-tool-helper-content {
        margin: 0px 12px;
        flex-grow: 1;
        line-height: 1.29;
        letter-spacing: 0.35px;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;

        ul {
          margin-top: 12px;
        }

        li {
          list-style-type: disc;
          list-style-position: inside;
          line-height: 24px;
        }
      }
    }

    .draw-tool-box-planner-buttons {
      display: flex;
      flex-direction: row;
      .tool-btn {
        pointer-events: initial;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        background-color: $white;
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 6px;
        box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);

        &.selected {
          border: solid 1px $lightish-blue;
          background-color: #edf2ff;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: darken($semi-dark-cloud, 9%);
        }

        i.icon {
          font-size: 34px;
          line-height: 1;
          color: rgba($dark-ocean, 0.8);
        }

        svg {
          g,
          path,
          rect,
          circle {
            fill: #2e2e2e !important;
          }
        }
      }

      .fly-button {
        margin-left: 10px;
      }

      .close-draw-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 30px;
        background: linear-gradient(to right, $b-100 0%, $b-100 100%);
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 3px;

        &:hover {
          background: linear-gradient(
            to right,
            darken($b-100, 3%) 0%,
            darken($b-100, 3%) 100%
          );
        }

        p {
          font-size: 14px;
          color: $white;
        }
      }
    }
  }

  .map-box {
    height: calc(100% + 20px);

    .map-info {
      position: absolute;
      left: calc(50% - 680px);
      bottom: 20px;
      display: flex;
      align-items: center;
      background-color: $n-0;
      border-radius: 6px;
      padding: 10px;

      @include e400;

      @media #{$screen-1440} {
        left: 40px;
      }

      @media #{$screen-800} {
        left: 20px;
      }

      &__item {
        width: auto;
        height: auto;

        & + .map-info__item {
          margin-left: 20px;
        }

        &__statistic {
          display: block;
          width: auto;
          height: auto;
          font-size: 24px;
          color: $b-200;
          font-weight: 500;
        }

        &__label {
          display: block;
          width: auto;
          height: auto;
          font-size: 12px;
          font-weight: 500;
          color: rgba($n-200, 0.8);
        }
      }
    }
  }

  .statistic-card {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 350px;
    height: auto;
    padding: 15px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 5px 20px -5px rgba(#21242c, 0.32);
    z-index: 100;

    .title {
      width: 100%;
      color: $dark-ocean;
      margin-bottom: 10px;

      b {
        display: block;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 3px;
      }

      p {
        font-size: 12px;
        color: rgba($dark-ocean, 0.7);
      }
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .statistic-item {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        text-align: center;

        b {
          display: block;
          font-size: 12px;
          margin-bottom: 3px;
          color: $dark-ocean;
        }

        .statistic {
          font-size: 22px;
          letter-spacing: 1px;
          font-weight: bold;
          margin-bottom: 0;

          &.online {
            color: $sea-green;
          }

          &.offline {
            color: $crimsion-pinkish;
          }

          &.small {
            font-size: 16px;
          }
        }
      }
    }
  }

  .tooltip-container {
    .tooltip {
      display: none;
      position: absolute;
      top: calc(100% + 5px);
      width: auto;
      height: auto;
      max-width: 400px;
      padding: 6px 10px;
      background-color: $carbon;
      color: $white;
      box-shadow: 0 5px 8px 0 rgba(#1d2631, 0.4);
      border-radius: 4px;
      font-size: 11px;
      z-index: 20;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: -4px;
        left: calc(50% - 6px);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $carbon;
        transform: rotate(180deg);
      }
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}
