b.fly-label,
p.fly-label {
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  color: rgba($n-200, 0.8);

  &.vertical {
    margin-bottom: 0;
    margin-right: 10px;
  }

  &.small {
    font-size: 10px;
  }
}
