.fly-error-page {
  width: 100%;
  height: 100%;
  position: relative;

  .error-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $white;
    padding: 48px 80px;

    .logo {
      width: 138px;
      height: auto;
    }

    @media #{$screen-750} {
      padding: 48px 10px;
    }
  }

  .error-left {
    position: absolute;
    top: 125px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    width: 500px;
    height: auto;
    background-color: $white;
    padding: 80px;

    .status {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.43px;
      color: #4f4f4f;
    }

    .oops {
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.03;
      letter-spacing: 0.9px;
      color: #2e2e2e;
      margin: 8px 0;
    }

    .info {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: 0.56px;
      color: #4f4f4f;
      margin: 12px 0;
    }

    .home-button {
      width: 175px;
      height: 40px;
      border-radius: 6px;
      background-color: $lightish-blue;
      margin: 12px 0;

      b {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: #ffffff;
      }
    }

    @media #{$screen-750} {
      width: 100%;
      padding: 10px;
    }
  }

  .error-right {
    position: absolute;
    top: 200px;
    right: 0;
    width: calc(100% - 600px);
    height: calc(100% - 125px);
    background-color: $white;

    &.access {
      top: 125px;
    }

    img {
      height: calc(100% - 75px);
    }

    @media #{$screen-750} {
      top: 625px;
      left: 0;
      width: 100%;

      &.access {
        top: 625px;
      }

      img {
        height: auto;
        width: 100%;
      }
    }
  }
}
