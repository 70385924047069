.fly-status-wrapper {
  margin-right: 15px;

  @media #{$screen-750} {
    position: absolute;
    top: 7px;
    right: 0;
    display: inline-block;
    margin-right: 0;

    .fly-status {
      &.online,
      &.offline,
      &.upcoming {
        background: transparent;
        border: none;
        box-shadow: nones;
      }
    }
  }
}

.fly-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 24px;
  padding: 0 10px;
  border-radius: 15px;
  border: 2px solid transparent;
  background-color: $n-0;

  @media #{$screen-750} {
    height: auto;
    padding: 0;
    border: none;
  }

  span.dot {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
    margin-left: -3px;

    @media #{$screen-750} {
      width: 4px;
      height: 4px;
    }

    &__live {
      animation: online-dot 1s steps(5, start) infinite;
    }
  }

  b {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    @include noselect;

    @media #{$screen-750} {
      font-size: 10px;
    }
  }

  &.online {
    border-color: $g-100;
    box-shadow: 0 3px 8px 0 rgba($g-100, 0.35);

    span.dot {
      background-color: $g-100;
    }

    b {
      color: $g-100;
    }
  }

  &.upcoming {
    border-color: $b-200;
    box-shadow: 0 3px 8px 0 rgba($b-200, 0.35);

    span.dot {
      background-color: $b-200;
    }

    b {
      color: $b-200;
    }
  }

  &.offline {
    border-color: rgba($n-100, 0.6);
    box-shadow: 0 3px 8px 0 rgba($n-100, 0.25);

    span.dot {
      background-color: $n-100;
    }

    b {
      color: rgba($n-100, 0.6);
    }
  }
}

@keyframes online-dot {
  to {
    visibility: hidden;
  }
}
