.authlayout-wrapper {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../image/login-image.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;

  .main-brick {
    display: flex;
    flex: 4;
    align-items: center;
    overflow-y: auto;

    @media #{$screen-height-500} {
      align-items: flex-start;
    }
  }
}

.authbox {
  display: flex;
  flex-direction: column;
  z-index: 2;
  border-radius: 12px;
  margin-left: 200px;
  width: 380px;
  height: 100%;
  min-height: 480px;
  max-height: 480px;
  background-color: rgba($n-0, 0.96);
  box-shadow: 0 10px 40px -10px rgba($n-900, 1);

  @media #{$screen-600} {
    margin: auto;
  }

  @media #{$screen-400} {
    width: 90%;
  }

  &__reset-success-wrapper {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__success-text {
      text-align: center;
      width: 50%;
    }
    .reset-success {
      width: 378px;
      height: 170px;
    }
  }
  &__logo {
    flex: 2;
    padding-top: 30px;
    background-image: url(../../image/firefly-logo.svg);
    background-size: 150px auto;
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  &__content {
    flex: 6;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;

    .forget-password-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 35px;
      margin-bottom: 15px;

      .forget-password-text {
        flex: 1;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        width: 220px;
        margin-bottom: 10px;
      }

      .forget-password-description {
        flex: 1;
        font-weight: 300;
        font-size: 11px;
        text-align: center;
        width: 220px;
        color: $n-100;
      }
    }

    &__input-wrapper {
      flex: 2;
      display: flex;
      align-items: baseline;

      &__login-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .submit {
          flex: 3;
          padding-right: 8px;

          .fly-button {
            width: 100%;
            height: 38px;
            background-color: rgba(54, 117, 255, 0.95);
            border-radius: 6px;
            border: 0;
            color: $white;
            box-shadow: 0 3px 14px rgba(#191b1e, 0.15);

            b {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .fly-button:disabled {
            opacity: 0.5;
          }
        }

        .g-border {
          height: 30px;
          border-left: 1px solid rgba($n-200, 0.25);
        }

        .g-logo {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 auto;
          width: 38px;
          height: 38px;
          border-radius: 6px;
          margin-left: 8px;
          cursor: pointer;

          @include transition-general;

          &:hover {
            background-color: rgba($n-30, 0.6);
          }

          img {
            width: 23;
            height: 23px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &:nth-last-child(3) {
        padding-bottom: 5px;
      }
      .label-input-wrapper {
        width: 100%;

        input {
          &:focus {
            background-color: $white;
          }
        }

        input.login.error {
          background-color: $white;
        }

        span {
          height: 16px;
          width: 159px;
          &:not(.error) {
            :not(.success) {
              display: none;
            }
          }

          &.error {
            color: #d0021b;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0.24px;
            line-height: 12px;
          }

          &.success {
            color: $b-300;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0.24px;
            line-height: 12px;
          }
        }
      }
    }

    &__forget-pass-wrapper {
      flex: 3;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-size: 11px;
      color: rgb(108, 108, 108);

      .fly-button__campaigns {
        .text-link {
          color: $n-100;
          text-decoration: underline;
          letter-spacing: 0.1px;
        }
      }
    }

    &__get-started-wrapper {
      flex: 8;
      display: flex;
      align-items: center;
      padding-bottom: 40px;
      justify-content: space-around;
      border-top: 1px solid rgb(150, 164, 186);

      .text-wrapper {
        flex: 1 0 auto;
        display: contents;
        font-size: 14px;
        color: $n-100;
      }

      button {
        flex: 1 1 auto;
        font-size: 14px;
        border-radius: 6px;
        border: 1px solid black;
        max-width: 94px;
        height: 30px;
        @media #{$screen-768} {
          height: auto;
        }
      }

      &__get-started {
        color: $black;
        text-align: center !important;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
