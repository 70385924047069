.flex-col {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  &.margin-top-20 {
    margin-top: 20px;
  }

  &.margin-top-50 {
    margin-top: 50px;
  }

  &.margin-left-10 {
    margin-left: 10px;
  }

  .f-col-1 {
    width: calc(100% / 12 * 1 - 15px);
  }

  .f-col-2 {
    width: calc(100% / 12 * 2 - 15px);
  }

  .f-col-3 {
    width: calc(100% / 12 * 3 - 15px);
  }

  .f-col-4 {
    width: calc(100% / 12 * 4 - 15px);
  }

  .f-col-5 {
    width: calc(100% / 12 * 5 - 15px);
  }

  .f-col-6 {
    width: calc(100% / 12 * 6 - 15px);

    &.margin-top-20 {
      margin-top: 20px;
    }

    & + .f-col-12 {
      margin-top: 20px;
    }
  }

  .f-col-7 {
    width: calc(100% / 12 * 7 - 15px);
  }

  .f-col-8 {
    width: calc(100% / 12 * 8 - 15px);
  }

  .f-col-9 {
    width: calc(100% / 12 * 9 - 15px);
  }

  .f-col-10 {
    width: calc(100% / 12 * 10 - 15px);
  }

  .f-col-11 {
    width: calc(100% / 12 * 11 - 15px);
  }

  .f-col-12 {
    width: calc(100% / 12 * 12);

    & + .f-col-12 {
      margin-top: 20px;
    }
  }

  &.one-col {
    &--1300 {
      @media #{$screen-1300} {
        flex-wrap: wrap;

        [class*="f-col"] + [class*="f-col"] {
          margin-top: 20px;
        }

        .f-col-1,
        .f-col-2,
        .f-col-3,
        .f-col-4,
        .f-col-5,
        .f-col-6,
        .f-col-7,
        .f-col-8,
        .f-col-9,
        .f-col-10,
        .f-col-11,
        .f-col-12 {
          width: 100%;
        }
      }
    }
  }

  .f-row {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;

    & + .f-row {
      margin-top: 5px;
    }

    &.wrap {
      flex-wrap: wrap;
    }

    &.planner-summary {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.35px;
      color: #2e2e2e;
    }
  }
}

.flex-col-playlist {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;

  .f-col-1 {
    width: calc(100% / 12 * 1 - 15px);
  }

  .f-col-2 {
    width: calc(100% / 12 * 2 - 15px);
  }

  .f-col-3 {
    width: calc(100% / 12 * 3 - 15px);
  }

  .f-col-4 {
    width: calc(100% / 12 * 4 - 15px);
  }

  .f-col-5 {
    width: calc(100% / 12 * 5 - 15px);
  }

  .f-col-6 {
    width: calc(100% / 12 * 6 - 15px);

    & + .f-col-12 {
      margin-top: 20px;
    }
  }

  .f-col-7 {
    width: calc(100% / 12 * 7 - 15px);
  }

  .f-col-8 {
    width: calc(100% / 12 * 8 - 15px);
  }

  .f-col-9 {
    width: calc(100% / 12 * 9 - 15px);
  }

  .f-col-10 {
    width: calc(100% / 12 * 10 - 15px);
  }

  .f-col-11 {
    width: calc(100% / 12 * 11 - 15px);
  }

  .f-col-12 {
    width: calc(100% / 12 * 12);

    & + .f-col-12 {
      margin-top: 20px;
    }
  }

  &.one-col {
    &--1300 {
      @media #{$screen-1300} {
        flex-wrap: wrap;

        [class*="f-col"] + [class*="f-col"] {
          margin-top: 20px;
        }

        .f-col-1,
        .f-col-2,
        .f-col-3,
        .f-col-4,
        .f-col-5,
        .f-col-6,
        .f-col-7,
        .f-col-8,
        .f-col-9,
        .f-col-10,
        .f-col-11,
        .f-col-12 {
          width: 100%;
        }
      }
    }
  }
}
