.fly-map-city-select {
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  max-height: calc(100% - 45px);
  padding-bottom: 15px;
  overflow-y: auto;
  z-index: 200;

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 auto;
    width: 100%;
    height: 26px;
    padding: 0 6px;
    border-radius: 4px;
    background-color: $n-0;
    cursor: pointer;

    @include transition-general;
    @include e100;

    & + .fly-map-city-select__item {
      margin-top: 6px;
    }

    &:hover {
      @include e200;

      b {
        color: $n-200;
      }
    }

    b {
      font-size: 12px;
      font-weight: 500;
      color: rgba($n-200, 0.8);
    }
  }
}
