.fly-inventory-card-wrapper {
  display: flex;
  width: 300px;
  @media #{$screen-800} {
    width: 300px;
  }
}
.fly-inventory-card {
  flex: 1 1 auto;
  height: auto;
  border-radius: 6px;
  background-color: $gray-1;
  box-shadow: 0 6px 20px 0 rgba($shadow-color, 0.06);

  &__header {
    position: relative;
    width: 100%;
    height: auto;
    padding: 10px 15px;

    b {
      display: block;
      width: 100%;
      height: auto;
      color: $main-dark;
      font-size: 14px;
      font-weight: 600;
    }

    .date {
      display: block;
      width: 100%;
      height: auto;

      p {
        display: inline-block;
        margin-right: 3px;
        color: rgba($main-dark, 0.8);
        font-size: 12px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, $gray-4 0%, rgba($white, 0) 90%);
    }
  }

  &__content {
    width: 100%;
    height: auto;
    padding: 10px 15px;

    .primary-data {
      width: 100%;
      height: auto;
      padding-bottom: 5px;

      b {
        display: inline-block;
        margin-right: 5px;
        color: $pumpkin;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .secondary-data {
      width: 100%;
      height: auto;

      p {
        display: inline-block;
        margin-right: 5px;
        color: $main-dark;
        font-size: 14px;
      }
    }
  }
}

.chart_container {
  width: 100%;
  display: flex;
  margin: 2rem 0;
  flex-wrap: wrap;

  .chart-item {
    width: 33.3%;
  }

  &-column {
    width: 100%;
    margin: 0 1rem 0 0;
  }
  .white-holder {
    word-break: break-all;
  }

  @media (min-width: 768px) and (max-width: 1600px) {
    .white-holder {
      word-break: break-all;
      .h-1000 {
        font-size: 26px;
      }
    }
  }
}

.title-m {
  margin: 0.5rem 0;
}

.chartperiod_title {
  text-align: right;
  padding-right: 2rem;
}

.ch-wrapper {
  padding: 0.5rem;
}
