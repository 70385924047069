.fly-info {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 20px 10px 5px;
  border-radius: 4px;
  background-color: rgba($dark-sky, 0.2);
  color: $midnight;

  i.icon {
    margin-top: 5px;
  }

  b {
    font-size: 14px;
  }
}
