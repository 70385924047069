.fly-calendar {
  position: relative;
  max-width: 220px;

  .calendar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    padding-right: 10px;
    border: 1px solid transparent;
    border-radius: 6px;
    @include transition-general;

    &:hover {
      background-color: $n-10;
      //border: 1px solid $b-100;
      //box-shadow: 0 2px 6px 0 rgba($shadow-color, 0.2);
    }

    b {
      color: $n-200;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 15px;
    }

    i.icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: 5px;
      margin-top: 0;
      color: rgba($n-200, 0.8);
      font-size: 22px;
    }
  }

  .calendar-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-radius: 6px;
    border: 1px solid $b-100;
    z-index: 100;
    opacity: 0;
    display: none;
    background-color: $white;
    box-shadow: 0 3px 15px 0 rgba($shadow-color, 0.2);

    &__header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 35px;
      padding-left: 10px;
      border-bottom: 1px solid rgba($n-200, 0.1);

      b {
        font-size: 16px;
        font-weight: 500;
        color: $n-200;
      }

      .close-btn {
        position: absolute;
        top: 4px;
        right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;
        border-radius: 3px;
        z-index: 10;

        i.icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-size: 22px;
          color: $n-200;
          margin-top: 3px;
          margin-left: 0;

          &:before {
            content: "\e022";
          }
        }

        &:hover {
          background-color: rgba($main-dark, 0.1);

          i.icon {
            color: $main-dark;

            &:before {
              content: "\e022";
            }
          }
        }
      }
    }

    &__allday-swtich {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      border-bottom: 1px solid rgba($n-200, 0.1);

      .label {
        font-size: 13px;
        color: $n-100;
      }
    }

    &__date-picker {
      width: 100%;
      height: auto;
      padding: 10px;
      border-bottom: 1px solid rgba($main-dark, 0.1);

      .DayPicker {
        width: 100%;

        &:focus {
          outline: none;
        }

        .DayPicker-wrapper {
          padding-bottom: 0;

          &:focus {
            outline: none;
          }
        }

        .DayPicker-NavBar {
          .DayPicker-NavButton {
            top: -3px;
            background-size: 45%;

            &:focus {
              outline: none;
            }

            &--prev {
              left: 70px;
            }

            &--next {
              right: 70px;
            }
          }
        }

        .DayPicker-Month {
          width: 100%;
          margin: 0;

          .DayPicker-Caption {
            text-align: center;
            width: 200px;
            margin: 0 auto 20px auto;
            font-weight: normal;
            font-size: 15px;

            div {
              display: inline-block;
              color: $n-200;
              font-size: 15px;
              font-weight: normal;
            }
          }
        }
      }

      .month-selector {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        color: $main-dark;
        text-align: center;

        i.icon {
          height: 26px;
          width: 26px;
          font-size: 25px;
          margin-left: 20px;
          margin-right: 20px;
        }

        b {
          height: auto;
          margin-top: -5px;
          font-size: 15px;
        }
      }

      .input-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        & > div {
          width: calc(50% - 15px);
          font-size: 14px;
          height: auto;
        }

        .divider {
          width: 10px;
          height: 2px;
          border-radius: 1px;
          background-color: $n-40;
        }
      }

      &.is-close {
        display: none;
      }
    }

    &__action-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: auto;
      padding: 10px;

      .fly-button {
        & + .fly-button {
          margin-left: 10px;
        }
      }
    }
  }

  &.is-open {
    max-width: 320px;

    button {
      animation: calendar-open_button 0.3s ease-in-out forwards;
    }

    .calendar-dropdown {
      animation: calendar-open_dropdown 0.3s ease-in-out forwards;
      display: inline-block;

      &__header,
      &__allday-swtich,
      &__date-picker,
      &__action-box {
        animation: calendar-open-inner 0.3s ease-in-out forwards;
      }
    }
  }

  &.is-close {
    button {
      animation: calendar-xlose_button 0.3s ease-in-out forwards;
    }

    .calendar-dropdown {
      animation: calendar-close_dropdown 0.3s ease-in-out forwards;
      display: none;

      &__header,
      &__allday-swtich,
      &__date-picker,
      &__action-box {
        animation: calendar-close-inner 0.3s ease-in-out forwards;
      }
    }
  }

  &--planner {
    max-width: 100%;
    height: auto;

    & + .fly-calendar--planner {
      margin-top: 10px;
    }

    &.is-open {
      max-width: 100%;

      button {
        animation: none;
      }

      .calendar-dropdown {
        display: inline-block;
      }
    }

    .calendar-btn {
      height: 48px;
      border-radius: 6px;
      background-color: $n-0;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      overflow: hidden;
      border: none;
      @include transition-general;

      b {
        display: inline-block;
        flex: 1 1 auto;
        font-size: 14px;
      }

      &:hover {
        background-color: $n-0;
        box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
        border: none;

        .calendar-btn__change {
          display: flex;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin-left: 10px;
        background-color: $n-0;

        &:hover {
          background-color: $n-20;
        }

        i.icon {
          margin-left: -2px;
          margin-top: 2px;
        }
      }

      &__change {
        display: none;
      }
    }

    & > .fly-button {
      margin-top: 10px;
    }

    .calendar-dropdown {
      position: initial;
      min-width: 100%;
      height: auto;
      margin-top: 10px;
      background-color: $n-0;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      border: none;

      &__date-picker {
        .input-box {
          input {
            text-align: center;
          }
        }

        .DayPicker {
          .DayPicker-wrapper {
            .DayPicker-NavBar {
              .DayPicker-NavButton--prev {
                left: 0;
              }

              .DayPicker-NavButton--next {
                right: 0;
              }
            }

            .DayPicker-Months {
              flex-wrap: nowrap;

              .DayPicker-Month + .DayPicker-Month {
                margin-left: 25px;
              }
            }
          }
        }
      }

      &__action-box {
        button {
          margin-top: 0;
        }
      }
    }
  }

  &--heat-map {
    max-width: 100%;
    height: auto;

    &.is-open {
      max-width: 100%;

      button {
        animation: none;
      }

      .calendar-dropdown {
        display: inline-block;
      }
    }

    .calendar-btn {
      height: 48px;
      border-radius: 6px;
      background-color: $n-0;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      overflow: hidden;
      border: none;
      @include transition-general;

      b {
        display: inline-block;
        flex: 1 1 auto;
        font-size: 14px;
      }

      &:hover {
        background-color: $n-0;
        box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
        border: none;

        .calendar-btn__change {
          display: flex;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin-left: 10px;
        background-color: $n-0;

        &:hover {
          background-color: $n-20;
        }

        i.icon {
          margin-left: -2px;
          margin-top: 2px;
        }
      }

      &__change {
        display: none;
      }
    }

    & > .fly-button {
      margin-top: 10px;
    }

    .calendar-dropdown {
      position: initial;
      min-width: 100%;
      height: auto;
      margin-top: 10px;
      background-color: $n-0;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      border: none;

      &__date-picker {
        .input-box {
          input {
            text-align: center;
          }
        }

        .DayPicker {
          .DayPicker-wrapper {
            .DayPicker-NavBar {
              .DayPicker-NavButton--prev {
                left: 0;
              }

              .DayPicker-NavButton--next {
                right: 0;
              }
            }

            .DayPicker-Months {
              flex-wrap: nowrap;

              .DayPicker-Month + .DayPicker-Month {
                margin-left: 25px;
              }
            }
          }
        }
      }

      &__action-box {
        button {
          margin-top: 0;
        }
      }
    }

    .reset-button {
      float: right;
      width: 94px;
      height: 32px;
      border-radius: 6px;
      background-color: transparent;
      b {
        width: 38px;
        height: 14px;
        opacity: 0.5;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.34px;
        color: $n-200;
      }

      &:hover {
        border: solid 1px $very-light-pink;
      }
    }

    .apply-button {
      float: right;
      width: 94px;
      height: 32px;
      border-radius: 6px;
      background-color: $lightish-blue;
      margin-left: 8px;

      b {
        width: 38px;
        height: 14px;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.34px;
        text-align: center;
        color: #ffffff;
      }

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}

@keyframes calendar-open_button {
  0% {
    width: auto;
  }

  100% {
    width: 330px;
  }
}

@keyframes calendar-close_button {
  0% {
    width: 330px;
  }

  100% {
    width: auto;
  }
}

@keyframes calendar-open_dropdown {
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    height: auto;
    opacity: 1;
  }
}

@keyframes calendar-close_dropdown {
  0% {
    height: auto;
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
  }
}

@keyframes calendar-open-inner {
  0% {
    display: none;
    opacity: 0;
  }

  50% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes calendar-close-inner {
  0% {
    display: block;
    opacity: 1;
  }

  50% {
    display: block;
    opacity: 0;
  }

  100% {
    isplay: none;
    opacity: 0;
  }
}
