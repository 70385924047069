.fly__hamburger_menu {
  position: relative;
  display: none;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  width: 45px;
  height: 45px;

  @media #{$screen-750} {
    display: flex;
  }

  &.open-menu {
    .fly__hamburger_menu__curtain {
      display: block;
      animation: fade-in-opacity 0.2s ease-in-out forwards;
    }

    .fly__hamburger_menu__menu {
      left: 0;
      box-shadow: 10px 0 10px 0 rgba($n-200, 0.21);
    }
  }

  &.close-menu {
    .fly__hamburger_menu__curtain {
      animation: fade-out-opacity 0.2s ease-in-out forwards;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: auto;
    cursor: pointer;

    span {
      flex: 0 0 auto;
      display: block;
      width: 100%;
      height: 3px;
      border-radius: 4px;
      background-color: $n-200;

      & + span {
        margin-top: 5px;
      }
    }
  }

  &__curtain {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    bottom: 0;
    background-color: rgba($n-200, 0.5);
    z-index: 10000;
  }

  &__menu {
    position: fixed;
    top: 0;
    left: -100%;
    display: flex;
    flex-direction: column;
    width: calc(100% - 45px);
    bottom: 0;
    background-color: $n-0;
    box-shadow: none;
    z-index: 10001;
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 auto;
      width: 100%;
      height: 48px;
      padding: 0 20px;

      .logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        width: auto;
        height: auto;

        img {
          display: block;
          width: auto;
          height: 16px;
        }
      }

      .close-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 48px;
        cursor: pointer;

        span {
          flex: 0 0 auto;
          display: block;
          width: 100%;
          max-width: 25px;
          height: 3px;
          border-radius: 4px;
          background-color: $n-100;
          margin-top: -3px;

          &:first-child {
            transform: rotate(-45deg);
          }

          &:last-child {
            transform: rotate(45deg);
          }
        }
      }
    }

    &__content {
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      padding: 30px 20px;
      overflow-y: scroll;

      & > b {
        display: block;
        color: $n-200;
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 30px;
      }

      &__item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        padding: 4px 10px;
        background-color: rgba($n-10, 0.6);
        border-radius: 6px;

        & + .fly__hamburger_menu__menu__content__item {
          margin-top: 10px;
        }

        b {
          font-size: 16px;
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      border-top: 1px solid $n-20;

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 20px;

        p {
          font-size: 13px;
          color: rgba($n-100, 0.8);
          font-weight: 600;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 0 20px 30px 20px;

        b {
          font-size: 16px;
          font-weight: 500;
          color: $n-200;
          margin-bottom: 10px;
        }

        a {
          font-size: 12px;
          color: rgba($n-200, 0.8);
          font-weight: 500;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@keyframes fade-in-opacity {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fade-out-opacity {
  0% {
    display: block;
    opacity: 1;
  }

  99% {
    display: block;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}
