.client-settings {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;

  @media #{$screen-768} {
    flex-direction: column;
  }

  &__details {
    flex: 1;

    .name {
      height: 29px;
      margin-bottom: 2px;
      color: $n-100;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.57px;
      line-height: 29px;
    }

    .company {
      height: 19px;
      color: $n-100;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.38px;
      line-height: 19px;
    }

    .email-address-label,
    .location-label {
      height: 16px;
      margin-top: 20px;
      opacity: 0.8;
      color: $n-100;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.29px;
      line-height: 14px;
    }

    .email-address,
    .location {
      height: 19px;
      margin-top: 2px;
      color: $n-100;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.38px;
      line-height: 19px;
    }
  }

  &__account-manager-details {
    flex: 0;
    height: 132px;
    width: 242px;
    padding: 15px 15px 15px 21px;
    border: 1px solid $n-20;
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08),
      0 6px 10px 0 rgba(22, 33, 57, 0.1);

    .name {
      color: $n-100;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.29px;
      line-height: 19px;
      margin-bottom: 2px;
    }

    .position {
      color: rgba(46, 46, 46, 0.8);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.29px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    .divider {
      box-sizing: border-box;
      height: 1px;
      width: 212px;
      border: 1px solid $n-20;
      margin-bottom: 8px;
    }

    .email,
    .phone {
      height: 19px;
      color: $b-200;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.33px;
      line-height: 16px;
    }

    .email {
      margin-bottom: 10px;
    }
  }
}

.settings-button-wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;

  .settings-button {
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    background-color: rgba(31, 31, 30, 0.08);
    margin-right: 14px;

    &:hover {
      box-shadow: 0 3px 7px 0 rgba(15, 67, 180, 0.3);
    }

    b {
      height: 16px;
      width: 102px;
      color: $n-100;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.33px;
      line-height: 16px;
      text-align: center;
      white-space: nowrap;
    }
  }
}

.fly-settings {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;

  @media #{$screen-750} {
    flex-direction: column;
  }

  &__detailed {
    width: 100%;
    height: auto;
    flex: 1 1 auto;

    &__header {
      width: 100%;
      height: auto;
      margin-bottom: 20px;

      b {
        display: block;
        font-size: 24px;
        color: $n-200;
        margin-bottom: 2px;
        font-weight: 500;
      }

      p {
        display: block;
        font-size: 16px;
        color: $n-200;
        font-weight: 500;
        min-width: 2px;
      }
    }

    &__content {
      width: 100%;
      height: auto;

      &__item {
        width: 100%;
        height: auto;

        & + .fly-settings__detailed__content__item {
          margin-top: 20px;
        }

        p {
          display: block;
          margin-bottom: 2px;
          font-size: 12px;
          font-weight: 600;
          color: rgba($n-200, 0.8);
        }

        b {
          display: block;
          font-size: 16px;
          font-weight: 500;
          color: $n-200;
        }
      }
    }

    &__action-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      margin-top: 20px;

      @media #{$screen-400} {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        min-width: 60px;
        height: auto;
        min-height: 32px;
        padding: 4px 15px;
        border-radius: 4px;
        background-color: darken($n-20, 6%);

        & + button {
          margin-left: 10px;

          @media #{$screen-400} {
            margin-left: 0;
            margin-top: 10px;
          }
        }

        &:hover {
          background-color: $n-30;
        }

        &:active {
          box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.09);
        }

        b {
          font-size: 14px;
          color: $n-200;
          font-weight: 500;
        }
      }
    }
  }

  &__account-manager {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    min-width: 250px;
    padding: 15px;
    background-color: $n-0;
    border-radius: 6px;
    @include e200;

    @media #{$screen-750} {
      margin: 20px 0;
    }

    &__name {
      display: flex;
      flex-direction: column;
      width: auto;
      height: auto;

      b {
        display: inline-block;
        width: auto;
        height: auto;
        margin-bottom: 2px;
        font-size: 18px;
        color: $n-100;
        font-weight: 500;
      }

      p {
        font-size: 12px;
        font-weight: 500;
        color: $n-100;
        opacity: 0.8;
      }
    }

    &__divider {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $n-20;
      margin: 10px 0;
    }

    &__contact {
      display: flex;
      flex-direction: column;
      width: auto;
      height: auto;

      a {
        display: inline-block;
        width: auto;
        height: auto;
        color: $b-400;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }

        & + a {
          margin-top: 10px;
        }
      }
    }
  }
}

.inventory-settings {
  .fly-list-item__title {
    white-space: nowrap;

    .main {
      width: 60px;
    }
    .sub {
      width: 60px;

      input {
        color: #444855;
        border: 1px solid $n-20;
        background-color: rgba(255, 255, 255, 0.08);
        margin-top: 4px;
        margin-bottom: 4px;
        cursor: pointer;
        width: 60px;
        height: 25px;
        font-size: 14px;
        font-weight: 400;
        padding-left: 4px;
      }
    }
  }
}

.salesforce-settings {
  .fly-list-item__title {
    white-space: nowrap;

    .main {
      width: 75px;
    }
    .sub {
      width: 75px;

      input {
        color: #444855;
        border: 1px solid $n-20;
        background-color: rgba(255, 255, 255, 0.08);
        margin-top: 4px;
        margin-bottom: 4px;
        cursor: pointer;
        width: 200px;
        height: 25px;
        font-size: 14px;
        font-weight: 400;
        padding-left: 4px;
      }
    }
  }
}
