.fly-static-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  &__label {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 3px;
    color: $dark-ocean;
  }

  &__desc {
    display: block;
    width: 100%;
    font-size: 14px;
    color: $dark-ocean;
  }

  &__error {
    display: block;
    width: 100%;
    font-size: 14px;
    color: $red !important;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin-top: 5px;

    &.zipcode-list {
      .zipcode {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 57px;
        height: 24px;
        margin-bottom: 8px;
        margin-right: 8px;
        border-radius: 4px;
        background-color: rgba($blue-gray-2, 0.5);
      }

      button.show-more {
        width: auto;
        height: auto;

        b {
          font-size: 10px;
          color: $dark-sky;
        }

        &:hover {
          b {
            color: darken($dark-sky, 4%);
            text-decoration: underline;
          }
        }
      }
    }

    &.creative-list {
      .fly-creative-list__item {
        width: 150px !important;
        height: 50px !important;
        margin-bottom: 10px;
        margin-right: 10px;

        &:hover {
          transform: translate(0, 0);
          -webkit-transform: translate(0, 0);

          .image {
            box-shadow: 0 3px 14px rgba(25, 27, 30, 0.15);
          }
        }

        .image {
          img {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }

  & + .fly-static-info {
    margin-top: 20px;
  }
}
