* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
}

b {
  font-weight: normal;
}

p {
  margin: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
  background: none;
  background-color: transparent;

  &:focus,
  &:active {
    outline: none;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.header-title {
  color: $main-dark;
  font-size: 30px;
  font-weight: 500;
}

.input {
  &.login {
    padding-left: 12px;
    border-radius: 6px;
    width: 100%;
    height: 38px;
    border: 2px solid transparent;
    background-color: rgba($n-30, 0.51);
    font-size: 14px;
    color: $n-100;

    &:focus {
      outline: none;
      box-shadow: none;
      border: 2px solid $b-200;
      background-color: $n-0;
    }
  }

  &.label {
    padding-left: 12px;
    padding-bottom: 2px;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    font-weight: 400;
    letter-spacing: 0.1px;
  }

  &.error {
    outline: none;
    padding-left: 10px;
    box-shadow: 0 0 0 0 $white !important;
    border: 2px solid $red !important;
  }
}

input:-webkit-autofill,
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px rgba($n-30, 0.8) inset !important;
  border: 2px solid $n-30 !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px $n-30 inset !important;
  border: 2px solid $n-30 !important;
}

input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $n-0 inset !important;
  border: 2px solid $b-200 !important;
}

*[hidden] {
  display: none !important;
}
