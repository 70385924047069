.fly-search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  background-color: $n-10;
  border-radius: 6px;

  &:hover {
    background-color: $n-20;
  }

  i.icon {
    position: absolute;
    top: 8px;
    left: 3px;
  }

  input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 26px;
    border: 2px solid transparent;
    font-size: 14px;
    color: $n-200;
    background-color: transparent;
    border-radius: 6px;
    @include transition-general;

    &:focus {
      background-color: $white;
      outline: none;
      border-color: $b-200;
    }
  }

  .svg {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    margin-left: 8.25px;
  }

  .svg16 {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    margin-left: 12px;
  }

  &.small {
    width: 50%;
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 10px);
    display: none;
    width: 100%;
    height: auto;
    max-height: 200px;
    background-color: $white;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10000;

    @include e400;

    .search-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;

      i.icon {
        display: block;
        flex: 0 0 auto;
        margin-top: 3px;
        margin-right: 5px;
        color: rgba($main-dark, 0.9);
      }

      b {
        font-size: 14px;
        font-weight: 400;
        color: $n-200;

        mark {
          font-weight: 600;
          color: darken($b-200, 5%);
        }
      }

      &:hover {
        background-color: rgba($main-dark, 0.05);
      }

      & + .search-item {
        border-top: 1px solid rgba($main-dark, 0.1);
      }
    }

    .search-item-highlight {
      color: #2461e7;
      background-color: transparent;
    }
  }

  &.open-dropdown {
    .fly-search__dropdown {
      display: block;
    }
  }
}

.tooltip--inline {
  text-decoration: underline dashed;
}

.tooltip--inline:not(:hover) + .tooltip-content {
  visibility: hidden;
}

.tooltip-arrow {
  left: 0px;
  margin-left: -23px;
  height: 24px;
  width: 1em;
  position: absolute;

  &::before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-width: 12px 12px 12px 0px;
    border-color: transparent #3d3d3d transparent transparent;
  }
}

.sov-helper {
  line-height: initial;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.11);
  background-color: #3d3d3d;
  color: white;
  width: 227.4px;
  height: 244px;
  padding: 19.5px;
  border-radius: 2px;

  ul {
    li {
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
    }
  }
}

.sov-helper--row {
  display: inline-block;
  span:first-child {
    float: left;
    width: 52.5px;
    margin-right: 24px;
  }
}

.sov-helper--header {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
  display: block;
}

.white-holder__header,
.ozzy-table .table-filter {
  b,
  div {
    & + .fly-search {
      margin-left: 30px;

      &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: -15px;
        display: block;
        width: 1px;
        height: 20px;
        flex: 0 0 auto;
        background-color: $n-40;
      }
    }
  }

  .fly-search {
    background-color: transparent;
    margin-left: -5px;

    &:hover {
      background-color: $n-10;
    }
  }
}

.search {
  position: absolute;
  width: 240px;
  height: 36px;
  right: 40px;
  top: 24px;
  background-color: $n-0;
  border-radius: 6px;
  box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
  z-index: 2;

  &.client-margin-top {
    top: 175px;
  }

  .fly-search {
    background-color: $n-0;

    input {
      padding-left: 30px;
    }
  }

  .search-item {
    padding: 10px;
    height: auto;
  }
}
