@charset "UTF-8";

@font-face {
  font-family: "ozzy";
  src: url("../font/ozzy.eot");
  src: url("../font/ozzy.eot??#iefix") format("embedded-opentype"),
    url("../font/ozzy.woff") format("woff"),
    url("../font/ozzy.ttf") format("truetype"),
    url("../font/ozzy.svg#ozzy") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "ozzy" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ozzy-"]:before,
[class*=" ozzy-"]:before {
  font-family: "ozzy" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  font-family: "ozzy" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  display: inline-block;
  font-size: 24px;
  vertical-align: middle;
  line-height: 1;

  &.small-icon {
    font-size: 18px;
  }

  &.medium-icon {
    font-size: 20px;
  }

  &.standard-icon {
    font-size: 24px;
  }

  &.large-icon {
    font-size: 26px;
  }

  &.xlarge-icon {
    font-size: 38px;
  }
}

.ozzy-game-changer:before {
  content: "\e007";
}
.ozzy-hamburger:before {
  content: "\e000";
}
.ozzy-close:before {
  content: "\e001";
}
.ozzy-arrow-right:before {
  content: "\e002";
}
.ozzy-arrow-left:before {
  content: "\e003";
}
.ozzy-arrow-up:before {
  content: "\e004";
}
.ozzy-arrow-down:before {
  content: "\e005";
}
.ozzy-play-circle:before {
  content: "\e006";
}
.ozzy-real-time:before {
  content: "\e008";
}
.ozzy-heat-map:before {
  content: "\e009";
}
.ozzy-list-view:before {
  content: "\e00a";
}
.ozzy-online:before {
  content: "\e00b";
}
.ozzy-offline:before {
  content: "\e00c";
}
.ozzy-fleet:before {
  content: "\e00d";
}
.ozzy-campaign:before {
  content: "\e00e";
}
.ozzy-settings:before {
  content: "\e00f";
}
.ozzy-profile:before {
  content: "\e010";
}
.ozzy-info:before {
  content: "\e011";
}
.ozzy-right-arrow:before {
  content: "\e012";
}
.ozzy-left-arrow:before {
  content: "\e013";
}
.ozzy-right-arrow-alt:before {
  content: "\e014";
}
.ozzy-left-arrow-alt:before {
  content: "\e015";
}
.ozzy-up-arrow-alt:before {
  content: "\e016";
}
.ozzy-down-arrow-alt:before {
  content: "\e017";
}
.ozzy-forward:before {
  content: "\e018";
}
.ozzy-backward:before {
  content: "\e019";
}
.ozzy-warning:before {
  content: "\e01a";
}
.ozzy-square:before {
  content: "\e01b";
}
.ozzy-circle:before {
  content: "\e01c";
}
.ozzy-pen:before {
  content: "\e01d";
}
.ozzy-plus:before {
  content: "\e01e";
}
.ozzy-cross:before {
  content: "\e01f";
}
.ozzy-erase:before {
  content: "\e020";
}
.ozzy-search:before {
  content: "\e021";
}
.ozzy-cross-thick:before {
  content: "\e022";
}
.ozzy-clone:before {
  content: "\e023";
}
.ozzy-edit:before {
  content: "\e024";
}
.ozzy-trash:before {
  content: "\e025";
}
.ozzy-calendar:before {
  content: "\e026";
}
.ozzy-city:before {
  content: "\e027";
}
.ozzy-play:before {
  content: "\e028";
}
.ozzy-pause:before {
  content: "\e029";
}
.ozzy-stop:before {
  content: "\e02a";
}
.ozzy-real-time-v2:before {
  content: "\e02b";
}
.ozzy-heat-map-v2:before {
  content: "\e02c";
}
.ozzy-inventory:before {
  content: "\e02d";
}
.ozzy-report:before {
  content: "\e02e";
}
.ozzy-zipcode:before {
  content: "\e02f";
}
.ozzy-check:before {
  content: "\e030";
}
.ozzy-calculate:before {
  content: "\e031";
}
.ozzy-upload:before {
  content: "\e032";
}
.ozzy-download:before {
  content: "\e033";
}
.ozzy-info:before {
  content: "\e034";
}
.ozzy-campaign-manager:before {
  content: "\e035";
}
.ozzy-playlist:before {
  content: "\e036";
}
.ozzy-planner:before {
  content: "\e037";
}
