.fly-svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  &--small {
    width: 20px;
    height: auto;
  }

  &--medium {
    width: 26px;
    height: auto;
  }

  &--large {
    width: 30px;
    height: auto;
  }

  &--xlarge {
    width: 36px;
    height: auto;
  }

  &--22 {
    width: 22px;
    height: auto;
    fill: white;
  }

  &--15 {
    width: 15px;
    height: auto;
    fill: white;
    margin-top: 5px;
  }
}
