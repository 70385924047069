.fly-breadcrumb {
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  color: rgba($n-100, 0.8);
  @include transition-general;

  &.mobile {
    @media #{$screen-800} {
      display: flex;
    }
    @media #{$screen-min-800} {
      display: none;
    }
  }

  &:hover {
    color: $b-400;

    .fly-svg-icon {
      margin-left: -2px;
      margin-right: 2px;
    }

    .breadcrumb-item {
      b,
      a {
        color: $b-400;
      }
    }
  }

  .fly-svg-icon {
    margin-top: 2px;
    width: 32px;

    @include transition-general;
  }

  .breadcrumb-item {
    display: flex;
    align-items: center;

    @include transition-general;

    a,
    b {
      position: relative;
      display: inline-block;
      color: rgba($n-100, 0.8);
      font-size: 11px;
      font-weight: 500;

      @include transition-general;

      & + a {
        margin-left: 5px;

        &::before {
          content: "/";
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
}
