.fly-graph-label {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 1 auto;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
  }

  &__divider {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 1px;
    height: 24px;
    margin: 0 8px;
    background-color: $n-40;
  }
}
