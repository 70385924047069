.fly-location-charts {
  .has-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }

  @media #{$screen-800} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    margin-left: -20px;
    width: calc(100% + 40px);
    padding-left: 10px;

    &::after {
      content: "";
      flex: 0 0 auto;
      display: inline-block;
      width: 10px;
      height: 30px;
    }
  }

  .location-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 130px;
    height: auto;
    flex: 1 1 auto;
    margin: 0 10px 20px 10px;
    padding: 10px;
    border-radius: 6px;

    @media #{$screen-800} {
      background-color: $n-0;
      margin-bottom: 0;

      &:hover {
        background-color: $n-0;
      }
    }

    &:hover {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
      background-color: rgba(245, 245, 245, 0.7);
    }

    .location-chart {
      position: relative;
      width: 110px;
      height: 110px;
      margin-bottom: 10px;
      border-radius: 100%;
      background-color: #f1f1f1;

      &::after {
        content: "";
        position: absolute;
        top: 20px;
        left: 20px;
        width: 70px;
        height: 70px;
        border-radius: 35px;
        background-color: $gray-1;
        box-shadow: 0 0 3px 0 rgba($shadow-color, 0.2);
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: transparent;
        box-shadow: inset 0 1px 3px 0 rgba($shadow-color, 0.2);
        z-index: 30;
      }

      .percent-text {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        color: $n-100;
        z-index: 20;
        text-align: center;
        font-size: 18px;
        letter-spacing: 0.6px;
        line-height: 18px;

        b {
          font-weight: 600;
          display: inline-block;
          margin-right: 1px;
        }

        span {
          display: inline-block;
          font-size: 12px;
          margin-top: 6px;
          // opacity: 0.7;
          color: $n-100;
        }
      }

      canvas {
        position: absolute;
        height: 112px !important;
        width: 112px !important;
        top: -1px;
        left: -1px;
      }
    }

    .location-text-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .name {
        width: 100%;
        display: block;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.31px;
        line-height: 15px;
        color: $n-100;
        margin-bottom: 5px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .info {
        display: inline-block;
        font-size: 13px;
        color: $b-300;

        span {
          display: inline-block;
          font-size: 10px;
          color: rgba(46, 46, 46, 0.7);
          margin-left: 2px;
          font-weight: 600;
        }
      }
    }

    .fly-button {
      @media #{$screen-1024} {
        visibility: visible;
      }
    }
  }

  .no-data-text {
    text-align: left;
  }
}
