// this is not working

.card-loading {
  width: 100%;
  height: 100%;
  // margin-left: 18px;

  span {
    display: grid;
    grid-template-columns: 300px 300px 300px 300px 300px;
    grid-gap: 80px;

    @media #{$screen-768} {
      display: flex;
      flex-direction: column;
    }

    span {
      flex: 1 1 18%;
      max-width: 300px;
      min-width: 300px;
      max-height: 185px;
      min-height: 185px;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      border-radius: 6px;
      background-color: $white;
      background-image: linear-gradient(
        90deg,
        $white,
        darken($gray-3, 2%),
        $white
      );
      margin-bottom: 20px;
    }
  }
}

.card-loading-inventory {
  width: 100%;
  height: 100%;
  // margin-left: 18px;

  span {
    display: grid;
    grid-template-columns: 300px 300px 300px 300px 300px;
    grid-gap: 18px;

    @media #{$screen-768} {
      display: flex;
      flex-direction: column;
    }

    span {
      flex: 1 1 18%;
      max-width: 300px;
      min-width: 300px;
      max-height: 185px;
      min-height: 185px;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      border-radius: 6px;
      background-color: $white;
      background-image: linear-gradient(
        90deg,
        $white,
        darken($gray-3, 2%),
        $white
      );
      margin-bottom: 20px;
    }
  }
}

.campaign-card-loading {
  width: 100%;
  height: 100%;
  grid-column: 1 / span 4;

  & > span {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    @media #{$screen-750} {
      grid-gap: 15px;
      grid-template-columns: 100%;
      padding: 0 0 30px 0;
    }

    span {
      width: 100%;
      height: 212px;
      box-shadow: 0 6px 20px 0 rgba(#162139, 0.08);
      border-radius: 6px;
      background-color: $white;
      background-image: linear-gradient(
        90deg,
        $white,
        darken($gray-3, 2%),
        $white
      );

      @media #{$screen-750} {
        height: 64px;
      }
    }
  }
}

.media-loading {
  display: flex;
  width: calc(100% / 2 - 10px);
  height: auto;
  margin-bottom: 20px;
  transition: transform 0.2s ease;

  span {
    span {
      width: 200px;
      height: 60px;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      border-radius: 6px;
      background-color: $white;
      background-image: linear-gradient(
        90deg,
        $white,
        darken($gray-3, 2%),
        $white
      );

      & + span {
        margin-left: 20px;
      }
    }
  }
}

.media-list-loading {
  display: flex;
  width: calc(100% / 2 - 10px);
  height: auto;
  margin-bottom: 20px;
  transition: transform 0.2s ease;

  span {
    span {
      width: 240px;
      height: 80px;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      border-radius: 6px;
      background-color: $white;
      background-image: linear-gradient(
        90deg,
        $white,
        darken($gray-3, 2%),
        $white
      );

      & + span {
        margin-left: 20px;
      }
    }
  }

  &.full {
    width: 100%;
    flex: 1 1 auto;
    margin-bottom: 0;

    span {
      display: block;
      width: 100%;

      span {
        width: 100%;
        padding-top: 33.2%;
      }
    }
  }
}

.playlist-creative-item-loading {
  display: flex;
  width: calc(100% / 2 - 10px);
  height: auto;
  margin-bottom: 20px;
  transition: transform 0.2s ease;

  span {
    span {
      width: 112px;
      height: 41px;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      border-radius: 6px;
      background-color: $white;
      background-image: linear-gradient(
        90deg,
        $white,
        darken($gray-3, 2%),
        $white
      );

      & + span {
        margin-left: 20px;
      }
    }
  }

  &.full {
    width: 100%;
    flex: 1 1 auto;
    margin-bottom: 0;

    span {
      display: block;
      width: 100%;

      span {
        width: 100%;
        padding-top: 33.2%;
      }
    }
  }
}

.list-item-loading {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &.has-tab {
    padding-top: 20px;
  }

  span {
    span {
      width: 100%;
      height: 45px;
      border-radius: 6px;
      background-color: $blue-gray-1;
      background-image: linear-gradient(
        90deg,
        $blue-gray-1,
        darken($blue-gray-1, 2%),
        $blue-gray-1
      );

      & + span {
        margin-top: 8px;
      }
    }
  }
}

.header-loading {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  span {
    display: flex;
    flex-direction: column;

    span {
      width: 300px;
      height: 35px;
      margin-bottom: 10px;
      border-radius: 6px;
      background-color: $white;
      background-image: linear-gradient(
        90deg,
        $white,
        darken($gray-3, 2%),
        $white
      );

      &:nth-child(2) {
        width: 500px;
        height: 30px;
        margin-bottom: 0;
      }
    }
  }
}

.sub-menu-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;

  &.has-tab {
    padding-top: 20px;
  }

  span {
    span {
      width: 100%;
      height: 60px;
      border-radius: 6px;
      background-color: $blue-gray-1;
      background-image: linear-gradient(
        90deg,
        $blue-gray-1,
        darken($blue-gray-1, 2%),
        $blue-gray-1
      );

      & + span {
        margin-top: 10px;
      }
    }
  }
}

.fly-admin {
  .map-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba($white, 0.6);
    z-index: 210;

    &__animation {
      width: auto;
      height: auto;

      .fly-folding-cube {
        margin: 20px auto;
        width: 40px;
        height: 40px;
        position: relative;
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);

        .fly-cube {
          float: left;
          width: 50%;
          height: 50%;
          position: relative;
          -webkit-transform: scale(1.1);
          -ms-transform: scale(1.1);
          transform: scale(1.1);

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $b-100;
            -webkit-animation: fly-foldCubeAngle 2.4s infinite linear both;
            animation: fly-foldCubeAngle 2.4s infinite linear both;
            -webkit-transform-origin: 100% 100%;
            -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
          }

          &.fly-cube2 {
            -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg);

            &::before {
              -webkit-animation-delay: 0.3s;
              animation-delay: 0.3s;
            }
          }

          &.fly-cube3 {
            -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg);

            &::before {
              -webkit-animation-delay: 0.6s;
              animation-delay: 0.6s;
            }
          }

          &.fly-cube4 {
            -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg);

            &::before {
              -webkit-animation-delay: 0.9s;
              animation-delay: 0.9s;
            }
          }
        }
      }

      @-webkit-keyframes fly-foldCubeAngle {
        0%,
        10% {
          -webkit-transform: perspective(140px) rotateX(-180deg);
          transform: perspective(140px) rotateX(-180deg);
          opacity: 0;
        }

        25%,
        75% {
          -webkit-transform: perspective(140px) rotateX(0deg);
          transform: perspective(140px) rotateX(0deg);
          opacity: 1;
        }

        90%,
        100% {
          -webkit-transform: perspective(140px) rotateY(180deg);
          transform: perspective(140px) rotateY(180deg);
          opacity: 0;
        }
      }

      @keyframes fly-foldCubeAngle {
        0%,
        10% {
          -webkit-transform: perspective(140px) rotateX(-180deg);
          transform: perspective(140px) rotateX(-180deg);
          opacity: 0;
        }

        25%,
        75% {
          -webkit-transform: perspective(140px) rotateX(0deg);
          transform: perspective(140px) rotateX(0deg);
          opacity: 1;
        }

        90%,
        100% {
          -webkit-transform: perspective(140px) rotateY(180deg);
          transform: perspective(140px) rotateY(180deg);
          opacity: 0;
        }
      }
    }

    &__text {
      width: auto;
      height: auto;
      margin-top: 10px;

      b {
        color: $dark-ocean;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

.map-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba($white, 0.6);
  z-index: 90;

  &__animation {
    width: auto;
    height: auto;

    .fly-folding-cube {
      margin: 20px auto;
      width: 40px;
      height: 40px;
      position: relative;
      -webkit-transform: rotateZ(45deg);
      transform: rotateZ(45deg);

      .fly-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $b-100;
          -webkit-animation: fly-foldCubeAngle 2.4s infinite linear both;
          animation: fly-foldCubeAngle 2.4s infinite linear both;
          -webkit-transform-origin: 100% 100%;
          -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
        }

        &.fly-cube2 {
          -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);

          &::before {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
          }
        }

        &.fly-cube3 {
          -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);

          &::before {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
          }
        }

        &.fly-cube4 {
          -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);

          &::before {
            -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
          }
        }
      }
    }

    @-webkit-keyframes fly-foldCubeAngle {
      0%,
      10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
      }

      25%,
      75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
      }

      90%,
      100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
      }
    }

    @keyframes fly-foldCubeAngle {
      0%,
      10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
      }

      25%,
      75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
      }

      90%,
      100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
      }
    }
  }

  &__text {
    width: auto;
    height: auto;
    margin-top: 10px;

    b {
      color: $dark-ocean;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.editable-list-loading {
  display: flex;
  width: 100%;
  height: 100%;

  span {
    display: block;
    width: 100%;
    height: 50px;

    span {
      display: block;
      width: 100%;
      height: 50px;
      box-shadow: 0 6px 20px 0 rgba(22, 33, 57, 0.06);
      border-radius: 6px;
      background-color: $white;
      background-image: linear-gradient(
        90deg,
        $white,
        darken($gray-3, 2%),
        $white
      );

      & + span {
        margin-left: 20px;
      }
    }
  }
}

.sub-menu-item-loading {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  span {
    display: block;
    width: 100%;
    height: 65px;

    span {
      display: block;
      width: 100%;
      height: 65px;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 0;
      background-color: $white;
      background-image: linear-gradient(90deg, $white, $gray-3, $white);
    }
  }
}

.chart-loading {
  height: 339px;
  width: 100%;
  overflow: hidden;
  margin: 0 !important;
  padding: 0.5rem;
  span {
    width: 100%;
    span {
      height: 100%;
      width: unset;
      display: block;
    }
  }
}
