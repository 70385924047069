.fly-footer-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: $white;
  box-shadow: 0 0 20px 0 rgba(70, 59, 55, 0.15);

  .max-width {
    height: 100%;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    button + button {
      margin-left: 10px;
    }
  }
}
