.fly-white-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $gray-1;
  border: 1px solid $gray-1;
  box-shadow: 0 6px 20px 0 rgba($shadow-color, 0.06);
  @include transition-general;

  &.white-bg {
    border: 1px solid #e3e3e3;

    &:hover {
      border: 1px solid #e3e3e3;
    }
  }

  &__text {
    flex: 1 1 auto;
    width: 100%;
    height: auto;
    padding-left: 15px;
    margin-right: 0;
    margin-left: 1px;

    b {
      display: block;
      font-size: 14px;
      font-weight: 600;
      color: $dark-ocean;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__action-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: auto;
    height: auto;
    opacity: 0;
    @include transition-general;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 4px;
      background-color: darken($blue-gray-1, 1%);
      @include transition-general;

      i.icon {
        margin-top: 1px;
        color: $dark-ocean;
        font-size: 29px;

        &.ozzy-check {
          font-size: 37px;
          margin-top: -2px;
        }
      }

      &:hover {
        background-color: darken($blue-gray-1, 5%);
      }

      & + button {
        margin-left: 6px;
      }
    }
  }

  .fly-combo-box {
    width: 100%;
    flex: 1 1 auto;
    margin-right: 6px;

    .Select {
      .Select-control {
        width: 100%;

        .Select-multi-value-wrapper {
          .Select-value {
            .Select-value-label {
              display: block;
              font-size: 14px;
              font-weight: 600;
              color: $dark-ocean;
              margin-left: -1px;
            }
          }
        }
      }
    }
  }

  &:hover {
    border: 1px solid $white;
    background-color: $white;
    box-shadow: 0 8px 20px 0 rgba($shadow-color, 0.1);

    .fly-white-item__action-box {
      opacity: 1;
    }
  }

  & + .fly-white-item {
    margin-top: 15px;
  }

  & + button {
    margin-top: 10px;
  }

  &.editable-active {
    border: 1px solid rgba($pumpkin, 1);
    box-shadow: 0 3px 25px 0 rgba(89, 17, 4, 0.16);

    .fly-white-item__text {
      padding-left: 0;
      margin-right: 8px;
      margin-left: 0;

      input {
        width: 100%;
        height: 35px;
        padding-left: 15px;
        padding-right: 15px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 600;
        color: $dark-ocean;
        border: 1px solid #e4e4e4;
        background-color: #f9f9f9;
        border-radius: 20px;
      }
    }

    .fly-white-item__action-box {
      opacity: 1;

      button {
        &:first-child {
          background-color: transparent;

          i.icon {
            opacity: 0.8;
          }
        }
        &:last-child {
          background-color: $pumpkin;

          &:hover {
            background-color: darken($pumpkin, 5%);
          }

          i.icon {
            color: $white;
          }
        }
      }
    }
  }

  //Header style
  &.is-header {
    width: auto;
    max-width: calc(100% - 63px);
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding-left: 0;
    flex: 0 0 auto;
    cursor: pointer;

    &:hover {
      border: none;
      box-shadow: none;
      background-color: transparent;
    }

    .fly-white-item__text {
      padding-left: 16px;
      margin-right: 15px;

      b {
        font-size: 16px;
      }

      input {
        font-size: 16px;

        &:focus {
          outline: none;
        }
      }
    }

    &.editable-active {
      width: calc(100% - 33px);
      max-width: calc(100% - 33px);

      .fly-white-item__text {
        padding-left: 0;
      }
    }
  }
}
