.legend-container {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  background: white;
  min-width: 175px;
  min-height: 220px;
  overflow-y: scroll;
  border-radius: 6px;
  box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);

  .legend-inner {
    position: relative;
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 14px;

    .title {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.35px;
      color: #2e2e2e;
    }

    .hide-button {
      width: auto !important;
      margin: 0 !important;
      cursor: pointer;
    }
  }

  .legend-items {
    font-size: 14px;
    line-height: 30px;

    .legend-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-color {
        width: 10px;
        height: 30px;
      }
      &-text {
        margin-left: 12px;
      }
    }
  }
}
