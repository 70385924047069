/* New Firefly Color palette */

//Blue
$b-100: #4c84ff;
$b-200: #3675ff;
$b-300: #2160e9;
$b-400: #1953d1;

//Orange
$o-100: #ffac61;

//Green
$g-100: #34a853;
$g-200: #4dc17a;

//Pink
$p-200: #fc8975;

//Teal
$t-200: #22acea;

//Neutrals
$n-0: #fff;
$n-10: #f5f5f5;
$n-20: #eaeaea;
$n-30: #cececf;
$n-40: #c0c0c0;
$n-100: #2e2e2e;
$n-200: #1f1f1e;
$n-900: #000;

//Red
$r-200: #ff213d;

/* Old Firefly Color palette */
$white: #fff;
$black: #000;
$slow-gray: #e3e3e3;
$carbon: #313131;
$crimson-pink: #fc8975;
$dark-ocean: #444855;
$sea-green: #4fc8d6;
$crimsion-pinkish: lighten(#fc8975, 3%);
$cloud: #f5faff;
$semi-dark-cloud: #f0f4f8;
$dark-sky: #3262dd;
$continental-blue: #436285;
$midnight: #062863;

/* Main colors */

$pumpkin: #fc8975;
$main-dark: #4f4f4f;
$slow-white: #fdfdfd;
$background: #f2f2f2;
$shadow-color: #162139;

/* Grey colors */

$gray-1: #fbfbfb;
$gray-2: #cacaca;
$gray-3: #f9f9f9;
$gray-4: #e4e4e4;
$gray-5: #dedede;
$gray-6: #ececec;

/* Blue Grey colors */

$blue-gray-1: #f4f5f7;
$blue-gray-2: #e5eaee;
$blue-gray-3: #d3dce2;
$blue-gray-4: #c7d2da;
$blue-gray-5: #e8f1ff;
$blue-gray-6: #ebf2ff;

/* Alterate Color */

$bright-green: #56e481;
$bright-red: #f56262;
$bright-yellow: #f5d662;

$green: #14ac17;
$red: #dd1212;
$blue: #0f70e6;

$lightish-blue: #3675ff;
$very-light-pink: #d8d8d8;
