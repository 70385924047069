.fly-day-hour-chart {
  width: 100%;
  height: 100%;

  &__header {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 20px;

    .toggle-switch-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;

      .switch {
        display: block;
        width: auto;
        height: 22px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 11px;
        cursor: pointer;

        b {
          font-size: 11px;
          color: $main-dark;
          opacity: 0.8;
        }

        &:hover {
          background-color: rgba($main-dark, 0.1);

          b {
            opacity: 1;
          }
        }

        &:active {
          box-shadow: inset 0 1px 3px 0 rgba($n-200, 0.21);
        }

        & + .switch {
          margin-left: 8px;
        }

        &.is-active {
          background: linear-gradient(
            135deg,
            lighten($b-300, 5%) 0,
            darken($b-300, 5%) 100%
          );
          box-shadow: 0 2px 4px 0 rgba(#a84837, 0.19);

          &:hover {
            background-color: none;
          }

          b {
            opacity: 1;
            color: $white;
          }
        }
      }
    }

    .legend-box {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      //change to flex when you want to show legend
      align-items: center;
      justify-content: flex-end;

      .color {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 4px;
        background-color: $b-300;
      }

      b {
        display: inline-block;
        font-size: 12px;
        color: $main-dark;
        opacity: 0.8;
      }
    }
  }

  &__chart {
    position: relative;
    width: 100%;

    .day-chart {
      width: 100%;
      height: 100%;
    }

    .hour-chart {
      width: 100%;
      height: 100%;
    }
  }

  &.day-is-active {
    .fly-day-hour-chart__chart {
      .day-chart {
        display: inline-block;
        z-index: 10;
      }

      .hour-chart {
        display: none;
        z-index: 5;
      }
    }
  }

  &.hour-is-active {
    .fly-day-hour-chart__chart {
      .day-chart {
        display: none;
        z-index: 5;
      }

      .hour-chart {
        display: inline-block;
        z-index: 10;
      }
    }
  }
}
