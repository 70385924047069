.fly-map-search {
  position: absolute;
  left: calc(50% - 350px);
  top: 20px;
  width: 700px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 5px 20px -5px rgba(#21242c, 0.32);
  background-color: $white;
  z-index: 200;

  @media #{$screen-750} {
    width: calc(80%);
    left: calc(10%);
  }

  &__search-box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 16px;

    i.icon {
      display: block;
      flex: 0 0 auto;
      font-size: 32px;
      margin-top: 7px;
      color: rgba($main-dark, 0.7);
    }

    input {
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 14px;
      color: $main-dark;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }

    .bullet-point {
      padding-left: 8px;
      color: #c7c7c5;

      @media #{$screen-800} {
        display: none;
      }
    }

    .action-box {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: flex-end;
      width: auto;
      height: auto;

      .car-filter-box {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        height: auto;
        width: auto;

        .days-left {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.39px;
          line-height: 16px;
          padding-left: 8px;
          color: $n-100;

          @media #{$screen-800} {
            display: none;
          }
        }

        .days-campaign {
          padding-left: 8px;
          display: flex;
          align-items: center;

          .campaign-dates {
            padding-left: 14px;
            color: $n-100;
            font-size: 12px;
            letter-spacing: 0.33px;
            line-height: 14px;
          }
        }

        &__item {
          display: flex;
          align-items: center;
          width: auto;
          height: 20px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 10px;
          @include transition-general;

          &:hover {
            background-color: rgba($main-dark, 0.1);
          }

          &.is-active {
            cursor: inherit;

            b {
              color: $white;
            }
          }

          b {
            font-size: 13px;
            font-weight: 600;
            color: rgba($dark-ocean, 0.8);
          }

          &.all.is-active {
            background-color: rgba($dark-ocean, 0.8);
            box-shadow: 0 2px 4px rgba($dark-ocean, 0.2);
          }

          &.online.is-active {
            background-color: $sea-green;
            box-shadow: 0 2px 4px rgba(darken($sea-green, 10%), 0.2);
          }

          &.offline.is-active {
            background-color: $crimson-pink;
            box-shadow: 0 2px 4px rgba(darken($crimson-pink, 10%), 0.2);
          }

          & + .car-filter-box__item {
            margin-left: 4px;
          }
        }

        .off-road {
          margin-left: 12px;
          font-size: 13px;
          font-weight: 600;
          color: rgba($dark-ocean, 0.8);

          label {
            display: flex;
            align-items: center;

            input {
              width: 14px;
              height: 50px;
            }

            span {
              white-space: nowrap;
              display: block;
              width: 55px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 10px);
    left: 25px;
    display: none;
    width: calc(100% - 50px);
    height: auto;
    max-height: 200px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 5px 20px -5px rgba(#21242c, 0.32);
    overflow-x: hidden;
    overflow-y: auto;

    .search-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;

      i.icon {
        display: block;
        flex: 0 0 auto;
        margin-top: 3px;
        margin-right: 5px;
        color: rgba($main-dark, 0.9);
      }

      b {
        font-size: 14px;
        font-weight: 400;
        color: $main-dark;
      }

      &:hover {
        background-color: rgba($main-dark, 0.05);
      }

      & + .search-item {
        border-top: 1px solid rgba($main-dark, 0.1);
      }
    }
  }

  &.open-dropdown {
    .fly-map-search__dropdown {
      display: block;
    }
  }
}
