.fly-textarea {
  width: 100%;
  height: auto;
  min-height: 60px;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid transparent;
  background-color: $n-10;
  resize: vertical;
  color: $n-200;
  font-size: 14px;

  &:hover {
    background-color: darken($n-10, 5%);
  }

  &:focus {
    border-color: $b-100;
    outline: none;
    box-shadow: 0 0 0 0 $white !important;
    background-color: $n-0;
  }

  & + button {
    margin-top: 10px;
  }
}
