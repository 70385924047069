.fr-top {
  &.has-shadow {
    box-shadow: 0 0 10px 0 rgba($n-200, 0.3);
  }

  &.max-width {
    .fr-top__wrapper {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 40px;

      @media #{$screen-800} {
        padding: 0 20px;
      }
    }
  }

  &.has-detail-padding {
    &.fr-top {
      padding-left: 60px !important;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &.has-padding {
      padding: 0 18px;
    }
  }

  &__wrapper_menu {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 55px;

    .statistic-card {
      display: flex;
      margin-right: 14px;

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 11px;
        margin-left: 32px;

        .statistic-small {
          flex: 1;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.39px;
          line-height: 16px;
          color: $n-100;
          white-space: nowrap;
        }

        .statistic-big {
          flex: 1;
          color: $b-200;
          font-size: 26px;
          font-weight: 600;
          letter-spacing: 0.72px;
          line-height: 31px;
        }
      }
    }

    &.has-padding {
      padding: 0 18px;
    }

    .menu-wrapper {
      display: flex;
      align-items: flex-start;
      width: 238px;
      padding-left: 7px;
      padding-bottom: 5px;

      .menu-item {
        padding-right: 30px;
        white-space: nowrap;
        color: $n-100;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.33px;
        line-height: 16px;
        margin-bottom: -4px;
        height: 18px;

        display: flex;
        flex-direction: column;
      }

      .is-active {
        span {
          .blue-bar {
            background-color: $b-200;
            border-radius: 2px;
            height: 8px;
            margin-top: 2px;
          }
        }
      }
    }
  }

  &__wrapper_page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &.has-padding {
      padding: 0 18px;
    }

    .fr-page {
      flex: 1 1 auto;
      width: 100%;
      height: auto;

      &__header {
        position: relative;
        width: 100%;
        height: auto;
        color: red;

        .campaign-header {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;

          &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;

            &__left {
              display: flex;
              align-items: center;
            }

            &__right {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }

          &__bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;

            &__left {
              .tag-box {
                display: flex;
                align-items: center;
              }
            }

            &__right {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }

          .header {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 10px;

            i.icon {
              display: inline-block;
              margin-left: 10px;
              font-size: 35px;
              margin-top: 4px;

              &.live {
                color: $bright-green;
              }

              &.paused {
                color: $bright-yellow;
              }

              &.ended {
                color: $bright-red;
              }
            }

            i.icon-live {
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: green;
              margin-left: 10px;
              margin-top: 4px;
              border-radius: 5px;
            }

            i.icon-paused {
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: yellow;
              margin-left: 10px;
              margin-top: 4px;
              border-radius: 5px;
            }

            i.icon-ended {
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: red;
              margin-left: 10px;
              margin-top: 4px;
              border-radius: 5px;
            }
          }

          .info-box {
            position: relative;
            display: flex;
            align-items: center;

            &__item {
              display: flex;
              align-items: center;

              b,
              p {
                font-size: 13px;
                color: $main-dark;
              }

              p {
                margin-right: 5px;
              }

              b {
                font-weight: 600;
              }

              & + .info-box__item {
                margin-left: 15px;

                &:before {
                  content: "";
                  display: block;
                  width: 1px;
                  height: 15px;
                  margin-right: 15px;
                  background-color: rgba($main-dark, 0.5);
                }
              }
            }
          }

          .tag-box + .info-box {
            margin-left: 20px;
          }
        }

        .page-header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: auto;

          b {
            display: block;
            flex: 0 0 auto;
            font-size: 18px;
            font-weight: 600;
            color: $main-dark;

            & + .fly-search {
              margin-left: 20px;
              padding-left: 10px;

              &:before {
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                width: 1px;
                height: 20px;
                background-color: darken($gray-2, 10%);
                display: block;
              }
            }
          }

          .action-box {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: flex-end;
            width: auto;
            height: auto;

            &__item {
              display: flex;
              align-items: center;

              b {
                color: $main-dark;
                font-size: 14px;
                margin-right: 10px;
                font-weight: 400;
              }

              & + .action-box__item {
                margin-left: 15px;

                &::before {
                  content: "";
                  display: block;
                  width: 1px;
                  height: 20px;
                  background-color: rgba($main-dark, 0.2);
                  margin-right: 15px;
                }
              }
            }
          }
        }

        .playlist-header {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;

          .tag-box {
            flex: 0 0 auto;

            & + .header {
              margin-left: 10px;
            }
          }

          .action-box {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: flex-end;
            width: auto;
            height: auto;

            &__item {
              display: flex;
              align-items: center;

              b {
                color: $main-dark;
                font-size: 14px;
                margin-right: 10px;
                font-weight: 400;
              }

              & + .action-box__item {
                margin-left: 15px;

                &::before {
                  content: "";
                  display: block;
                  width: 1px;
                  height: 20px;
                  background-color: rgba($main-dark, 0.2);
                  margin-right: 15px;
                }
              }
            }
          }

          .header {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 0;

            b {
              display: inline-block;
              color: $main-dark;
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }

      &__content {
        width: 100%;
        height: auto;
      }

      &.has-tab {
        .white-holder {
          display: none;

          &:first-child {
            border-radius: 0 6px 6px 6px;
          }

          &__header {
            border-radius: 0;
            border-bottom: 1px solid $n-10;
          }

          &__content {
            padding-top: 20px;
          }

          &.is-active {
            display: block;
          }

          & + .white-holder {
            margin-top: 0;
          }
        }
      }

      &.has-scroll {
        display: flex;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
      }

      &.has-max-width {
        width: 100vh; /* Fallback for browsers that do not support Custom Properties */
        width: calc(var(--vh, 1vh) * 100);
        overflow-y: overlay;
        overflow-x: auto;

        display: flex;
        justify-content: center;
        @media #{$screen-1400} {
          justify-content: flex-start;
        }
        .max-width {
          flex: 1;
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          padding: 0 40px;
          @media #{$screen-1440} {
            margin-left: 0;
            padding: 0 0;
          }
        }
      }

      &.has-footer-menu {
        padding-bottom: 80px;
      }

      &.has-top-menu {
        padding-top: 110px;
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: auto;
    height: auto;

    @include transition-general;

    img {
      height: 16px;
      width: auto;
    }
  }

  &__hamburger_menu {
    position: relative;
    display: none;
    flex: 0 0 auto;
    width: 30px;
    height: 100%;
    background-image: url(../image/iconfinder_menu-alt_134216.svg);
    background-size: 30px auto;
    background-position: left center;
    background-repeat: no-repeat;
    @include transition-general;

    @media #{$screen-800} {
      display: flex;
    }
  }

  &__controller,
  .tab-controller {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;

    left-side-inner1 .tab-item {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 5px;
      padding-right: 5px;

      @include transition-general;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        height: 2px;
        border-radius: 3px;
        opacity: 0;
        background-color: $white;
        z-index: 100;

        @include transition-general;
      }

      i.icon {
        flex: 0 0 auto;
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 10px;
        font-size: 30px;
        color: $white;
      }
      b {
        display: block;
        color: $white;
        font-size: 13px;
        letter-spacing: 1px;
      }

      &:hover {
        background-color: rgba($black, 0.03);
      }

      &.is-active {
        &::before {
          opacity: 1;
        }
      }

      &.all-btn {
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    &.displaynone {
      display: none;
    }
  }

  &__dropdown-box {
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    flex: 0 0 auto;
    width: auto;
    height: 30px;

    @media #{$screen-800} {
      display: none;
    }

    & + .fr-top__dropdown-box {
      margin-left: 10px;
    }

    &--is-active {
      .fr-top__dropdown-box__button {
        background-color: $n-20;

        &:hover {
          background-color: $n-20;
        }
      }

      .fr-top__dropdown-box__dropdown {
        display: flex;
      }
    }

    &--account {
      .fr-top__dropdown-box__button {
        b,
        p {
          color: $b-400;
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: 100%;
      border-radius: 3px;
      padding: 0 15px;
      white-space: nowrap;
      cursor: pointer;

      @include transition-general;

      &:hover {
        background-color: $n-10;
      }

      &:active {
        box-shadow: inset 0 1px 3px 0 rgba($n-200, 0.11);
        background-color: $n-10;
      }

      b,
      p {
        display: inline-block;
        color: $n-100;
        font-size: 14px;
        font-weight: 500;

        @include noselect;

        & + i.icon {
          margin-left: 5px;
          margin-right: -8px;
        }
      }

      p {
        font-weight: 400;

        + b {
          margin-left: 3px;
        }
      }

      i.icon {
        margin-top: 5px;
        margin-right: 5px;
        margin-left: -8px;

        @include noselect;
      }

      img {
        width: 22px;
        height: 22px;
      }
    }

    &__dropdown {
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      display: none;
      flex-direction: column;
      width: auto;
      min-width: 180px;
      height: auto;
      max-height: 500px;
      padding: 10px 0;
      border-radius: 3px;
      background-color: $n-0;
      overflow-y: auto;
      z-index: 600;

      @include e300;

      &__account-box {
        width: auto;
        min-width: 240px;
        padding: 5px 15px;

        &__name {
          display: flex;
          flex-direction: column;
          width: auto;
          height: auto;

          b {
            display: inline-block;
            width: auto;
            height: auto;
            margin-bottom: 2px;
            font-size: 18px;
            color: $n-100;
            font-weight: 500;
          }

          p {
            font-size: 12px;
            font-weight: 500;
            color: $n-100;
            opacity: 0.8;
          }
        }

        &__divider {
          display: block;
          width: 100%;
          height: 1px;
          background-color: $n-20;
          margin: 10px 0;
        }

        &__contact {
          display: flex;
          flex-direction: column;
          width: auto;
          height: auto;

          a {
            display: inline-block;
            width: auto;
            height: auto;
            color: $b-400;
            font-size: 14px;
            font-weight: 500;

            &:hover {
              text-decoration: underline;
            }

            & + a {
              margin-top: 10px;
            }
          }
        }
      }

      &__item {
        display: flex;
        align-items: center;
        width: auto;
        min-height: 32px;
        padding: 4px 16px;
        background-color: $n-0;
        cursor: pointer;

        @include transition-general;

        &:hover {
          background-color: $n-10;
        }

        &:active {
          box-shadow: inset 0 1px 3px 0 rgba($n-200, 0.11);
          background-color: $n-20;
        }

        b {
          display: block;
          flex: 1 1 auto;
          font-size: 14px;
          color: $n-100;
          font-weight: 400;
        }

        .fly-svg-icon {
          flex: 0 0 auto;
        }
      }

      &__contact {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-top: 16px;

        &__item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 32px;
          font-size: 14px;
          color: $b-100;
          font-weight: 500;
          padding: 0 18px;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            color: $b-200;
          }
        }
      }
    }
  }

  .version-box {
    position: absolute;
    display: block;
    right: 80px;
    bottom: 0;
    padding-bottom: 15px;
    p {
      color: rgba($dark-ocean, 0.4);
      font-size: 10px;
    }
  }

  &__menu_box {
    position: absolute;
    left: 0;
    top: 80px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    flex: 0 0 auto;
    width: 90vw;
    height: 100%;

    &--is-active {
      .fr-top__menu_box_dropdown {
        display: flex;
      }
    }

    &__dropdown {
      position: relative;
      top: calc(100%);
      display: none;
      flex-direction: column;
      width: 90vw;
      height: 100%;
      max-height: 100%;
      padding: 10px 0;
      border-radius: 3px;
      background-color: $n-0;
      overflow-y: auto;
      z-index: 600;

      @include e500;

      &__item {
        display: flex;
        align-items: center;
        width: 80vw;
        min-height: 32px;
        padding: 4px 16px;
        background-color: $n-0;
        cursor: pointer;

        &:hover {
          background-color: $n-10;
        }

        &:active {
          box-shadow: inset 0 1px 3px 0 rgba($n-200, 0.11);
        }

        b {
          font-size: 14px;
          color: $n-100;
          font-weight: 400;
        }

        i.icon {
          position: relative;
          right: 0px;
          width: 20px;
          align-items: center;
          justify-content: center;
        }
      }

      &__name {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 0 18px;

        b {
          display: inline-block;
          margin-bottom: 3px;
          font-size: 16px;
          font-weight: 500;
          color: $n-100;
        }

        p {
          display: inline-block;
          font-size: 12px;
          font-weight: 500;
          color: rgba($n-100, 0.8);
        }
      }

      &__contact {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-top: 16px;

        &__item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 32px;
          font-size: 14px;
          color: $b-100;
          font-weight: 500;
          padding: 0 18px;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            color: $b-200;
          }
        }
      }
    }
  }
}
