.fly-input {
  width: 100%;
  height: auto;

  &__input,
  input {
    width: 100%;
    height: 32px;
    padding: 0 10px;
    border-radius: 6px;
    border: 2px solid transparent;
    background-color: $n-10;
    font-size: 14px;
    color: $n-100;
    @include transition-general;

    &:hover {
      background-color: darken($n-10, 5%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0 $white !important;
      background-color: $n-0;
      border: 2px solid $b-200 !important;
    }

    &.error {
      outline: none;
      padding-left: 10px;
      box-shadow: 0 0 0 0 $white !important;
      border: 2px solid $red !important;
    }
  }

  &__label {
    padding-left: 12px;
    padding-bottom: 2px;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    font-weight: 400;
    letter-spacing: 0.1px;
  }
}
