.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
}
