/* Burak Başcı -- 2018 */

/* This is a main scss file that gather all scss file */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap");

@import "node_modules/bootstrap/scss/bootstrap";

@import "./toastify";

/* Varialbles */
@import "-unit";
@import "-mixin";
@import "-color";
@import "-font";

/* scss files */
@import "basic";
@import "framework";
@import "Component/fly-settings";
@import "sidebar";
@import "filter";
@import "top-menu";
@import "main-screen";
@import "Component/fly-driver-pin";
@import "table";
@import "flex-column";
@import "white-holder";
@import "h-scroll";
@import "loading";
@import "under-construction";

@import "./Component/fly-app";
@import "./Component/fly-search";
@import "./Component/fly-creative-list";
@import "./Component/fly-button";
@import "./Component/fly-location-charts";
@import "./Component/fly-breadcrumb";
@import "./Component/fly-tag";
@import "./Component/fly-tab";
@import "Component/fly-list-item";
@import "./Component/fly-calendar";
@import "./Component/fly-date-picker";
@import "./Component/fly-combo-box";
@import "./Component/fly-day-hour-chart";
@import "./Component/fly-inventory-card";
@import "./Component/fly-campaign-card";
@import "./Component/fly-authlayout";
@import "./Component/fly-line-chart";
@import "./Component/fly-map-search";
@import "./Component/fly-driver-pin";
@import "./Component/fly-sub-menu";
@import "./Component/fly-static-info";
@import "./Component/fly-label";
@import "./Component/fly-white-item";
@import "./Component/fly-modal";
@import "./Component/fly-geofence";
@import "./Component/fly-workspace";
@import "./Component/fly-events";
@import "Component/fly-info";
@import "Component/fly-textarea";
@import "Component/fly-creative";
@import "Component/fly-playlist";
@import "Component/fly-footer-menu";
@import "Component/fly-top-menu";
@import "Component/fly-status";
@import "Component/fly-status-switch";
@import "Component/fly-time-left";
@import "Component/fly-svg-icon";
@import "Component/fly-header";
@import "Component/fly-campaign-list";
@import "Component/fly-hamburger_menu";
@import "Component/fly-bottom-menu";
@import "Component/fly-settings";
@import "Component/fly-input";
@import "Component/fly-photo-gallery";
@import "Component/fly-planner";
@import "Component/fly-section";
@import "Component/fly-switch";
@import "Component/fly-hour-filter";
@import "Component/fly-zipcode";
@import "Component/fly-typography";
@import "Component/fly-graph-label";
@import "Component/fly-map-city-select";
@import "Component/fly-map-controllers.scss";
@import "Component/fly-inventory-outlook.scss";
@import "Component/fly-campaign-detail";
@import "Component/fly-error-page";
@import "Component/fly-legend";
@import "Component/fly-cumulio";
