.fly-zipcode {
  position: relative;
  width: 100%;
  height: auto;

  &__wrapper {
    width: 100%;
    height: auto;
  }

  &__switch {
    position: absolute;
    top: -34px;
    right: 0;
    display: flex;
  }

  &__textarea {
    width: 100%;
    height: auto;
  }

  &__action-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  &__zipcode-list {
    width: 100%;
    height: auto;
    margin-top: 10px;

    .undetected-zipcode-box {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      font-weight: 400;
      color: $dark-ocean;

      b {
        display: block;
        width: 100%;
        height: auto;
        font-size: 12px;
        font-weight: 400;
        color: $dark-ocean;
      }

      &__item {
        display: flex;
        align-items: center;
        width: auto;
        height: 20px;
        margin: 2px;
        padding: 0 2px 0 4px;
        border-radius: 3px;
        background: rgba($b-300, 0.2);

        b {
          flex: 1 1 auto;
          display: block;
          margin-right: 4px;
          font-size: 12px;
          font-weight: 500;
          color: rgba($dark-ocean, 0.8);
        }

        .delete {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          cursor: pointer;
          border-radius: 2px;

          &:hover {
            background-color: rgba($white, 0.5);
          }

          i.icon {
            margin-top: 3px;
            font-size: 15px;
            color: rgba($dark-ocean, 0.8);
          }
        }
      }
    }
  }
}
