.h-scroll-con {
  // width: calc(100% + 40px);
  height: auto;
  // margin-left: -20px;
  // margin-right: -20px;

  &__scroll {
    display: grid;
    grid-gap: 80px;
    grid-template-columns: 300px 300px 300px 300px 300px;

    @media #{$screen-768} {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    & > div {
      margin-bottom: 18px;
    }
  }

  &__scroll-inventory {
    display: grid;
    grid-gap: 18px;
    grid-template-columns: 300px 300px 300px 300px 300px;

    @media #{$screen-768} {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    & > div {
      margin-bottom: 18px;
    }
  }

  &__campaign-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(317px, 1fr));
    grid-gap: 30px;
    padding-bottom: 40px;

    @media #{$screen-768} {
      display: flex;
      flex-direction: column;
      margin-left: 0px;
    }
  }
}
