.driver-pin-box {
  display: inline-block;
  position: relative;
  height: auto;
  width: auto;

  .driver-pin {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    background-color: rgba($white, 1);
    box-shadow: 0 1px 2.5px 0 rgba(8, 42, 116, 0.37);
    z-index: 10000;

    @include transition-general;
  }

  &.client {
    .driver-pin {
      border-color: $dark-sky;
    }
  }

  &.online {
    .driver-pin {
      border-color: $b-100;
    }
  }

  &.offline {
    .driver-pin {
      border-color: $crimsion-pinkish;
    }
  }

  &.is-active {
    .driver-pin {
      transform: scale(1.3, 1.3);
      box-shadow: 0 6px 10px -2px rgba(#343944, 0.4);
    }

    .driver-info-card {
      display: block;
    }
  }
}

.driver-pin-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(#343944, 0.25);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include transition-general;

  p {
    display: block;
    text-align: center;
    font-size: 12px;
    color: $white;
  }

  &.client {
    background: linear-gradient(
      135deg,
      lighten($dark-sky, 5%) 0%,
      darken($dark-sky, 5%) 100%
    );
  }

  &.online {
    background: linear-gradient(
      135deg,
      lighten($n-100, 5%) 0%,
      darken($n-100, 5%) 100%
    );
  }

  &.offline {
    background: linear-gradient(
      135deg,
      lighten($crimsion-pinkish, 5%) 0%,
      darken($crimsion-pinkish, 5%) 100%
    );
  }
}

.general-pin-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #344e6a 0%, #182639 100%);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.23);
  cursor: pointer;

  &:hover {
    background: #213750;
  }

  p {
    color: white;
    font-size: 16px;
  }
}

.mapboxgl-popup {
  z-index: 210 !important;

  .mapboxgl-popup-tip {
    border-width: 12px !important;
  }

  .mapboxgl-popup-content {
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 20px 0 rgba($main-dark, 0.2);

    button.mapboxgl-popup-close-button {
      top: 3px;
      right: 3px;
      width: 22px;
      height: 22px;
      border-radius: 13px;
      font-size: 20px;
      line-height: 20px;
      padding: 0;
      color: rgba($dark-ocean, 0.6);
      cursor: pointer;

      &:hover {
        background-color: rgba($dark-ocean, 0.1);
        color: rgba($dark-ocean, 0.8);
      }
    }

    .force-select {
      padding: 0 !important;

      .driver-info-card {
        width: 100%;
        height: auto;

        .top {
          position: relative;
          display: flex;
          align-items: flex-start;
          width: 100%;
          height: auto;
          padding-right: 15px;

          .image {
            flex: 0 0 auto;
            width: 80px;
            height: 80px;
            margin-right: 10px;
            border-radius: 5px;
            background-size: 100%;
            background-position: cover;
            box-shadow: 0 2px 15px rgba($dark-ocean, 0.13);
            //background-image: url(../../image/driver-sample-image@2x.png);

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          .text-box {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            width: 100%;

            b.name {
              display: block;
              font-size: 16px;
              color: $dark-ocean;
              margin-bottom: 4px;
            }

            p.serial {
              display: block;
              font-size: 10px;
              color: rgba($dark-ocean, 0.7);
            }
          }
        }

        .divider {
          display: block;
          width: 100%;
          height: 1px;
          background-color: $slow-gray;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .bottom {
          min-width: 240px;
          height: auto;
        }

        .info-box {
          width: 100%;

          .label {
            display: block;
            font-size: 10px;
            color: rgba($dark-ocean, 0.7);
          }

          .info {
            display: block;
            font-size: 14px;
            color: $dark-ocean;
          }

          img {
            display: block;
            max-width: 100% !important;
            width: 100%;
            height: auto;
            margin-top: 10px;
            border-radius: 4px;
            box-shadow: 0 2px 15px rgba($dark-ocean, 0.2);
          }

          video {
            max-width: 100% !important;
            width: 100%;
          }

          & + .info-box {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.driver-group {
  position: relative;
  width: 100%;
  height: auto;

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 16px;
    margin-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 600;
    color: $white;

    &.online {
      background-color: $sea-green;
    }

    &.offline {
      background-color: $crimsion-pinkish;
    }
  }

  &__driver {
    width: 100%;
    height: auto;
    color: $dark-ocean;

    & + .driver-group__driver {
      margin-top: 10px;
    }

    .name {
      display: block;
      width: 100%;
      height: auto;
      font-size: 16px;
      color: $dark-ocean;
    }

    .serial-number {
      display: block;
      width: 100%;
      height: auto;
      font-size: 10px;
      color: rgba($dark-ocean, 0.8);
    }

    .media-id {
      display: block;
      width: 100%;
      height: auto;
      font-size: 12px;
      font-weight: 600;
      color: rgba($dark-ocean, 0.8);
    }
  }

  & + .driver-group {
    margin-top: 20px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -10px;
      width: 100%;
      height: 1px;
      background-color: $slow-gray;
    }
  }
}
