.fly-top-menu {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 90px;
  background-color: $white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 100;

  &__wrapper {
    display: flex;
    width: 100%;
    max-width: 1400px;
    padding: 0 20px;
    margin: 0 auto;

    @media #{$screen-1400} {
      padding: 0 40px;
    }
  }

  &.publish-box-active {
    .fly-top-menu__publish-box {
      display: flex;
    }
  }

  &.online-box-active {
    .fly-top-menu__online-box {
      display: flex;
    }
  }

  &.queue-box-active {
    .fly-top-menu__queue-box {
      display: flex;
    }
  }

  &__publish-box {
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: auto;

    &__action-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button.secondary {
        margin: 0 10px;
      }
    }
  }

  &__online-box {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;

    &__left {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .online-status {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__queue-box {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;

    &__left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .queue-status {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  &__message {
    display: flex;
    align-items: center;
    margin: 0 10px;

    p {
      white-space: nowrap;
      font-size: 12px;
      color: rgba($dark-ocean, 0.7);
    }
  }
}
