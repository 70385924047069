.fly-switch {
  position: relative;
  flex: 0 0 auto;
  width: 38px;
  height: 22px;
  border-radius: 11px;
  background-color: rgba($n-200, 0.3);
  box-shadow: inset 0 1px 3px 0 rgba($shadow-color, 0.2);
  cursor: pointer;

  @include transition-general;

  .toogle {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0 1px 3px 0 rgba($shadow-color, 0.2);

    @include transition-general;
  }

  &.is-on {
    background-color: $bright-green;

    .toogle {
      margin-left: 15px;
      background-color: $white;
    }
  }

  &--small {
    width: 30px;
    height: 16px;

    .toogle {
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
    }

    &.is-on {
      .toogle {
        margin-left: 14px;
      }
    }
  }
}
