.fly-header {
  position: relative;
  width: 100%;
  height: auto;
  flex: 0 0 auto;
  background-color: $n-0;
  box-shadow: 0 6px 19px 0 rgba(22, 33, 57, 0.08);
  z-index: 100;

  &.zIndex-none {
    z-index: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 40px;
    margin-top: 10px;

    @media #{$screen-800} {
      padding: 0 20px;
    }
  }

  &__campaign {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 0 10px 0;

    &__breadcrumb {
      width: auto;
      height: auto;
      cursor: pointer;
      position: absolute;
      left: -36px;
      top: 4px;

      @media #{$screen-800} {
        left: 0;
      }

      &.display-none {
        display: none;
      }
    }

    &__header {
      display: block;
      width: 100%;
      height: auto;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0.7px;
      color: $n-100;
      margin-bottom: 10px;

      @media #{$screen-750} {
        font-size: 24px;

        a {
          font-size: 20px;
        }
      }
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;

      .row {
        display: flex;
        flex-wrap: wrap;

        .col {
          display: flex;
          width: 50%;
          height: 35px;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.35px;
          color: #2e2e2e;
          justify-content: space-between;
          align-items: center;

          b {
            font-weight: 600;
          }

          .left,
          .right {
          }

          .left {
            text-align: left;
          }

          .right {
            text-align: right;
          }

          &.city,
          &.status {
            width: 35%;

            .left {
              margin-left: 25px;
            }
          }
        }
      }

      @media #{$screen-750} {
        flex-wrap: wrap;
        .fly-combo-box {
          display: none;
        }

        .fly-search {
          margin-left: 0;
        }
      }

      &__right,
      &__left {
        display: flex;
        align-items: center;
        height: auto;
      }

      &__left {
        justify-content: flex-start;
        width: 75%;

        .fly-combo-box {
          max-width: 200px;
          min-width: 166px;
        }

        .fly-search {
          max-width: 400px;
        }
      }

      &__right {
        justify-content: flex-end;
        width: 25%;

        .btn-simulation-tool {
          display: block;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          background-color: #edf2ff;
          padding: 0 20px;

          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.35px;
          color: #3675ff;

          > * {
            display: inline-block;
            vertical-align: middle;
          }

          svg path {
            fill: #3675ff;
          }

          b {
            margin-left: 6px;
          }

          &:hover {
            background-color: #3675ff;
            color: white;

            svg path {
              fill: white;
            }
          }
        }
      }

      .fly-tag {
        & + .fly-time-left__dates {
          margin-left: 10px;
        }
      }
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;

      &__left {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      i.icon {
        display: inline-block;
        font-size: 35px;

        &.live {
          color: $bright-green;
        }

        &.paused {
          color: $bright-yellow;
        }

        &.ended {
          color: $bright-red;
        }
      }
    }
  }

  &__tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-top: 6px;

    @media #{$screen-750} {
      display: none;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;

      .menu-item {
        position: relative;
        width: auto;
        height: 32px;
        margin-bottom: -15px;

        &:hover {
          .text {
            b {
              opacity: 1;
            }

            .blue-bar {
              opacity: 1;
            }
          }
        }

        & + .menu-item {
          margin-left: 24px;
        }

        .text {
          position: relative;
          display: block;
          width: auto;
          height: auto;

          b {
            display: block;
            font-size: 14px;
            color: $n-100;
            font-weight: 500;
            opacity: 0.8;

            @include transition-general;
          }

          .blue-bar {
            position: absolute;
            top: 20px;
            left: 0;
            width: 100%;
            height: 8px;
            border-radius: 2px;
            background-color: $n-40;
            box-shadow: 0 3px 5px 3px rgba($n-100, 0.05);
            opacity: 0;

            @include transition-general;
          }
        }

        &.is-active {
          .text {
            b {
              opacity: 1;
            }

            .blue-bar {
              opacity: 1;
              background-color: $b-200;
              box-shadow: 0 3px 5px 3px rgba($b-200, 0.15);
            }
          }
        }
      }
    }

    &__action-box {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      width: auto;
      height: 32px;

      @media #{$screen-750} {
        display: none;
      }

      .export-dropdown-menu {
        position: relative;
        width: auto;
        height: 24px;
        margin-left: 0;
        margin-right: 0;

        &.is-active {
          .export-dropdown-menu__button {
            background: $n-20;
          }

          .export-dropdown-menu__dropdown {
            display: block;
          }
        }

        &__button {
          width: auto;
          height: 24px;
          border-radius: 3px;
          margin-left: 0;
          margin-right: 0;
          padding: 0 5px 0 8px;
          cursor: pointer;

          &:hover {
            box-shadow: none;
            background-color: $n-10;
          }

          &:focus {
            background-color: $n-20;
            border: none;
            box-shadow: none;

            &:hover {
              box-shadow: none;
              background-color: $n-10;
            }
          }

          i.icon {
            margin-top: 3px;
          }

          b {
            font-size: 14px;
            margin-top: -2px;
          }
        }

        &__dropdown {
          position: absolute;
          top: calc(100% + 10px);
          right: 0;
          display: none;
          width: auto;
          height: auto;
          min-width: 120px;
          padding: 10px 0;
          border-radius: 3px;
          background-color: $n-0;

          @include e200;

          a {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            padding: 4px 15px;
            cursor: pointer;

            &:hover {
              background-color: $n-20;
            }

            b {
              font-size: 14px;
              color: $n-200;
            }
          }
        }
      }
    }

    &.display-none {
      display: none;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 24px 0 10px 0;

    &__title {
      display: block;
      flex: 1 1 auto;
      width: 100%;
      height: auto;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0.7px;
      color: $n-100;
      margin-bottom: 10px;

      @media #{$screen-750} {
        font-size: 24px;
      }
    }

    &__close {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background-color: $n-10;
      color: $n-200;

      &:hover {
        background-color: $n-20;
      }

      &:active {
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.09);
      }

      i.icon {
        font-size: 30px;
        margin-top: 6px;
      }
    }
  }
}
