.fly-header__campaign {
  .header-title {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.56px;
    color: #2e2e2e;
  }

  .fly-status-wrapper {
    margin-left: 12px;
    display: inline-block;

    b {
      font-size: 12px;
    }

    .fly-status {
      box-shadow: none;
    }
  }

  .fly-header__campaign__top__left {
    .icon {
      padding-top: 6px;
      margin-left: -9px;
    }
  }

  .export-dropdown-menu__button {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    color: #2e2e2e;
    cursor: pointer;
    border-radius: 6px;
    margin-right: 12px;
    padding: 5px;
    margin-top: 0px;

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    .fly-svg-icon {
      margin-right: 4px;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .fly-calendar {
    b {
      font-size: 14px;
    }

    .fly-svg-icon {
      margin-right: 6px;
    }
  }
}

.campaign-detail-content {
}
